import { MobileAction } from "./actionsTypes";

const initialState = {
  mobileList: [],
  addSingleMobile: {},
  edit: false,
};

const mobileReducer = (state = initialState, action) => {
  switch (action.type) {

    case MobileAction.GET_MOBILES: {
      return {
        ...state,
        mobileList: action.payload,
      };
    }
    case MobileAction.ADD_MOBILE: {
      return {
        ...state,
        addSingleMobile: action.payload,
      };
    }
    case MobileAction.DELETE_MOBILE: {
      return {
        ...state,
        mobileList: state.mobileList.filter(
          (mobile) => !action.payload.includes(mobile._id)
        ),
      };
    }
    case MobileAction.EDIT_MOBILE: {
      return {
        ...state,
        edit: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
export default mobileReducer;
