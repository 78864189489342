import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import { useNavigate } from "react-router";
// import { ImageAsset } from ".";
import { useSelector, useDispatch } from "react-redux";
import { deleteNews } from "../store/newsActions";
import { baseURL } from "../api/baseURL";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
// import { getNews } from "../store/actions/getNewsActions";
import { ImageAsset } from "../components";
import { GetContact } from "../store/NewAction/ContactUsAction";

const ContactScreen = () => {
  const [contactItems, setcontact] = useState([]);
  console.log(contactItems, "kuchhhh");
  const dispatch = useDispatch();
  // const [gadget, setGadget] = useState([]);
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    dispatch(GetContact());
  }, [refresh, dispatch]);
  useEffect(() => {
    const fatchApi = async () => {
      const { data } = await axios.get(`${baseURL}/api/v1/contactus`);
      console.log(data, "dataaaaaaa");
      setcontact(data.success);
    };
    fatchApi();
  }, [refresh]);
  const navigate = useNavigate();
  // const { newsList } = useSelector((state) => state.news);
  const contact = useSelector((state) => state.contact);
  console.log(contact, "contact");
  const { loading, error } = contact;
  const [selectedIds, setSelectedIds] = useState([]);
  const [open, setOpen] = useState(false);
  //redux

  //column
  const columns = [
    {
      field: "_id",
      headerName: "ID",
      width: 70,
      hide: true,
    },
    { field: "name", headerName: "Name", width: 200 },

    { field: "email", headerName: "Email", width: 200 },
    { field: "subject", headerName: "Subject", width: 200 },

    { field: "message", headerName: "Message", width: 300 },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          const api = params.api;
          const thisRow = {};
          api
            .getAllColumns()
            .filter((c) => c.field !== "__check__" && !!c)
            .forEach(
              (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
            );
          return navigate("/contactus/contactUsDetails/" + params.id, {
            state: { editNews: true, newsId: params.id },
          });
          // return navigate("/contactus/contactUsDetails/");
        };
        return (
          <Button variant="contained" color="primary" onClick={onClick}>
            VIEW
          </Button>
        );
      },
    },
  ];

  const handleOnCellClick = (params) => {
    navigate("/slide/newsDetail/" + params.id);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const ConformDelete = async () => {
    const admin = await JSON.parse(localStorage.getItem("admin"));
    console.log(admin, "for deleting purposes");
    // dispatch(deleteNews(selectedIds, admin));
    try {
      const { data } = await axios.delete(`${baseURL}/api/v1/contactus/del`, {
        data: {
          selectedIds,
        },
      });
      if (data) {
        setRefresh(true);
      }
      setRefresh(false);

      setOpen(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {loading ? (
        <h1 className="text-3xl animate-bounce text-black font-semibold font-serif text-center bg-zinc-200 p-6 ">
          Loading...
        </h1>
      ) : (
        <div className="w-full h-96">
          <div
            className={`flex mb-5 rounded  +  ${
              selectedIds.length > 0 ? "bg-pink" : ""
            }`}
          >
            <div className="w-1/2 align-middle pt-3 text-sm font-lighter text-[#f50057] pb-3 pl-2">
              {selectedIds.length > 0 && (
                <h3>Selected Items : {selectedIds.length}</h3>
              )}
            </div>
            <div className="w-1/2 pr-2 flex justify-end">
              <Button
                disabled={!selectedIds.length > 0}
                // onClick={handleDeleteRecords}
                onClick={handleClickOpen}
              >
                {selectedIds.length > 0 && (
                  <ImageAsset
                    className=" w-[1.5rem] h-[1.5rem] "
                    src="Delete"
                  />
                )}
              </Button>

              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  <div className="flex flex-col">
                    <div className="flex justify-center">
                      <ImageAsset
                        className="justify-center text-center w-[1.5rem] h-[1.5rem]"
                        src="Delete"
                      />
                    </div>
                    <div className="flex justify-center">
                      <h1>Are you sure ?</h1>
                    </div>
                  </div>
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <p className="px-10">
                      Do you want to delete this data ? <br />
                      this data canot be restore.
                    </p>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button onClick={ConformDelete} autoFocus>
                    ok
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </div>

          <DataGrid
            onSelectionModelChange={(newSelectionModel) => {
              setSelectedIds(newSelectionModel);
            }}
            rows={contactItems}
            columns={columns}
            pageSize={50}
            rowsPerPageOptions={[50]}
            checkboxSelection
            disableSelectionOnClick
            getRowId={(r) => r._id}
            autoHeight={true}
            // onRowDoubleClick={handleOnCellClick}
          />
        </div>
      )}
    </>
  );
};

export default ContactScreen;
