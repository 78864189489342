import React from "react";
import { NewsContent } from "../components";

const NewsScreen = () => {
  return (
    <div className="w-full">
      <NewsContent />
    </div>
  );
};

export default NewsScreen;
