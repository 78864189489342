import React, { useState, useEffect, useRef } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import { TextField } from "../components";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { addReview } from "../store/reviewsActions";
import { editReview } from "../store/actions/getReviewActions";
import { baseURL } from "../api/baseURL";
import { useLocation } from "react-router-dom";
// import  { useState, useRef } from "react";
import JoditEditor from "jodit-react";
const validate = Yup.object({
  brandName: Yup.string().required("Required"),
  alt:Yup.string(),
  rating: Yup.number()
    .positive()
    .required("Required!")
    .integer()
    .typeError("Rating should be a number"),
  youtubeUrl: Yup.string().required("Required"),
  // details: Yup.string().required("Required"),
  // introduction: Yup.string().required("Required"),
  mobileName: Yup.string().required("Required"),
});
const CreateReview = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { reviewList } = useSelector((state) => state.reviews);

  const [file, setFile] = useState();
  const [item, setItem] = useState();
  const [fileObj, setFileObject] = useState([]);
  //   const [fileArray, setFileArray] = useState([]);
  const [filePhoto, setFilePhoto] = useState("");
  const [edit, setEdit] = useState(false);

  var tempArr = [];

  // const [file, setFile] = useState();
  // var [fileObj, setFileObject] = useState([]);
  var [fileArray, setFileArray] = useState([]);

  const { id } = useParams();

  const uploadMultipleFiles = (e) => {
    console.log("1");
    setFile(e.target.files);
    fileObj.splice(0, fileObj.length);
    fileArray.splice(0, fileArray.length);
    fileObj.splice(0, fileObj.length);
    fileArray.splice(0, fileArray.length);
    fileObj.push(e.target.files);
    for (let i = 0; i < fileObj[0].length; i++) {
      fileArray.push(URL.createObjectURL(fileObj[0][i]));
    }
  };
  const location = useLocation();
  useEffect(() => {
    if (id !== "createNew") {
      reviewList.map((item) => {
        if (item?._id?.toString() === id?.toString()) {
          setItem(item);

          tempArr = [`${item.reviewPhotos}`];

          setFileArray(tempArr.map((item) => `${baseURL}${item}`));

          // setFileArray.push(item.reviewPhotos)
        }
        // setFileArray(`${baseURL}${item}`);
        setEdit(location.state.editReview);
      });
    }
  }, [id, addReview]);

  var saveReview =
    localStorage.getItem("review") != "undefined"
      ? JSON.parse(localStorage.getItem("review"))
      : "";
  var saveDet =
    localStorage.getItem("Reviewdet") !== "undefined"
      ? JSON.parse(localStorage.getItem("Reviewdet"))
      : "";
  var saveInt =
    localStorage.getItem("reviewInto") !== "undefined"
      ? JSON.parse(localStorage.getItem("reviewInto"))
      : "";
  //  console.log(JSON.parse(localStorage.getItem('Reviewdet')),"JSON.parse(localStorage.getItem('Reviewdet'))")
  // var saveReview= "";
  // var saveDet= "";
  // var saveInt=  "";

  const initialValues = {
    brandName: `${
      item ? item.brandName : saveReview ? saveReview.brandName : ""
    }`,
    alt: `${
      item ? item.alt || "" : saveReview ? saveReview.alt || "": ""
    }`,
    rating: `${item ? item.rating : saveReview ? saveReview.rating : ""}`,
    youtubeUrl: `${
      item ? item.youtubeUrl : saveReview ? saveReview.youtubeUrl : ""
    }`,
    // details: `${item ? item.details : ''}`,
    // details:`${content}`,
    mobileName: `${
      item ? item.mobileName : saveReview ? saveReview.mobileName : ""
    }`,
    // introduction: `${item ? item.introduction : ""}`,
    reviewPhotos: `${item ? `${baseURL}${item.reviewPhotos}` : ""}`,
  };
  // var savemmm=JSON.parse(localStorage.getItem('reviewInto'));

  const editor = useRef(null);

  let items;
  let intro;
  if (item) {
    items = item.details;
    intro = item.introduction;
  } else if (saveDet || saveInt) {
    items = saveDet;
    intro = saveInt;
  }
  // else if(saveInt){
  //   intro=saveInt
  // }
  const [details, setContent] = useState(items);
  const [introduction, setIntroduction] = useState(intro);
  console.log(details);
  console.log(introduction);
  useEffect(() => {
    setContent(items);
    setIntroduction(intro);
  }, [items, intro]);

  const config = {
    readonly: false,
    enableDragAndDropFileToEditor: true,
    uploader: {
      insertImageAsBase64URI: true,
      url: `${baseURL}/images/`,
    },

    height: 400,
  };
  const testEditor = async () => {
    // if(content){
    // try{
    // const res=axios.post(`${BASE_URL}/api/vi/review`,{details:content});
    // res && alert('done');
    // }catch(err){}
  };

  // var SavedDetails=JSON.parse(localStorage.getItem('details'));
  // var SavedDescription=JSON.parse(localStorage.getItem('description'));
  // console.log(saveTitle,'review')
  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validate}
        onSubmit={async (values) => {
          if (!edit && file == undefined) {
            alert("photo is required");
          }

          values.introduction = introduction;
          values.details = details;
          // setContent(item.details);
          // setContent(item.introduction);

          // values.details=content;
          const admin = await JSON.parse(localStorage.getItem("admin"));
          edit
            ? dispatch(
                editReview(
                  location.state.reviewId,
                  values,
                  file,
                  navigate,
                  admin
                )
              )
            : dispatch(addReview(values, file, navigate, admin));
        }}
        // navigate("/dashboard/mobileDetail/")
      >
        {(formik) => (
          <div className="border border-orange flex justify-center ">
            <div className="w-1/2">
              <div className="w-full p-4 flex justify-center">
                {id !== "createNew" ? (
                  <h1>Edit Review </h1>
                ) : (
                  <h1>Create Review </h1>
                )}
              </div>
              <Form>
                <div className="flex flex-col space-y-3">
                  <div className="flex flex-row flex-wrap">
                    {(fileArray || []).map((url) => (
                      <img
                        style={{ width: "150px", height: "200px" }}
                        src={url ? url : ""}
                        alt="..."
                        key={url ? url : ""}
                        className="p-4 border-2 border-sky-500 mx-2 my-2"
                      />
                    ))}
                  </div>
                  <div className="mx-auto my-5">
                  <div className="flex">  <input
                      type="file"
                      className="form-control hidden"
                      onChange={uploadMultipleFiles}
                      multiple
                      id="input"
                      name="images"
                      accept="image/*"
                    />
                    <TextField 
                    label="Img Alt"
                    name="alt"
                    placeholder="Enter Image alt"
                    // onChange={(e)=>{setBrandName(e.target.value) }}
                    value={formik.values.alt}
                    type="text"
                    className="my-5 outline-none border w-full py-2 rounded px-2" 

                  />
                    </div>
                    <label
                      className="py-2 px-5 border-2 rounded-2xl bg-sky-500 text-center text-white"
                      htmlFor="input"
                    >
                      Choose your Photo
                    </label>
                  </div>

                  <div className="bg-blue-700 text-center text-white py-2 my-4">
                    <h1>Review Info</h1>
                  </div>
                  <TextField
                    label="Brand Name"
                    name="brandName"
                    placeholder="Enter brand name"
                    value={formik.values.brandName}
                    type="text"
                  />
                  <TextField
                    label="Rating"
                    name="rating"
                    placeholder="Enter rating here"
                    value={formik.values.rating}
                    type="text"
                  />
                  <TextField
                    label="Youtube Url"
                    name="youtubeUrl"
                    placeholder="Enter youtube Url here"
                    value={formik.values.youtubeUrl}
                    type="text"
                  />
                  <TextField
                    label="Mobile Name"
                    name="mobileName"
                    value={formik.values.mobileName}
                    type="text"
                  />

                  {/*Descriptions*/}
                  <div className="bg-pink text-center text-black py-2">
                    <h1>Descriptions</h1>
                  </div>
                  <div className="flex flex-col">
                    <label className="text-sm pb-3 font-light">
                      Introduction
                    </label>
                    <JoditEditor
                      name="introduction"
                      ref={editor}
                      value={intro}
                      config={config}
                      tabIndex={0} // tabIndex of textarea
                      onBlur={(newContent) => setIntroduction(newContent)} // preferred to use only this option to update the content for performance reasons
                      onInit={(e, editor) => {
                        setIntroduction(editor);
                      }}
                    />
                    {/* <textarea
                      className="
                        block
                        w-full
                        px-3
                        py-1.5
                        text-base
                        font-normal
                        text-gray-700
                        bg-white bg-clip-padding
                        border border-solid border-gray-300
                        rounded
                        transition
                        ease-in-out
                        m-0
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                      "
                      rows="5"
                      placeholder="Please enter introduction"
                      name="introduction"
                      value={formik.values.introduction}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    ></textarea>
                    <ErrorMessage
                      component="div"
                      className="text-red-700 text-sm"
                      name="shortDescription"
                    /> */}
                  </div>
                  <div className="flex flex-col">
                    <label className="text-sm pb-3 font-light">
                      Enter Details
                    </label>
                    <JoditEditor
                      name="details"
                      ref={editor}
                      value={items}
                      config={config}
                      tabIndex={0} // tabIndex of textarea
                      onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                      onInit={(e, editor) => {
                        setContent(editor);
                      }}
                    />
                    {/* {items && <div dangerouslySetInnerHTML={{ __html: items }} />} */}
                    {/*
                    <textarea
                      className={`block
                      w-full
                      px-3
                      py-1.5
                      text-base
                      font-normal
                      text-gray-700
                      bg-white bg-clip-padding
                      border border-solid border-gray-300
                      rounded
                      transition
                      ease-in-out
                      m-0
                      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                      ${formik.touched &&
                        formik.error &&
                        "border border-red-700"
                        }`}
                      rows="8"
                      placeholder="Please enter details"
                      name="details"
                      value={formik.values.details}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      ></textarea> */}
                    <ErrorMessage
                      component="div"
                      className="text-red-700 text-sm"
                      name="longDescription"
                    />
                  </div>
                  <div className="flex pt-4 space-x-6 pb-4 w-40">
                    {edit ? (
                      <>
                        <button
                          className="btn text-sm font-light rounded-sm p-1 pl-6 pr-6 bg-green-500 btn-dark mt-3 text-white"
                          type="submit"
                        >
                          UPDATE
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          className="btn text-sm font-light rounded-sm p-1 pl-6 pr-6 bg-orange-400 btn-dark mt-3"
                          type="submit"
                          //  onClick={testEditor}
                        >
                          Create
                        </button>
                        <button
                          className="btn text-sm font-light rounded-sm p-2 pl-6 pr-6 bg-red-200 btn-dark mt-3"
                          type="button"
                          onClick={() => {
                            localStorage.removeItem("review");
                            localStorage.removeItem("Reviewdet");
                            localStorage.removeItem("reviewInto");
                            navigate("/reviews/reviewDetail/createNew");
                            navigate("/dashboard");
                          }}
                        >
                          Reset
                        </button>
                        <button
                          className="btn text-sm font-light rounded-sm p-2 pl-6 pr-6 bg-zinc-200 btn-dark mt-3"
                          type="button"
                          onClick={() => {
                            localStorage.setItem(
                              "review",
                              JSON.stringify(formik.values)
                            );
                            localStorage.setItem(
                              "Reviewdet",
                              JSON.stringify(details)
                            );
                            localStorage.setItem(
                              "reviewInto",
                              JSON.stringify(introduction)
                            );
                            alert("data saved");
                          }}
                        >
                          Save
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </Form>
            </div>
          </div>
        )}
      </Formik>
    </>
  );
};

export default CreateReview;
