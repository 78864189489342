import { ReviewAction } from "./actionsTypes";
import axios from "axios";
import { baseURL } from "../api/baseURL";

export const addNewReview = (values) => {
  return (dispatch) => {
    dispatch({
      type: ReviewAction.ADD_REVIEW,
      payload: values,
    });
  };
};

//delete reviews
export const deleteReview = (value, admin) => {
  console.log(admin, "deleting now now now")
  return async (dispatch) => {
    try {
      const res = await axios.delete(`${baseURL}/api/v1/review/deleteReviews`, {
        data: {
          value,
        },
      }, {
        headers: {
          'authorization': `${admin}`
        },
      });

      if (res.data) {
        dispatch({
          type: ReviewAction.DELETE_REVIEW,
          payload: value,
        });
      } else {
        console.log("Unable to fetch");
      }
    } catch (error) { }
  };
};

// add review
export const addReview = (values, images, navigate, admin) => {
  console.log("values==>", values);
  console.log('admin during news add req', admin)
  return async (dispatch) => {
    const formData = new FormData();
    if (images) {
      for (var i = 0; i < images.length; i++) {
        formData.append("images", images[i]);
      }
    }
    try {
      const res = await axios.post(

        `${baseURL}/api/v1/review/addReview`,

        images === undefined ? null : formData,
        {
          params: {
            values,
          },
        },
        {
          headers: {
            'Authorization': `${admin}`,
            accept: "application/json",
            "Content-Type": "multipart/form-data",

          },
        }
      );
      console.log("res===>", res);
      if (res.data.success) {
        dispatch({
          type: ReviewAction.ADD_REVIEW,
          payload: values,
        });
        alert(res.data.data);
        navigate("/reviews");
        navigate("/dashboard");
        localStorage.removeItem('review')
        localStorage.removeItem('Reviewdet')
        localStorage.removeItem('reviewInto')
      } else {
        alert(res.data.data);
        navigate("/reviews");
      }
    } catch (error) { }
  };
};
