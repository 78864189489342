import { Outlet } from "react-router";
import "./App.css";
import { ToastContainer } from "react-toastify";
import { useEffect } from "react";
import { useNavigate } from 'react-router-dom'


function App() {
  const navigate = useNavigate()
  useEffect(async () => {
    var admin = ''
    admin = await JSON.parse(localStorage.getItem(('admin')))
    if (admin) {
      navigate("dashboard");
    }
    else {
      navigate('/')
    }
  }, [])
  return (
    <div className="App">
      <Outlet />
      <ToastContainer />
    </div>
  );
}

export default App;
