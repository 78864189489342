import { UsedMobileAction } from "./actionsTypes";
import axios from "axios";
import { baseURL } from "../api/baseURL";

export const addNewMobile = (values) => {
  return (dispatch) => {
    dispatch({
      type: UsedMobileAction.ADD_USED_MOBILE,
      payload: values,
    });
  };
};

//delete mobiles
export const deleteMobile = (value, admin) => {
  console.log(admin, "deleting now now now")
  return async (dispatch) => {
    try {
      const res = await axios.delete(`${baseURL}/api/v1/usedmobile/deleteMobile`, {
        data: {
          value,
        },
      }, {
        headers: {
          'authorization': `${admin}`
        },
      });

      if (res.data) {
        dispatch({
          type: UsedMobileAction.DELETE_USED_MOBILE,
          payload: value,
        });
      } else {
        console.log("Unable to fetch");
      }
    } catch (error) { }
  };
};

// add mobile
export const addMobile = (values, images, navigate, admin) => {
  console.log("values==>", values);
  console.log('admin during phone add req', admin)
  return async (dispatch) => {
    const formData = new FormData();
    if (images) {
      for (var i = 0; i < images.length; i++) {
        formData.append("images", images[i]);
      }
    }
    try {
      const res = await axios.post(

        `${baseURL}/api/v1/usedmobile/uploadad`,

        images === undefined ? null : formData,
        {
          params: {
            values,
          },
        },
        {
          headers: {
            'Authorization': `${admin}`,
            accept: "application/json",
            "Content-Type": "multipart/form-data",

          },
        }
      );
      console.log("res===>", res);
      if (res.data.success) {
        dispatch({
          type: UsedMobileAction.ADD_USED_MOBILE,
          payload: values,
        });
        alert('New UsedMobile Post Successfully')
        navigate("/usedmobile");
      } else {
        alert(res.data.data);
        navigate("/usedmobile");
      }
    } catch (error) {
      console.log(error, "sharjeel")
     }
  };
};
