import { PlaylistAddOutlined } from "@mui/icons-material";
import { GADGET_BYID_FAIL, GADGET_BYID_RESQUEST, GADGET_BYID_SUCCESS, GADGET_CREATE_FAIL, GADGET_CREATE_RESQUEST, GADGET_CREATE_SUCCESS, GADGET_LIST_FAIL, 
    GADGET_LIST_REQUEST, GADGET_LIST_SUCCESS, GADGET_UPDATE_FAIL, GADGET_UPDATE_RESQUEST, GADGET_UPDATE_SUCCESS } from "../Constants/Gadget";


export const GadgetReducer=(state={gadget:[]},action)=>{
    switch(action.type){
        case GADGET_LIST_REQUEST:
            return {loading:true,gadget:[]}
            case GADGET_LIST_SUCCESS:
            return {loading:false,gadget:action.payload}
            case GADGET_LIST_FAIL:
            return {error:true,error:action.payload}
            default :
            return state
    }

}



export const GadgetCreateReducer=(state={},action)=>{
    switch(action.type){
        case GADGET_CREATE_RESQUEST:
            return {loading:true,}
            case GADGET_CREATE_SUCCESS:
            return {loading:false,gadgets:action.payload}
            case GADGET_CREATE_FAIL:
            return {error:true,error:action.payload}
            default :
            return state
    }

}

export const GadgetUpdateReducer=(state={},action)=>{
    switch(action.type){
        case GADGET_UPDATE_RESQUEST:
            return {loading:true,}
            case GADGET_UPDATE_SUCCESS:
            return {loading:false,gadgetUpdate:action.payload}
            case GADGET_UPDATE_FAIL:
            return {error:true,error:action.payload}
            default :
            return state
    }

}
export const GadgetGetByIdReducer=(state={gadgetById:[]},action)=>{
 switch(action.type){
    case GADGET_BYID_RESQUEST:
        return {loading:true,gadgetById:[]}
        case GADGET_BYID_SUCCESS:
         return {loading:false,gadgetById:action.payload}
         
         case GADGET_BYID_FAIL:
            return { error:true,error:action.payload}
            default :
            return state


 }

}