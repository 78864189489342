import { Button, TextField } from '@mui/material'
import React from 'react'
import { FaStar } from 'react-icons/fa';

import { useState } from 'react'
import { Formik, formik, Form, ErrorMessage } from 'formik';
import * as Yup from "yup";
import axios from 'axios';
import { baseURL } from '../api/baseURL';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import JoditEditor from "jodit-react";
import { useDispatch, useSelector } from 'react-redux';
import { gadgetSingleAction, Gadgetupdate } from '../store/NewAction/GadgetAction';

const GadgetUpdateScreen = () => {
  const dispatch = useDispatch();
  const GadgetSingle = useSelector(store => store.GadgetSingle)
  const { loading, gadgetById, error } = GadgetSingle;

  const [details, setContent] = useState();
  const editor = useRef(null);
  const PF = `${baseURL}/new/`
  const [refresh, setRefresh] = useState(false);
  const [reviewTitle, setReviewTitle] = useState(gadgetById.reviewTitle);
  const [Name, setName] = useState(gadgetById.Name);
  const [maker, setMarker] = useState(gadgetById.maker);
  const [model, setModel] = useState(gadgetById.model);
  const [alt, setAlt] = useState(gadgetById.alt);
  const [releaseDate, setReleaseDate] = useState(gadgetById.releaseDate);
  const [price, setPrice] = useState(gadgetById.price);
  const [colours, setColours] = useState(gadgetById.colours);
  const [stars, setVl] = useState(gadgetById.stars);
  console.log(stars, 'starsss')
  const param = useParams();
  const id = param.id;
  const config = {
    readonly: false,
    enableDragAndDropFileToEditor: true,
    uploader: {
      insertImageAsBase64URI: true,
      url: `${baseURL}/images/`
    },

    height: 400
  };
  useEffect(() => {
    dispatch(gadgetSingleAction(id));
    console.log(gadgetById, 'single')

  }, [id, refresh])
  const navigate = useNavigate();
  const [file, setFile] = useState(null)

  useEffect(() => {
    const fun = () => {
      setName(gadgetById?.Name || "")
      setVl(gadgetById?.stars || "")
      setMarker(gadgetById?.maker || "")
      setModel(gadgetById?.model || "")
      setAlt(gadgetById?.alt || "")
      setReleaseDate(gadgetById?.releaseDate || "")
      setPrice(gadgetById?.price || "")
      setColours(gadgetById?.colours || "")
      setReviewTitle(gadgetById?.reviewTitle || "")
      setContent(gadgetById?.reviewDetails || "")
    }
    fun();
  }, [dispatch, gadgetById])
  const [fileObj, setFileObject] = useState([]);

  var [fileArray, setFileArray] = useState([]);
  if (file) {
    fileObj.splice(0, fileObj.length);
    fileArray.splice(0, fileArray.length);
    fileObj.splice(0, fileObj.length)
    fileArray.splice(0, fileArray.length)
    fileObj.push(file);
    for (let i = 0; i < fileObj[0].length; i++) {
      fileArray.push(URL.createObjectURL(fileObj[0][i]));
    }
    console.log('array')
  }
  const submitHandler = async (e) => {
    e.preventDefault();
    const reviewDetails = details;
    const values = { Name, maker, model, alt, releaseDate, price, colours, reviewDetails, reviewTitle, stars };
    dispatch(Gadgetupdate(file, id, values, navigate))

  }

  return (


    <div className='w-full h-screen '>
      {
        loading ?
          <h1 className="text-3xl animate-bounce text-black font-semibold font-serif text-center bg-zinc-200 p-6 " >Loading...</h1>

          :

          <div className='w-full flex flex-col justify-center items-center '>
            <h1 className='font-medium text-xl '>Update Gadget</h1>
            <form className='flex flex-col w-[50%]'>

              <div className='flex space-x-2 mt-8 mb-5 '>
                {gadgetById &&
                  <div className='flex flex-row flex-wrap mt-8 mb-5'>
                    {console.log(fileArray, "file array")}
                    {
                      file ? (fileArray || []).map((url) => (
                        <img
                          style={{ width: "200px", height: "250px" }}
                          src={url ? url : ""}
                          alt="..."
                          key={url ? url : ""}
                          className="p-4 border-2 border-sky-500 mx-2 my-2"
                           
                        />
                      )) :




                        gadgetById.photo ? gadgetById.photo.map((urls) => (
                          <div className=' flex flex-row flex-wrap'>

                            <img className='p-4 border-2 border-sky-500 mx-2 my-2 ' src={baseURL + urls}
                              style={{ width: "200px", height: "250px" }}

                              alt='img' />
                          </div>
                        )) : ''}

                  </div>
                }
                <div className='flex justify-center items-center'>


                  <div className="">

                    <label htmlFor='file'
                      className="py-2 px-6 border-2 rounded-2xl bg-sky-500 text-center text-xl text-white"

                    >Choose File</label>

                    <input type='file' multiple name='file' id='file' style={{ display: 'none' }}
                      onChange={(e) => { setFile(e.target.files) }}
                    />
                    <TextField
                      label="Img Alt"
                      name="alt"
                      placeholder="Enter Image alt"
                      value={alt}
                      onChange={(e) => setAlt(e.target.value)}
                      type="text"
                      style={{ marginTop: '20px' }} // Add the desired margin value
                      className="outline-none border w-full py-2 rounded px-2"
                    />
                  </div>

                </div>
              </div>
              <div className='flex flex-col w-full space-y-6 '>
                <TextField
                  className='w-full'
                  label=""
                  name="Name"
                  placeholder=""
                  type="text"
                  onChange={(e) => setName(e.target.value)}
                  value={Name}
                />

                <TextField
                  className='w-full'
                  label=""
                  name="maker"
                  placeholder=""
                  type="text"
                  onChange={(e) => setMarker(e.target.value)}
                  value={maker}

                />

                <TextField
                  className='w-full'
                  label=""
                  name="model"
                  placeholder=""
                  type="text"
                  onChange={(e) => setModel(e.target.value)}
                  value={model}

                />

                <h1>Release Date</h1>
                <TextField
                  className='w-full'
                  // label="ReleaseDate"
                  name="releaseDate"

                  type="Date"
                  onChange={(e) => setReleaseDate(e.target.value)}
                  value={releaseDate}

                />

                <TextField
                  className='w-full'
                  label=""
                  name="price"
                  placeholder=""
                  type="text"
                  onChange={(e) => setPrice(e.target.value)}
                  value={price}

                />

                <TextField
                  className='w-full'
                  label=""
                  name="colours"
                  placeholder=""
                  type="text"
                  onChange={(e) => setColours(e.target.value)}
                  value={colours}

                />
                <TextField
                  className='w-full'
                  label=""
                  name="reviewTitle"
                  placeholder=""
                  type="text"
                  onChange={(e) => setReviewTitle(e.target.value)}
                  value={reviewTitle}

                />
                <JoditEditor
                  name="details"
                  ref={editor}
                  value={details}
                  // value={formik.values.details}

                  config={config}
                  tabIndex={0} // tabIndex of textarea
                  onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                  onInit={(e, editor) => { setContent(editor) }} />
                <h1 className='flex space-x-4 border-b-2' > <span className='text-2xl font-bold'> Stars </span>   {gadgetById.stars == 1 ?
                  <p className='flex items-center '>
                    <FaStar className="text-[#F4871E] mt-2" /> </p>
                  :
                  gadgetById.stars == 2 ?
                    <p className='flex items-center mt-2 '>
                      <FaStar className="text-[#F4871E]" />
                      <FaStar className="text-[#F4871E]" />
                    </p> :
                    gadgetById.stars == 3 ?
                      <p className='flex items-center mt-2'>
                        <FaStar className="text-[#F4871E]" />
                        <FaStar className="text-[#F4871E]" />
                        <FaStar className="text-[#F4871E]" />
                      </p> :
                      gadgetById.stars == 4 ?
                        <p className='flex items-center mt-2'>
                          <FaStar className="text-[#F4871E]" />
                          <FaStar className="text-[#F4871E]" />
                          <FaStar className="text-[#F4871E]" />
                          <FaStar className="text-[#F4871E]" />
                        </p> :
                        gadgetById.stars == 5 ?
                          <p className='flex items-center mt-2'>
                            <FaStar className="text-[#F4871E]" />
                            <FaStar className="text-[#F4871E]" />
                            <FaStar className="text-[#F4871E]" />
                            <FaStar className="text-[#F4871E]" />
                            <FaStar className="text-[#F4871E]" />
                          </p> :


                          ''

                }  </h1>
                <div>

                  <label className="flex items-center gap-1">
                    <input type="radio" name="stars" value="1"
                      checked={stars === 1}
                      onChange={(e) => setVl(1)}
                    />

                    <FaStar className="text-[#F4871E]" />
                  </label>
                </div>
                <div>
                  <label className="flex items-center gap-1">
                    <input type="radio" name="stars" value="2"
                      checked={stars === 2}
                      onChange={(e) => setVl(2)}

                    />

                    <FaStar className="text-[#F4871E]" />
                    <FaStar className="text-[#F4871E]" />
                  </label>
                </div>
                <div>
                  <label className="flex items-center gap-1">
                    <input type="radio" name="stars" value="3"
                      checked={stars === 3}
                      onChange={(e) => setVl(3)}

                    />

                    <FaStar className="text-[#F4871E]" />
                    <FaStar className="text-[#F4871E]" />
                    <FaStar className="text-[#F4871E]" />

                  </label>
                </div>
                <div>
                  <label className="flex items-center gap-1">
                    <input type="radio" name="stars" value="4"
                      checked={stars === 4}
                      onChange={(e) => setVl(4)}

                    />

                    <FaStar className="text-[#F4871E]" />
                    <FaStar className="text-[#F4871E]" />
                    <FaStar className="text-[#F4871E]" />
                    <FaStar className="text-[#F4871E]"

                    />
                  </label>
                </div>
                <div>
                  <label className="flex items-center gap-1">
                    <input type="radio" name="stars" value="5"
                      checked={stars === 5}
                      onChange={(e) => setVl(5)}

                    />

                    <FaStar className="text-[#F4871E]" />
                    <FaStar className="text-[#F4871E]" />
                    <FaStar className="text-[#F4871E]" />
                    <FaStar className="text-[#F4871E]" />
                    <FaStar className="text-[#F4871E]" />
                  </label>
                </div>




                <Button type='submit' onClick={submitHandler} sx={{ paddingTop: '12px' }} variant='outlined'>Update</Button>
                <div ></div>
              </div>
            </form>
          </div>
      }
    </div>

  )
}

export default GadgetUpdateScreen