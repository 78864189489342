import { Button, TextField } from '@mui/material'
import React from 'react'
import { useState, useRef } from 'react'
import { Formik, formik, Form, ErrorMessage } from 'formik';
import * as Yup from "yup";
import axios from 'axios';
import { baseURL } from '../api/baseURL';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { CreateSlides } from '../store/NewAction/SlideActions';
import { useSelector } from 'react-redux';

const CreateQuestion = () => {
    const createSlide=useSelector(store=>store.createSlide);
    const {loading,slide,error}=createSlide;

    const dispatch=useDispatch();
    const navigate = useNavigate();
    const [file, setFile] = useState(null)
    const editor = useRef(null);
    const initialValues = {
        question: '',
        answer: '',

    }
    const validate = Yup.object({
        question: Yup.string().required("This Field is Required"),
        answer: Yup.string().required("This Field is Required"),
      

    })

    
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validate}
            onSubmit={ async(values) => {
               
                
        //  dispatch(CreateSlides(file,values,navigate))
        try{
             const {data}=await axios.post(`${baseURL}/api/v1/question`,values);
                alert('   Questions Created ')
                  data && navigate('/question')
            }catch(err){
                console.log(err)
            }
            }


            }
        >
            {((formik) => (

                <div className='w-full h-screen '>

                    {loading ?
    
                      <h1 className="text-3xl animate-bounce text-black font-semibold font-serif text-center bg-zinc-200 p-6 " >Loading...</h1> 
                     
                     : error ?   <h1> {error} </h1>:

                    <div className='w-full flex flex-col justify-center items-center '>
                        <h1 className='font-medium text-xl '>Create Question Answer</h1>
                        <Form className='flex flex-col w-[50%]'>

                           
                            {/* <div className='flex space-x-4 mt-8 mb-5 '> */}
                                {/* {file && <img className='h-[15rem] rounded-xl' src={URL.createObjectURL(file)} */}
                                    {/* alt='img' />} */}
                                {/* <div className='flex justify-center items-center'> */}

                                    {/* <label htmlFor='file' */}
                                        {/* className="py-2 px-6 border-2 rounded-2xl bg-sky-500 text-center text-xl text-white" */}

                                    {/* >Choose File</label> */}
                                    {/* <input type='file' name='file' id='file' style={{ display: 'none' }} */}
                                        {/* onChange={(e) => { setFile(e.target.files[0]) }} */}
                                    {/* /> */}
                                {/* </div> */}
                            {/* </div> */}
                            <div className='flex flex-col w-full space-y-6 '>
                                <TextField
                                    className='w-full'
                                    label="Question"
                                    name="question"
                                    placeholder=""
                                    type="text"
                                    onChange={formik.handleChange}
                                />
                                <ErrorMessage
                                    component="div"
                                    className="text-red-700 text-sm"
                                    name="question"
                                />
                                <TextField
                                    className='w-full'
                                    label="answer"
                                    name="answer"

                                    type="text"
                                    onChange={formik.handleChange}
                                />
                                <ErrorMessage
                                    component="div"
                                    className="text-red-700 text-sm"
                                    name="answer"
                                />
                               
                            
                                <Button type='submit' sx={{ paddingTop: '12px' }} variant='outlined'>Submit</Button>
                                <div ></div>
                            </div>
                        </Form>
                    </div>

}
                </div>
            ))}
        </Formik>
    )
}

export default CreateQuestion