import React, { useEffect } from "react";
import { useState, useRef } from "react";
import axios from "axios";
import { baseURL } from "../api/baseURL";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// import data from "./ContactUsDetailData";

const ContactUsDetails = () => {
  const [detail, setdetail] = useState([]);
  const [loading, setloading] = useState(false);
  const [error, seterror] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setloading(true);
        const { data } = await axios.get(`${baseURL}/api/v1/contactus`);
        setdetail(data.success);
        setloading(false);
        console.log(data, "datao");
      } catch (err) {
        seterror(err.message);
        setloading(false);
      }
    };
    fetchData();
  }, []);
  // console.log(detail);

  return (
    <>
      <div className=" flex justify-center h-full items-center  w-full">
        {detail
          .filter((x) => x._id === id)
          .map((item) => (
            <div>
              <h1 className="font-bold ">Name</h1>
              <p className="border-2 border-black w-[35rem] rounded-md p-2">
                {item.name}
              </p>
              <h1 className="font-bold mt-1">Email</h1>
              <p className="border-2 border-black w-[35rem] rounded-md p-2">
                {item.email}
              </p>
              <h1 className="font-bold mt-1">Subject</h1>
              <p className="border-2 border-black w-[35rem] rounded-md p-2">
                {item.subject}
              </p>
              <h1 className="font-bold mt-1">Message</h1>
              <p className="border-2 break-words  overflow-y-auto border-black w-[35rem] h-[15rem] rounded-md p-2">
                {item.message}
              </p>
            </div>
          ))}
      </div>
    </>
  );
};

export default ContactUsDetails;
