import React, { useState, useEffect, useRef } from "react";
import { Formik, Form } from "formik";
import { TextField } from "../components";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { addBlog } from "../store/blogsActions";
import { editBlogs } from "../store/actions/getBlogsActions";
import { baseURL } from "../api/baseURL";
import { useLocation } from "react-router-dom";
import JoditEditor from "jodit-react";

const validate = Yup.object({
  title: Yup.string().required("Required"),
  alt: Yup.string(),
});

const CreateBlog = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { blogsList } = useSelector((state) => state.blogs);
  const [fileArray, setFileArray] = useState([]);
  // state for uploading images
  const [file, setFile] = useState();
  const [item, setItem] = useState();
  const [fileObj, setFileObject] = useState([]);
  const [filePhoto, setFilePhoto] = useState("");
  const [edit, setEdit] = useState(false);

  // this was define globaly but considering its use in useEffect - as a temporary Array to mutate state - defined it locally and commented the global one. if not issue happened, will delete global declaration.

  // var tempArr = [];

  const { id } = useParams();

  const uploadMultipleFiles = (e) => {
    setFile(e.target.files);
    fileObj.splice(0, fileObj.length);
    fileArray.splice(0, fileArray.length);
    fileObj.splice(0, fileObj.length);
    fileArray.splice(0, fileArray.length);
    fileObj.push(e.target.files);
    for (let i = 0; i < fileObj[0].length; i++) {
      fileArray.push(URL.createObjectURL(fileObj[0][i]));
    }
  };
  const location = useLocation();
  useEffect(() => {
    if (id !== "createNew") {
      blogsList.map((item) => {
        if (item?._id?.toString() === id?.toString()) {
          setItem(item);

          let tempArr = [`${item.image}`];

          setFileArray(tempArr.map((item) => `${baseURL}${item}`));
        }
        setEdit(location.state.editBlog);
      });
    }
  }, [id, addBlog]);

  // if a user clicks on 'save' button the data will be saved in localstorage so user can come later and continue working on from where he left.

  var saveTitle = JSON.parse(localStorage.getItem("title"));
  var saveAlt = JSON.parse(localStorage.getItem("alt"));
  var SavedDetails = JSON.parse(localStorage.getItem("details"));
  var SavedDescription = JSON.parse(localStorage.getItem("description"));
  const initialValues = {
    title: `${item ? item.title : saveTitle ? saveTitle.title : ""}`,
    alt: `${item ? item.alt || "" : saveAlt ? saveAlt.alt : ""}`,
    image: `${item ? `${baseURL}${item.image}` : ""}`,
  };

  const editor = useRef(null);
  let items;
  let intro;
  if (item) {
    items = item.details;
    intro = item.descreption;
  } else if (SavedDetails || SavedDescription) {
    items = SavedDetails;
    intro = SavedDescription;
  }

  const config = {
    readonly: false,
    enableDragAndDropFileToEditor: true,
    uploader: {
      insertImageAsBase64URI: true,
      url: `${baseURL}/images/`,
    },

    height: 400,
  };
  useEffect(() => {
    setContent(items);
    setIntroduction(intro);
  }, [items, intro]);

  const [details, setContent] = useState(items);
  const [descreption, setIntroduction] = useState(intro);

  return (
    <>
      <Formik
        // initial value retrieved from localstorage, if any.
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validate}
        onSubmit={async (values) => {
          if (!edit && file === undefined) {
            alert("photo is required");
          }

          if (details === "" || descreption === null) {
            alert("Description And Details are Required");
          }
          values.details = details;
          values.descreption = descreption;
          const admin = await JSON.parse(localStorage.getItem("admin"));
          edit
            ? dispatch(
                editBlogs(location.state.blogId, values, file, navigate, admin)
              )
            : dispatch(addBlog(values, file, navigate, admin));
        }}
      >
        {(formik) => (
          <div className="border border-orange flex justify-center ">
            <div className="w-1/2">
              <div className="w-full p-4 flex justify-center">
                {id !== "createNew" ? (
                  <h1>Edit Blog </h1>
                ) : (
                  <h1>Create Blog </h1>
                )}
              </div>
              <Form>
                <div className="flex flex-col space-y-3">
                  <div className="flex flex-row flex-wrap">
                    {(fileArray || []).map((url) => (
                      <img
                        style={{ width: "150px", height: "200px" }}
                        src={url ? url : ""}
                        alt="..."
                        key={url ? url : ""}
                        className="p-4 border-2 border-sky-500 mx-2 my-2"
                      />
                    ))}
                  </div>
                  <div className="mx-auto my-5">
                  <div className="flex">  <input
                      type="file"
                      className="form-control hidden"
                      onChange={uploadMultipleFiles}
                      multiple
                      id="input"
                      name="images"
                      accept="image/*"
                    />
                    <TextField 
                    label="Img Alt"
                    name="alt"
                    placeholder="Enter Image alt"
                    // onChange={(e)=>{setBrandName(e.target.value) }}
                    value={formik.values.alt}
                    type="text"
                    className="my-5 outline-none border w-full py-2 rounded px-2" 

                  />
                    </div>
                    <label
                      className="py-2 px-5 border-2 rounded-2xl bg-sky-500 text-center text-white"
                      htmlFor="input"
                    >
                      Choose your Photo
                    </label>
                  </div>

                  <div className="bg-blue-700 text-center text-white py-2 my-4">
                    <h1>Blog Info</h1>
                  </div>
                  <TextField
                    label="Title"
                    name="title"
                    placeholder="Enter Title here"
                    value={formik.values.title}
                    type="text"
                  />

                  {/*details*/}
                  <div className="bg-pink text-center text-black py-2">
                    <h1>Details</h1>
                  </div>
                  <div className="flex flex-col">
                    <label className="text-sm pb-3 font-light">
                      Description
                    </label>

                    <JoditEditor
                      name="descreption"
                      ref={editor}
                      value={intro}
                      config={config}
                      tabIndex={0} // tabIndex of textarea
                      onBlur={(newContent) => setIntroduction(newContent)} // preferred to use only this option to update the content for performance reasons
                      onInit={(e, editor) => {
                        setIntroduction(editor);
                      }}
                    />
                  </div>
                  <div className="flex flex-col">
                    <label className="text-sm pb-3 font-light">
                      Enter Details
                    </label>

                    <JoditEditor
                      name="details"
                      ref={editor}
                      value={items}
                      config={config}
                      tabIndex={0} // tabIndex of textarea
                      onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                      onInit={(e, editor) => {
                        setContent(editor);
                      }}
                    />
                  </div>
                  <div className="flex pt-4 space-x-6 pb-4 w-40">
                    {edit ? (
                      <>
                        <button
                          className="btn text-sm font-light rounded-sm p-1 pl-6 pr-6 bg-green-500 btn-dark mt-3 text-white"
                          type="submit"
                        >
                          UPDATE
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          className="btn text-sm font-light rounded-sm p-1 pl-6 pr-6 bg-orange-400 btn-dark mt-3"
                          type="submit"
                        >
                          Create
                        </button>
                        <button
                          className="btn text-sm font-light rounded-sm p-2 pl-6 pr-6 bg-red-200 btn-dark mt-3"
                          type="reset"
                          onClick={() => {
                            localStorage.removeItem("title");
                            localStorage.removeItem("alt");
                            localStorage.removeItem("details");
                            localStorage.removeItem("description");
                            navigate("/dashboard");
                          }}
                        >
                          Reset
                        </button>
                        <button
                          className="btn text-sm font-light rounded-sm p-2 pl-6 pr-6 bg-zinc-200 btn-dark mt-3"
                          type="button"
                          onClick={() => {
                            localStorage.setItem(
                              "title",
                              JSON.stringify(formik.values)
                            );
                            localStorage.setItem(
                              "alt",
                              JSON.stringify(formik.values)
                            )
                            localStorage.setItem(
                              "details",
                              JSON.stringify(details)
                            );
                            localStorage.setItem(
                              "description",
                              JSON.stringify(descreption)
                            );
                            alert("data saved");
                          }}
                        >
                          Save
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </Form>
            </div>
          </div>
        )}
      </Formik>
    </>
  );
};

export default CreateBlog;
