import axios from "axios";
import React, { useEffect, useState } from "react";
import { baseURL } from "../api/baseURL";
import { ImageAsset } from "../components";
import { useNavigate } from "react-router";
export const FeaturePhone = () => {
  const PF = `${baseURL}/images/`;
  // const PF =  "http://localhost:8080/features/";
  // const PF="http://localhost:8080/images/"
  const navigate = useNavigate();

  const [first1, setFirst1] = useState(false);
  const [second2, setSecond2] = useState(false);
  const [third3, setThird3] = useState(false);
  const [forth4, setForth4] = useState(false);
  const [fifth5, setFifth5] = useState(false);
  const [sixth6, setSixth6] = useState(false);
  const [seventh7, setSeventh7] = useState(false);
  const [file, setFile] = useState(null);
  const [phoneId, setId] = useState("");
  const [alt,setAlt] = useState("");
  const [first, setFirst] = useState("");
  const [second, setSecond] = useState([]);
  const [third, setThird] = useState([]);
  const [forth, setForth] = useState([]);
  const [fifth, setFifth] = useState([]);
  const [sixth, setSixth] = useState([]);
  const [seventh, setSeventh] = useState([]);
  // console.log(dat._id);

  useEffect(async () => {
    //  const GetMobo=async()=>{
    const GET = await axios.get(`${baseURL}/api/v1/featurephones`);
    // const GET =await axios.get(`${baseURL}/api/v1/featurephones`);
    setFirst(GET.data.success[0]);
    setSecond(GET.data.success[1]);
    setThird(GET.data.success[2]);
    setForth(GET.data.success[3]);
    setFifth(GET.data.success[4]);
    setSixth(GET.data.success[5]);
    setSeventh(GET.data.success[6]);
    // console.log(GET.data);
    // }
    // GetMobo();
  }, []);
  const PostMOBO = async (e) => {
    e.preventDefault();
    // const data= new FormData();
    const values = { phoneId, alt };
    const data = new FormData();

    if (file) {
      const filename = file.name.replace(/\s+/g, "_");
      data.append("name", filename);
      data.append("file", file);
      values.featureMobiles = filename;
      try {
        const dat = await axios.post(`${baseURL}/upload`, data, {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
            "Content-Type": "multipart/form-data",
          },
        });

        // const dat =  await axios.post(`${baseURL}/upload`, data);
        // console.log(dat)
      } catch (e) {
        console.log(e);
      }
    }
    try {
      const res = await axios.put(
        `${baseURL}/api/v1/featurephones/${first._id}`,
        values
      );
      // const res= await axios.post(`${baseURL}/api/v1/featurephones`,values)
      // const res= await axios.post('http://localhost:8080/api/v1/featurephones',values);
      console.log(res.data);
      // window.location.replace('/featurePhones');
      // window.location.reload();
      //  res.data &&  setFirst(res.data.success[0]);

      res.data && setFirst1(false);
      res.data.success && setFirst(res.data.success);
      console.log(res.data + "ojhhea");
      // setFile(false)
      // URL.createObjectURL(false)
      // res.data && file(null);
    } catch (e) {
      console.log(e);
    }
  };

  const Click = () => {
    // SetHover(false)
    first1(false);
    second2(false);
  };

  const PostMOBOS = async (e) => {
    e.preventDefault();
    const ide = second._id;
    // const data= new FormData();
    const values = { phoneId, alt };
    const data = new FormData();

    if (file) {
      const filename = file.name.replace(/\s+/g, "_");
      data.append("name", filename);
      data.append("file", file);
      values.featureMobiles = filename;
      try {
        const dat = await axios.post(`${baseURL}/upload`, data, {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
            "Content-Type": "multipart/form-data",
          },
        });
        // const dat =  await axios.post(`${baseURL}/upload`, data);
        // console.log(dat)
      } catch (e) {
        console.log(e);
      }
    }
    try {
      const res = await axios.put(
        `${baseURL}/api/v1/featurephones/${second._id}`,
        values
      );
      // const res= await axios.post(`${baseURL}/api/v1/featurephones`,values)
      // const res= await axios.post('http://localhost:8080/api/v1/featurephones',values);
      console.log(res.data);
      // window.location.reload();
      res.data && setSecond2(false);
      res.data.success && setSecond(res.data.success);
      console.log(res.data + "ojhhea");
    } catch (e) {
      console.log(e);
    }
  };

  const postThird = async (e) => {
    e.preventDefault();
    // const ide=second._id;
    // const data= new FormData();
    const values = { phoneId, alt };
    const data = new FormData();

    if (file) {
      const filename = file.name.replace(/\s+/g, "_");
      data.append("name", filename);
      data.append("file", file);
      values.featureMobiles = filename;
      try {
        const dat = await axios.post(`${baseURL}/upload`, data, {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
            "Content-Type": "multipart/form-data",
          },
        });
        // const dat =  await axios.post(`${baseURL}/upload`, data);
        // console.log(dat)
      } catch (e) {
        console.log(e);
      }
    }
    try {
      const res = await axios.put(
        `${baseURL}/api/v1/featurephones/${third._id}`,
        values
      );
      // const res= await axios.post(`${baseURL}/api/v1/featurephones`,values)
      // const res= await axios.post('http://localhost:8080/api/v1/featurephones',values);
      console.log(res.data);
      // window.location.replace();
      res.data && setThird3(false);
      res.data.success && setThird(res.data.success);
    } catch (e) {
      console.log(e);
    }
  };
  const postForth = async (e) => {
    e.preventDefault();
    // const ide=second._id;
    // const data= new FormData();
    const values = { phoneId, alt };
    const data = new FormData();

    if (file) {
      const filename = file.name.replace(/\s+/g, "_");
      data.append("name", filename);
      data.append("file", file);
      values.featureMobiles = filename;
      try {
        const dat = await axios.post(`${baseURL}/upload`, data, {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
            "Content-Type": "multipart/form-data",
          },
        });
        // const dat =  await axios.post(`${baseURL}/upload`, data);
        // console.log(dat)
      } catch (e) {
        console.log(e);
      }
    }
    try {
      const res = await axios.put(
        `${baseURL}/api/v1/featurephones/${forth._id}`,
        values
      );
      // const res= await axios.post(`${baseURL}/api/v1/featurephones`,values)
      // const res= await axios.post('http://localhost:8080/api/v1/featurephones',values);
      // console.log(res.data)
      // window.location.reload();
      res.data && setForth4(false);
      res.data.success && setForth(res.data.success);
    } catch (e) {
      console.log(e);
    }
  };

  const postFifth = async (e) => {
    e.preventDefault();
    // const ide=second._id;
    // const data= new FormData();
    const values = { phoneId, alt };
    const data = new FormData();

    if (file) {
      const filename = file.name.replace(/\s+/g, "_");
      data.append("name", filename);
      data.append("file", file);
      values.featureMobiles = filename;
      try {
        const dat = await axios.post(`${baseURL}/upload`, data, {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
            "Content-Type": "multipart/form-data",
          },
        });
        // const dat =  await axios.post(`${baseURL}/upload`, data);
        // console.log(dat)
      } catch (e) {
        console.log(e);
      }
    }
    try {
      const res = await axios.put(
        `${baseURL}/api/v1/featurephones/${fifth._id}`,
        values
      );
      // const res= await axios.post(`${baseURL}/api/v1/featurephones`,values)
      // const res= await axios.post('http://localhost:8080/api/v1/featurephones',values);
      console.log(res.data);
      // window.location.reload();
      res.data && setFifth5(false);
      res.data.success && setFifth(res.data.success);
    } catch (e) {
      console.log(e);
    }
  };

  const postSixth = async (e) => {
    e.preventDefault();
    const values = { phoneId };
    const data = new FormData();

    if (file) {
      const filename = file.name.replace(/\s+/g, "_");
      data.append("name", filename);
      data.append("file", file);
      values.featureMobiles = filename;
      try {
        const dat = await axios.post(`${baseURL}/upload`, data);
        // const dat =  await axios.post(`${baseURL}/upload`, data);
        // console.log(dat)
        // window.location.reload();
      } catch (e) {
        console.log(e);
      }
    }
    try {
      const res = await axios.put(
        `${baseURL}/api/v1/featurephones/${sixth._id}`,
        values
      );
      // const res= await axios.post(`${baseURL}/api/v1/featurephones`,values)
      // const res= await axios.post('http://localhost:8080/api/v1/featurephones',values);
      console.log(res.data);
      // window.location.reload();
      res.data && setSixth6(false);
      res.data.success && setSixth(res.data.success);
    } catch (e) {
      console.log(e);
    }
  };

  const postSeventh = async (e) => {
    e.preventDefault();
    const values = { phoneId };
    const data = new FormData();

    if (file) {
      const filename = file.name.replace(/\s+/g, "_");
      data.append("name", filename);
      data.append("file", file);
      values.featureMobiles = filename;
      try {
        const dat = await axios.post(`${baseURL}/upload`, data, {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
            "Content-Type": "multipart/form-data",
          },
        });
        // const dat =  await axios.post(`${baseURL}/upload`, data);
        // console.log(dat)
      } catch (e) {
        console.log(e);
      }
    }
    try {
      const res = await axios.put(
        `${baseURL}/api/v1/featurephones/${seventh._id}`,
        values
      );
      // const res= await axios.post(`${baseURL}/api/v1/featurephones`,values)
      // const res= await axios.post('http://localhost:8080/api/v1/featurephones',values);
      console.log(res.data);
      // window.location.reload();
      res.data && setSeventh7(false);
      res.data.success && setSeventh(res.data.success);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <div className="flex">
        <div
          // one
          // onMouseLeave={()=>{SetHover(false)}}
          // onMouseEnter={clicked}
          className="w-[40%]  pr-2 relative border border-blue rounded -3xl"
        >
          {
            first1 && (
              <div
                className=" border border-blue-5 rounded-3xl bg-slate-300"
                style={{
                  width: "80%",
                  height: "60%",
                  backgroundColor: "",
                  position: "absolute",
                  top: "20px",
                  left: "35px",
                  opacity: ".6",
                  borderRedius: "25px",
                }}
              >
                <div className="flex  justify-center pt-4">
                  <form className="flex flex-col" onSubmit={PostMOBO}>
                    <input
                      type="text"
                      className="mb-2 outline-none border border-blue-300"
                      placeholder="Id"
                      name="id"
                      onChange={(e) => {
                        setId(e.target.value);
                      }}
                    />
                    <input
                      type="file"
                      placeholder="Id"
                      onChange={(e) => {
                        setFile(e.target.files[0]);
                      }}
                      name="file"
                    />
                    <input
                      type="text"
                      className="my-5 outline-none border border-blue-300"
                      placeholder="Enter Img Alt"
                      name="alt"
                      onChange={(e) => {
                        setAlt(e.target.value);
                      }}
                    />
                    <div className="w-full h-[12rem] border border-red mt-4 rounded-3xl ">
                      <img
                        className="w-full h-[100%] border border-white bg-teal-700 "
                        src={
                          file
                            ? URL.createObjectURL(file)
                            : PF + first.featureMobiles
                        }
                      />
                    </div>
                    <input
                      type="submit"
                      className="p-2 border bg-black  border-green-400
                 mt-4 cursor-pointer text-2xl text-white hover:bg-yellow-500 hover:text-black
                 "
                      value="Update"
                      name="submit"
                    />
                  </form>
                </div>
                <button
                  onClick={() => {
                    setFirst1(false);
                  }}
                  className="p-2 mx-8 border bg-yellow-500 block justify-center  border-green-400
                 mt-4 cursor-pointer text-2xl text-white hover:bg-black hover:text-white
                 "
                >
                  Cancel
                </button>
              </div>
            )

            //  <button
            //  onClick={() =>{setSecond2(false)}}
            //  className="p-2 border bg-yellow-500  border-green-400
            //  mt-4 cursor-pointer text-2xl text-white hover:bg-black hover:text-white
            //  "
            //  >Cancel</button>

            // </form>
          }
          <img
            className="w-full h-full "
            onClick={() => setFirst1(true)}
            src={PF + first.featureMobiles}
          />
        </div>

        <div className="w-[60%] flex flex-col pl-2 space-y-2 relative  ">
          <div className="">
            {second2 && (
              <div
                className="  border border-blue-5 rounded-3xl bg-slate-300"
                style={{
                  width: "60%",
                  height: "55%",
                  position: "absolute",
                  top: "20px",
                  left: "35px",
                  opacity: ".6",
                  borderRedius: "25px",
                }}
              >
                <div className="flex justify-center pt-4">
                  <form className="flex flex-col" onSubmit={PostMOBOS}>
                    <input
                      type="text"
                      className="mb-2 outline-none border border-blue-300"
                      placeholder="Id"
                      name="id"
                      onChange={(e) => {
                        setId(e.target.value);
                      }}
                    />
                    <input
                      type="file"
                      placeholder="Id"
                      onChange={(e) => {
                        setFile(e.target.files[0]);
                      }}
                      name="file"
                    />
                    <input
                      type="text"
                      className="my-5 outline-none border border-blue-300"
                      placeholder="Enter Img Alt"
                      name="alt"
                      onChange={(e) => {
                        setAlt(e.target.value);
                      }}
                    />
                    <div className="w-full h-[12rem] border border-red mt-4 rounded-3xl ">
                      <img
                        className="w-full h-[100%] border border-white bg-teal-700 "
                        src={
                          file
                            ? URL.createObjectURL(file)
                            : PF + second.featureMobiles
                        }
                      />
                    </div>
                    <input
                      type="submit"
                      className="p-2 border bg-black  border-green-400
                 mt-4 cursor-pointer text-2xl text-white hover:bg-yellow-500 hover:text-black
                 "
                      value="Update"
                      name="submit"
                    />
                  </form>
                </div>

                <button
                  // onClick={FALSE}

                  onClick={(e) => {
                    setSecond2(false);
                  }}
                  className="p-2 mx-[2rem] border bg-yellow-500  border-green-400
                 mt-4 cursor-pointer text-2xl text-white hover:bg-black hover:text-white
                 "
                >
                  Cancel{" "}
                </button>
              </div>
            )}
          </div>

          <div>
            <img
              onClick={() => {
                setSecond2(true);
              }}
              className="w-full h-[5rem] lg:h-[10rem] "
              src={PF + second.featureMobiles}
            />
          </div>

          {/* third */}

          <div>
            <div>
              {third3 && (
                <div
                  className=" border border-blue-5 rounded-3xl bg-slate-300"
                  style={{
                    width: "60%",
                    height: "55%",
                    position: "absolute",
                    top: "20px",
                    left: "35px",
                    opacity: ".6",
                    borderRedius: "25px",
                  }}
                >
                  <div className="flex justify-center pt-4">
                    <form className="flex flex-col" onSubmit={postThird}>
                      <input
                        type="text"
                        className="mb-2 outline-none border border-blue-300"
                        placeholder="Id"
                        name="id"
                        onChange={(e) => {
                          setId(e.target.value);
                        }}
                      />
                      <input
                        type="file"
                        placeholder="Id"
                        onChange={(e) => {
                          setFile(e.target.files[0]);
                        }}
                        name="file"
                      />
                      <input
                      type="text"
                      className="my-5 outline-none border border-blue-300"
                      placeholder="Enter Img Alt"
                      name="alt"
                      onChange={(e) => {
                        setAlt(e.target.value);
                      }}
                    />
                      
                      <div className="w-full h-[12rem] border border-red mt-4 rounded-3xl ">
                        <img
                          className="w-full h-[100%] border border-white bg-teal-700 "
                          src={
                            file
                              ? URL.createObjectURL(file)
                              : PF + third.featureMobiles
                          }
                        />
                      </div>
                      <input
                        type="submit"
                        className="p-2 border bg-black  border-green-400
                 mt-4 cursor-pointer text-2xl text-white hover:bg-yellow-500 hover:text-black
                 "
                        value="Update"
                        name="submit"
                      />
                    </form>
                  </div>

                  <button
                    onClick={() => {
                      setThird3(false);
                    }}
                    //  onClick={() =>{SetHover(false)}}
                    className="p-2  mx-[2rem] border bg-yellow-500  border-green-400
                 mt-4 cursor-pointer text-2xl text-white hover:bg-black hover:text-white
                 "
                  >
                    Cancel
                  </button>
                </div>
              )}
              <img
                // onClick={()=>{setThird3(true)}}
                onClick={() => setThird3(true)}
                className="w-full h-[5rem] lg:h-[10rem] "
                src={PF + third.featureMobiles}
                alt=""
              />
            </div>
          </div>

          {/* forth4 */}

          <div>
            <div>
              {forth4 && (
                <div
                  className=" border border-blue-5 rounded-3xl bg-slate-300"
                  style={{
                    width: "60%",
                    height: "55%",
                    position: "absolute",
                    top: "20px",
                    left: "35px",
                    opacity: ".6",
                    borderRedius: "25px",
                  }}
                >
                  <div className="flex justify-center pt-4">
                    <form className="flex flex-col" onSubmit={postForth}>
                      <input
                        type="text"
                        className="mb-2 outline-none border border-blue-300"
                        placeholder="Id"
                        name="id"
                        onChange={(e) => {
                          setId(e.target.value);
                        }}
                      />
                      <input
                        type="file"
                        placeholder="Id"
                        onChange={(e) => {
                          setFile(e.target.files[0]);
                        }}
                        name="file"
                      />
                      <input
                      type="text"
                      className="my-5 outline-none border border-blue-300"
                      placeholder="Enter Img Alt"
                      name="alt"
                      onChange={(e) => {
                        setAlt(e.target.value);
                      }}
                    />
                      <div className="w-full h-[12rem] border border-red mt-4 rounded-3xl ">
                        <img
                          className="w-full h-[100%] border border-white bg-teal-700 "
                          src={
                            file
                              ? URL.createObjectURL(file)
                              : PF + forth.featureMobiles
                          }
                        />
                      </div>
                      <input
                        type="submit"
                        className="p-2 border bg-black  border-green-400
                 mt-4 cursor-pointer text-2xl text-white hover:bg-yellow-500 hover:text-black
                 "
                        value="Update"
                        name="submit"
                      />
                    </form>
                  </div>

                  <button
                    onClick={() => {
                      setForth4(false);
                    }}
                    //  onClick={() =>{SetHover(false)}}
                    className="p-2 mx-5 border bg-yellow-500  border-green-400
                 mt-4 cursor-pointer text-2xl text-white hover:bg-black hover:text-white
                 "
                  >
                    Cancel
                  </button>
                </div>
              )}
              <img
                // onClick={()=>{setThird3(true)}}
                onClick={() => setForth4(true)}
                className="w-full h-[5rem] lg:h-[10rem] "
                // className="w-full h-[5rem] lg:h-[10rem] "
                src={PF + forth.featureMobiles}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      {/* 4th */}
      <div className="">
        {/* fifth  */}
        {/* <div> */}
        <div className="relative">
          {fifth5 && (
            <div
              className="z-7 absolute bottom-1 bg-no-100 border border-blue-5 rounded-3xl"
              style={{
                width: "80%",
                height: "80%",
                position: "absolute",
                top: "20px",
                left: "35px",
                opacity: ".6",
                borderRedius: "25px",
              }}
            >
              <div className="flex justify-center ">
                <form className="flex space-x-8" onSubmit={postFifth}>
                  <input
                    type="text"
                    className="mb-2 outline-none border h-[2.5rem] mt-7 border-blue-300"
                    placeholder="Id"
                    name="id"
                    onChange={(e) => {
                      setId(e.target.value);
                    }}
                  />
                  <input
                    className="mt-8 text-green-300 font-bold"
                    type="file"
                    placeholder=""
                    onChange={(e) => {
                      setFile(e.target.files[0]);
                    }}
                    name="file"
                  />
                  <input
                      type="text"
                      className="my-5 outline-none border border-blue-300"
                      placeholder="Enter Img Alt"
                      name="alt"
                      onChange={(e) => {
                        setAlt(e.target.value);
                      }}
                    />
                  <div className="w-full h-[10rem] border border-red mt-1 rounded-3xl ">
                    <img
                      className="w-full h-[100%] border border-white bg-teal-700 "
                      src={
                        file
                          ? URL.createObjectURL(file)
                          : PF + fifth.featureMobiles
                      }
                    />
                  </div>
                  <input
                    type="submit"
                    className="h-[3.5rem] px-3 border rounded-3xl bg-black  border-green-400
                   mt-9 cursor-pointer text-2xl text-white hover:bg-yellow-500 hover:text-black
                   "
                    value="Update"
                    name="submit"
                  />
                  <button
                    onClick={() => {
                      setFifth5(false);
                    }}
                    //  onClick={() =>{SetHover(false)}}
                    className="px-3 h-[3.5rem] border rounded-3xl bg-yellow-500  border-green-400
                   mt-9 cursor-pointer text-2xl text-white hover:bg-black hover:text-white
                   "
                  >
                    Cancel
                  </button>
                </form>
              </div>
            </div>
          )}
          <img
            onClick={() => setFifth5(true)}
            className="w-full mt-3 h-[7rem] lg:h-[13rem]"
            src={PF + fifth.featureMobiles}
          />
        </div>
        {/* <div className='relative'>
          {
            
          sixth6 && 
           <div  className=" border border-blue-5 bg-slates-300 rounded-3xl"   
              style={{width:"80%",height:"80%"
             , position:"absolute", top:'20px' ,left:"35px", opacity:'.6' ,borderRedius:"25px"
            }}>
                <div className='flex justify-center'>
                <form className="flex space-x-8 " onSubmit={postSixth}>
                    <input  type="text" className="mb-2 outline-none border h-[2.5rem] mt-4 border-blue-300" placeholder="Id" name='id'
                     onChange={(e)=>{setId(e.target.value)}}
                    />
                    <input className='mt-8 text-green-300 font-bold' type="file" placeholder="Id"
                    onChange={(e)=>{setFile(e.target.files[0])}}
                    name='file'/>
                    <div className="w-full h-[10rem] border border-red mt-.2 rounded-3xl ">

                        <img
                        className="w-full h-[100%] border border-white bg-teal-700 "
                        src={
                          file? URL.createObjectURL(file):
                         PF+sixth.featureMobiles} />
                    </div>
                 <input type='submit'
                  className="h-[3.5rem] mt-9 px-3  border rounded-3xl bg-black  border-green-400
                  cursor-pointer text-2xl text-white hover:bg-yellow-500 hover:text-black
                 " value="Update" name="submit"/>
                 <button
            onClick={()=>{setSixth6(false)}}
                 
                 className=" h-[3.5rem] mt-9 px-3 border rounded-3xl bg-yellow-500  border-green-400
                  cursor-pointer text-2xl text-white hover:bg-black hover:text-white
                 "
                 >Cancel</button>
                  
                </form>
             </div>
              </div>
         

}
            <img
            onClick={()=>setSixth6(true)}
              className="w-full mt-3 h-[7rem] lg:h-[13rem] "
              src={PF+sixth.featureMobiles}
            // onmouseenter={clicked}
            />
          </div> */}

        {/* <div className='relative'>
      {
        seventh7 &&
        <div 
              className=" border border-blue-5 bg-slates-300 rounded-3xl"   
              style={{width:"80%",height:"80%"
             , position:"absolute", top:'20px' ,left:"35px", opacity:'.6' ,borderRedius:"25px"
            }}>
                <div className='flex justify-center mb-6'>
                <form className="flex space-x-9" onSubmit={postSeventh}>
                    <input type="text" className=" h-[2.5rem] mt-6 outline-none border border-blue-300" placeholder="Id" name='id'
                     onChange={(e)=>{setId(e.target.value)}}
                    />
                    <input className='mt-8 text-green-300 font-bold' type="file" placeholder="Id"
                    onChange={(e)=>{setFile(e.target.files[0])}}
                    name='file'/>
                    <div className="w-full h-[10rem] border border-red mt-.5 rounded-3xl ">

                        <img
                        className="w-full h-[100%] border border-white bg-teal-700 "
                        src={
                          file? URL.createObjectURL(file):
                         PF+seventh.featureMobiles} />
                    </div>
                 <input type='submit'
                  className="h-[3.5rem] px-3 border  rounded-3xl bg-black  border-green-400
                 mt-9 cursor-pointer text-2xl text-white hover:bg-yellow-500 hover:text-black
                 " value="Update" name="submit"/>
                 <button
            onClick={()=>{setSeventh7(false)}}
                 
                 className="h-[3.5rem] border  rounded-3xl  px-3 bg-yellow-500  border-green-400
                 mt-9 cursor-pointer text-2xl text-white hover:bg-black hover:text-white
                 "
                 >Cancel</button>
                  
                </form>
             </div>
              </div>
      }
            <img
            onClick={()=>setSeventh7(true)}
               className="w-full mt-4 mb-7 h-[13rem] "
              src={PF+seventh.featureMobiles}
            />
    </div> */}
      </div>
      <div>
        <div></div>
      </div>
    </div>
  );
};
