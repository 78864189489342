import { Button, TextField } from '@mui/material'
import React from 'react'
import { useState, useRef } from 'react'
import { FaStar } from 'react-icons/fa';

import { Formik, formik, Form, ErrorMessage ,Field } from 'formik';
import * as Yup from "yup";
import axios from 'axios';
import { baseURL } from '../api/baseURL';
import { useNavigate } from 'react-router-dom';
import JoditEditor from "jodit-react";
import { useDispatch } from 'react-redux';
import { gadgetCreate } from '../store/NewAction/GadgetAction';
import { useSelector } from 'react-redux';

const CreateGadget = () => {
    // const [files,setFiles]=useState([]);;
    const navigate = useNavigate();
    const [file, setFile] = useState([]);
    console.log(file,'file')
    const dispatch=useDispatch();
    // console.log(files,'mulhhhhhhhhhhhhhh')

    // if(file){
    // for(let i=0 ; i<file.lenght ; i++){
    //    console.log(file[i])
     
    // }}
    const [details, setContent] = useState();
    const editor = useRef(null);
    const initialValues = {
        GadgetName: '',
        maker: '',
        model: '',
        releaseDate: '',
        price: '',
        colours: '',
        reviewTitle:'',

    }
    const validate = Yup.object({
        GadgetName: Yup.string().required("This Field is Required"),
        maker: Yup.string().required("This Field is Required"),
        model: Yup.string().required("This Field is Required"),
        releaseDate: Yup.string().required("ReThis Field is Requireduired"),
        price: Yup.string().required("This Field is Required"),
        colours: Yup.string().required("This Field is Required"),
        reviewTitle: Yup.string().required("This Field is Required"),

    })

    const config = {
        readonly: false,
        enableDragAndDropFileToEditor: true,
        uploader: {
            insertImageAsBase64URI: true,
            url: `${baseURL}/images/`
        },

        height: 400
    };
    const [fileObj, setFileObject] = useState([]);

  var [fileArray, setFileArray] = useState([]);
    if(file.length>0){ 
        fileObj.splice(0, fileObj.length);
        fileArray.splice(0, fileArray.length);
        fileObj.splice(0, fileObj.length)
        fileArray.splice(0, fileArray.length)
        fileObj.push(file);
        for (let i = 0; i < fileObj[0].length; i++) {
          fileArray.push(URL.createObjectURL(fileObj[0][i]));
        }
    }

    const createGadget=useSelector(store=>store.createGadget);
    const {loading ,error,gadgets}=createGadget;
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validate}
            onSubmit={async (values) => {
                values.reviewDetails = details;
                console.log(values)
                 dispatch(gadgetCreate(file,values,navigate))
               

            }

            }
        >
            {((formik) => (
                <div className='w-full h-screen '>
                    {loading? <h1 className='bg-zinc-200 text-3xl p-6 font-bold font-serif animate-bounce text-black '>Loading</h1> :error ? <h1>{error}</h1> :
                    <div className='w-full flex flex-col justify-center items-center '>
                        <h1 className='font-medium text-xl '>Create Gadget</h1>
                        <Form className='flex flex-col w-[50%]'>

                            <div className='flex flex-row flex-wrap mt-8 mb-5 '>
                                {

                                file.length>0 &&                 
                                // <img className='h-[15rem] rounded-xl' src={URL.createObjectURL(file[0])  }  alt='img' />
                                (fileArray || []).map((url) => (
                                    <img
                                      style={{ width: "150px", height: "200px" }}
                                      src={url ? url : "" }
                                      alt="..."
                                      key={url ? url : "" }
                                      className="p-4 border-2 border-sky-500 mx-2 my-2"
                                    />
                                      )) 
                                   }
                                
                                {/* } */}
                                <div className='flex justify-center items-center'>

                                    <label htmlFor='file'
                                        className="py-2 px-6 border-2 rounded-2xl bg-sky-500 text-center text-xl text-white"

                                    >Choose File</label>
                                    <input type='file' multiple name='file' id='file' style={{ display: 'none' }}
                                        onChange={(e) => { setFile(e.target.files) }}
                                    />
                                </div>
                            </div>
                            <div className='flex flex-col w-full space-y-6 '>
                                <TextField
                                    className='w-full'
                                    label="Gadget Name"
                                    name="GadgetName"
                                    placeholder=""
                                    type="text"
                                    onChange={formik.handleChange}
                                />
                                <ErrorMessage
                                    component="div"
                                    className="text-red-700 text-sm"
                                    name="GadgetName"
                                />
                                <TextField
                                    className='w-full'
                                    label="Maker"
                                    name="maker"

                                    type="text"
                                    onChange={formik.handleChange}
                                />
                                <ErrorMessage
                                    component="div"
                                    className="text-red-700 text-sm"
                                    name="maker"
                                />
                                <TextField
                                    className='w-full'
                                    label="Model"
                                    name="model"
                                    placeholder=""
                                    type="text"
                                    onChange={formik.handleChange}
                                />
                                <ErrorMessage
                                    component="div"
                                    className="text-red-700 text-sm"
                                    name="model"
                                />
                                <h1>Release Date</h1>
                                <TextField
                                    className='w-full'
                                    // label="ReleaseDate"
                                    name="releaseDate"

                                    type="Date"
                                    onChange={formik.handleChange}
                                />
                                <ErrorMessage
                                    component="div"
                                    className="text-red-700 text-sm"
                                    name="releaseDate"
                                />
                                <TextField
                                    className='w-full'
                                    label="Price"
                                    name="price"
                                    placeholder=""
                                    type="text"
                                    onChange={formik.handleChange}
                                />
                                <ErrorMessage
                                    component="div"
                                    className="text-red-700 text-sm"
                                    name="price"
                                />
                                <TextField
                                    className='w-full'
                                    label="Colours"
                                    name="colours"
                                    placeholder=""
                                    type="text"
                                    onChange={formik.handleChange}
                                />
                                <ErrorMessage
                                    component="div"
                                    className="text-red-700 text-sm"
                                    name="colours"
                                />
                                 <TextField
                                    className='w-full'
                                    label="Review Title"
                                    name="reviewTitle"
                                    placeholder=""
                                    type="text"
                                    onChange={formik.handleChange}
                                />
                                <ErrorMessage
                                    component="div"
                                    className="text-red-700 text-sm"
                                    name="reviewTitle"
                                />
                                <JoditEditor
                                    name="details"
                                    ref={editor}
                                    value={details}
                                    config={config}
                                    tabIndex={0}
                                    onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                                    onInit={(e, editor) => { setContent(editor) }} />

                            <div>
                            <label className="flex items-center gap-1">
                              <Field type="radio" name="stars" value="1" />

                              <FaStar className="text-[#F4871E]" />
                              {/* <FaStar className="text-[#F4871E]" /> */}
                            </label>
                          </div>
                          <div>
                          <label className="flex items-center gap-1">
                          <Field type="radio" name="stars" value="2" />
                          <FaStar className="text-[#F4871E]" />
                          <FaStar className="text-[#F4871E]" />
                          </label>
                          </div>
                          
                          <div>
                            <label className="flex items-center gap-1">
                              <Field type="radio" name="stars" value="3" />

                              <FaStar className="text-[#F4871E]" />
                              <FaStar className="text-[#F4871E]" />
                              <FaStar className="text-[#F4871E]" />
                            </label>
                          </div>
                          <div>
                            <label className="flex items-center gap-1">
                              <Field type="radio" name="stars" value="4" />

                              <FaStar className="text-[#F4871E]" />
                              <FaStar className="text-[#F4871E]" />
                              <FaStar className="text-[#F4871E]" />
                              <FaStar className="text-[#F4871E]" />
                            </label>
                          </div>
                          <div>
                            <label className="flex items-center gap-1">
                              <Field type="radio" name="stars" value="5" />

                              <FaStar className="text-[#F4871E]" />
                              <FaStar className="text-[#F4871E]" />
                              <FaStar className="text-[#F4871E]" />
                              <FaStar className="text-[#F4871E]" />
                              <FaStar className="text-[#F4871E]" />
                            </label>
                          </div>
                        
                        <ErrorMessage
                          component="div"
                          className="text-red-700 text-sm"
                          name="stars"
                        />


                                <Button type='submit' sx={{ paddingTop: '12px' }} variant='outlined'>Submit</Button>
                                <div ></div>
                            </div>
                        </Form>
                    </div>
}
                </div>
            ))}
        </Formik>
    )
}

export default CreateGadget