import React from "react";
import { ReviewContent } from "../components";

const ReviewScreen = () => {
  return (
    <div className="w-full">
      <ReviewContent/>
    </div>
  );
};

export default ReviewScreen;
