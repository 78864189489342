import React from "react";
import { TextField } from "../components";
function CheckBox(props) {
  const { label, name, value, type, unit } = props;
  return (
    <div className="flex flex-row mt-3">
      <TextField label={label} name={name} value={value} type={type} />
      <select
        className="
      dropdown-toggle
      inline-block bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out
      flex
      items-center
      whitespace-nowrap
    "
        id="dropdownSmall"
      >
        <option value="1">unit</option>
        <option value="2">GB</option>
        <option value="3">MB</option>
      </select>
    </div>
  );
}

export default CheckBox;
