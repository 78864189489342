export const GADGET_LIST_REQUEST = "GADGET_LIST_REQUEST";
export const GADGET_LIST_SUCCESS = "GADGET_LIST_SUCCESS";
export const GADGET_LIST_FAIL = "GADGET_LIST_FAIL";

export const SLIDE_LIST_REQUEST = "SLIDE_LIST_REQUEST";
export const SLIDE_LIST_SUCCESS = "SLIDE_LIST_SUCCESS";
export const SLIDE_LIST_FAIL = "SLIDE_LIST_FAIL";

export const SLIDE_CREATE_REQUEST = "SLIDE_CREATE_REQUEST";
export const SLIDE_CREATE_SUCCESS = "SLIDE_CREATE_SUCCESS";
export const SLIDE_CREATE_FAIL = "SLIDE_CREATE_FAIL";

export const SLIDE_UPDATE_REQUEST = "SLIDE_UPDATE_REQUEST";
export const SLIDE_UPDATE_SUCCESS = "SLIDE_UPDATE_SUCCESS";
export const SLIDE_UPDATE_FAIL = "SLIDE_UPDATE_FAIL";

export const SLIDE_GET_REQUEST = "SLIDE_GET_REQUEST";
export const SLIDE_GET_SUCCESS = "SLIDE_GET_SUCCESS";
export const SLIDE_GET_FAIL = "SLIDE_GET_FAIL";

export const GADGET_CREATE_RESQUEST = "GADGET_CREATE_RESQUEST";
export const GADGET_CREATE_SUCCESS = "GADGET_CREATE_SUCCESS";
export const GADGET_CREATE_FAIL = "GADGET_CREATE_FAIL";

export const GADGET_UPDATE_RESQUEST = "GADGET_UPDATE_RESQUEST";
export const GADGET_UPDATE_SUCCESS = "GADGET_UPDATE_SUCCESS";
export const GADGET_UPDATE_FAIL = "GADGET_UPDATE_FAIL";

export const GADGET_BYID_RESQUEST = "GADGET_BYID_RESQUEST";
export const GADGET_BYID_SUCCESS = "GADGET_BYID_SUCCESS";
export const GADGET_BYID_FAIL = "GADGET_BYID_FAIL";

export const CONTACT_LIST_REQUEST = "SLIDE_LIST_REQUEST";
export const CONTACT_LIST_SUCCESS = "SLIDE_LIST_SUCCESS";
export const CONTACT_LIST_FAIL = "SLIDE_LIST_FAIL";

export const CONTACT_GET_REQUEST = "SLIDE_GET_REQUEST";
export const CONTACT_GET_SUCCESS = "SLIDE_GET_SUCCESS";
export const CONTACT_GET_FAIL = "SLIDE_GET_FAIL";

export const CSVFILE_CREATE_REQUEST = "CSVFILE_CREATE_REQUEST";
export const CSVFILE_CREATE_SUCCESS = "CSVFILE_CREATE_SUCCESS";
export const CSVFILE_CREATE_FAIL = "CSVFILE_CREATE_FAIL";

export const CSVFILE_GET_REQUEST = "CSVFILE_GET_REQUEST";
export const CSVFILE_GET_SUCCESS = "CSVFILE_GET_SUCCESS";
export const CSV_GET_FAIL = "CSV_GET_FAIL";