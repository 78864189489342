import axios from "axios"
import { baseURL } from "../../api/baseURL"
import { GADGET_BYID_FAIL, GADGET_BYID_RESQUEST, GADGET_BYID_SUCCESS, GADGET_CREATE_FAIL, GADGET_CREATE_RESQUEST, GADGET_CREATE_SUCCESS, GADGET_LIST_FAIL, GADGET_LIST_REQUEST, GADGET_LIST_SUCCESS, GADGET_UPDATE_FAIL, GADGET_UPDATE_RESQUEST, GADGET_UPDATE_SUCCESS } from "../Constants/Gadget"

export const gadgetLists=()=>async(dispatch)=>{
      try{
    dispatch({type : GADGET_LIST_REQUEST})
    const {data} =await axios.get(`${baseURL}/api/v1/gadget`);
    dispatch({type : GADGET_LIST_SUCCESS, payload:data.succes })
      }catch(error){
    dispatch({type : GADGET_LIST_FAIL,payload:error.response && error.response.data.message ? 
        error.response.data.message : error.message })
      }

}





export const gadgetCreate=(file,values,navigate)=>async(dispatch)=>{
      try{
    dispatch({type : GADGET_CREATE_RESQUEST})

    // if (file) {
                   
                //     const datas=new FormData();
                //     for(let i = 0; i <file.length; i++) {     
                //         datas.append('file', file[i]);
                //     }  
                const datas = new FormData();
                if (file.length>0) {
                  for (var i = 0; i < file.length; i++) { 
                  datas.append("file", file[i])
                  }
                
                    console.log(datas,'datasssss');
                    console.log(values)
                    // const send={datas,values};
                    try {
                        const { data } = await axios.post(`${baseURL}/api/v1/gadget`,

                            file === undefined ? null : datas,
                            {
                                params: {
                                    values,
                                },
                            },
                            {
                                headers: {
                                    mode: 'no-cors',
                                    
                                    accept: "application/json",
                                    "Content-Type": "multipart/form-data",

                                },
                            }

                        );
             dispatch({type : GADGET_CREATE_SUCCESS, payload:data.success })

                        if (data) {
                            alert('Gadget Created')
                            navigate('/gadget')
                        }

                    } catch (err) {
                        console.log(err)
                    }
                } else {
                    alert('file is required')
                }

      }catch(error){
    dispatch({type : GADGET_CREATE_FAIL,payload:error.response && error.response.data.message ? 
        error.response.data.message : error.message })
      }

}

export const Gadgetupdate=(file,id,values,navigate)=>async(dispatch)=>{
     dispatch({type:GADGET_UPDATE_RESQUEST});
     const datas = new FormData();

     if (file){
               for (var i = 0; i < file.length; i++) { 
               datas.append("file", file[i])
               }
             //   if (file) {
             //     for (var i = 0; i < file.length; i++) { 
             //     datas.append("file", file[i])
             //     }
     console.log(datas,'dfdffdfdf');
     console.log(file,'dfdffdfdf');

     }
     // else{
        
     //    arry= gadget.photo
     // }
     try {
         const { data } = await axios.put(`${baseURL}/api/v1/gadget/${id}`, file && datas ,
             {
                 params: {
                     values
                 }
             },
             {
                 headers: {
                     mode: 'no-cors',
                     // 'Authorization': `${admin}`,
                     accept: "application/json",
                     "Content-Type": "multipart/form-data",

                 }
             }
         )
  dispatch({type:GADGET_UPDATE_SUCCESS,payload:data.success})
         if (data) {
             alert('Updated Successfully');
             navigate('/gadget')
         }
     } catch (err) {
  dispatch({type:GADGET_UPDATE_FAIL,payload:err})

         console.log(err)
     } 

}

export const gadgetSingleAction=(id)=>async(dispatch)=>{
    try{
        dispatch({type : GADGET_BYID_RESQUEST})
        const {data} =await axios.get(`${baseURL}/api/v1/gadget/${id}`);
        console.log(data,'data from dispatch')
        dispatch({type : GADGET_BYID_SUCCESS, payload:data.success })
          }catch(error){
        dispatch({type : GADGET_BYID_FAIL,payload:error.response && error.response.data.message ? 
            error.response.data.message : error.message })
          }
    

}