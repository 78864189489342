import React, { useState, useEffect, useRef } from "react";
import { Formik, Form, ErrorMessage, FieldArray, Field } from "formik";
import { TextField } from "../components";
import { useSelector, useDispatch } from "react-redux";
import Papa from "papaparse";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { addMobile } from "../store/mobilesActions";
import { editMobile } from "../store/actions/getMobileActions";
import { CreateCSVFILE } from "../store/NewAction/CSVAction";
import { baseURL } from "../api/baseURL";
import { useLocation } from "react-router-dom";
import JoditEditor from "jodit-react";

let row = "sssss";

const validate = Yup.object({
  brandName: Yup.string().required("Required"),
  modelNo: Yup.string().required("Required"),
  alt: Yup.string(),

  OS: Yup.object().shape({
    type: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
  }),
  UI: Yup.string().required("Required"),
  dimensions: Yup.string().required("Required"),
  weight: Yup.number()
    .positive()
    .required("Required!")
    .integer()
    .typeError("It should be a number"),
  sim: Yup.string().required("Required"),
  colors: Yup.string().required("Required"),
  twoGBand: Yup.object().shape({
    exists: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
  }),
  threeGBand: Yup.object().shape({
    exists: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
  }),
  fourGBand: Yup.object().shape({
    exists: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
  }),
  fiveGBand: Yup.object().shape({
    exists: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
  }),
  CPU: Yup.string().required("Required"),
  chipSet: Yup.string().required("Required"),
  GPU: Yup.string().required("Required"),
  technology: Yup.string().required("Required"),
  size: Yup.number()
    .positive()
    .required("Required!")
    .typeError("It should be a number"),
  resolution: Yup.string().required("Required"),
  ram: Yup.object().shape({
    value: Yup.array()
      .of(
        Yup.number()
          .positive()
          .required("Required!")
          .integer()
          .typeError("It should be a number")
      )
      .min(1, "at least 1")
      .required("required"),
    unit: Yup.string().required("Required"),
  }),
  rom: Yup.object().shape({
    value: Yup.array()
      .of(
        Yup.number()
          .positive()
          .required("Required!")
          .integer()
          .typeError("It should be a number")
      )
      .min(1, "at least 1")
      .required("required"),
    unit: Yup.string().required("Required"),
  }),
  card: Yup.string()
    // .positive()
    .required("Required!")
    .min(0),
  // .integer()
  // .typeError("It should be a number"),
  mainCam: Yup.object().shape({
    value: Yup.array()
      .of(
        Yup.number()
          .positive()
          .required("Required!")
          .integer()
          .typeError("It should be a number")
      )
      .min(1, "at least 1")
      .required("required"),
    description: Yup.string().required("Required"),
    flash: Yup.string().required("Required"),
  }),
  frontCam: Yup.object().shape({
    value: Yup.array()
      .of(
        Yup.number()
          .positive()
          .required("Required!")
          .integer()
          .typeError("It should be a number")
      )
      .min(1, "at least 1")
      .required("required"),
    description: Yup.string().required("Required"),
    flash: Yup.string().required("Required"),
  }),
  features: Yup.string().required("Required"),
  WLAN: Yup.object().shape({
    exists: Yup.string().required("Required"),
  }),
  bluetooth: Yup.object().shape({
    exists: Yup.string().required("Required"),
  }),
  GPS: Yup.object().shape({
    exists: Yup.string().required("Required"),
  }),
  radio: Yup.object().shape({
    exists: Yup.string().required("Required"),
  }),
  USB: Yup.object().shape({
    exists: Yup.string().required("Required"),
  }),
  NFC: Yup.object().shape({
    exists: Yup.string().required("Required"),
  }),
  infrared: Yup.string().required("Required"),
  data: Yup.object().shape({
    exists: Yup.string().required("Required"),
  }),
  sensor: Yup.string().required("Required"),
  audio: Yup.string().required("Required"),
  browser: Yup.string().required("Required"),
  messaging: Yup.string().required("Required"),
  games: Yup.string().required("Required"),
  torch: Yup.string().required("Required"),
  extraFeatures: Yup.string().required("Required"),
  battery: Yup.object().shape({
    capacity: Yup.number()
      .positive()
      .required("Required!")
      .integer()
      .typeError("It should be a number"),
    description: Yup.string().required("Required"),
  }),
  priceInPKR: Yup.number()
    .positive()
    .required("Required!")
    .integer()
    .typeError("Price should be a number"),
  priceInUSD: Yup.number()
    .positive()
    .required("Required!")
    .integer()
    .typeError("Price should be a number"),
  //shortDescription: Yup.string().required("Required"),
  //longDescription: Yup.string().required("Required"),
});
const Create = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { mobileList } = useSelector((state) => state.mobiles);

  const [file, setFile] = useState();
  const allowedExtensions = ["csv"];
  // This state will store the parsed data
  const [value, setvalue] = useState("");
  const [data, setData] = useState([]);

  // It state will contain the error when
  // correct file extension is not used
  const [error, setError] = useState("");
  let items;
  let intro;
  let sitems;
  let litems;
  const [item, setItem] = useState();
  const [fileObj, setFileObject] = useState([]);
  const [fileArray, setFileArray] = useState([]);
  const [edit, setEdit] = useState(false);
  const [formValues, setFormsValues] = useState([]);
  const [init, setInit] = useState("");
  useEffect(() => {
    setContent(items);
    setIntroduction(intro);
  }, [item || value]);
  const editor = useRef(null);
  const [file1, setFile1] = useState();
  const [shortDescription, setContent] = useState(items);
  const [brandName, setBrandName] = useState("");
  const [alt, setAlt] = useState();
  const [longDescription, setIntroduction] = useState(intro);
  // console.log(brandName,';brand')

  // var inputVal;
  // console.log(formValues,'forms');

  var inputVal = localStorage.getItem("formik")
    ? JSON.parse(localStorage.getItem("formik"))
    : null;
  var Jdetails = localStorage.getItem("joditsmall")
    ? localStorage.getItem("joditsmall")
    : null;
  var joditLong = localStorage.getItem("joditlarge")
    ? localStorage.getItem("joditlarge")
    : null;
  // var joditLong = JSON.parse(localStorage.getItem("joditLarge"))||null;

  const handleFileChange = (e) => {
    setError("");

    // Check if user has entered the file
    if (e.target.files.length) {
      const inputFile = e.target.files[0];

      // Check the file extensions, if it not
      // included in the allowed extensions
      // we show the error
      const fileExtension = inputFile?.type.split("/")[1];
      if (!allowedExtensions.includes(fileExtension)) {
        setError("Please input a csv file");
        return;
      }

      // If input type is correct set the state
      setFile(inputFile);
    }
  };
  const handleParse = () => {
    // If user clicks the parse button without
    // a file we show a error
    if (!file) return setError("Enter a valid file");

    // Initialize a reader which allows user
    // to read any file or blob.
    const reader = new FileReader();

    // Event listener on reader when the file
    // loads, we parse it and set the data.
    reader.onload = async ({ target }) => {
      const csv = Papa.parse(target.result, { header: true });
      const parsedData = csv?.data;
      // const columns = Object.keys(parsedData);
      // setData(columns);
      // console.log(columns , "Data1");
      console.log(parsedData[0].brandName, "Data2");
      console.log(parsedData, "Data4");
      setvalue(parsedData);
    };
    reader.readAsText(file, "Data2");
  };

  const localStorages = (val) => {
    localStorage.setItem("formik", JSON.stringify(val));
    console.log(val, "Data Saved in LocalStorage");
  };

  const handleReset = () => {
    // localStorage.removeItem("formsValz");
    // localStorage.removeItem("longDetails");
    // localStorage.removeItem("details");
    localStorage.removeItem("formik");
    localStorage.removeItem("joditsmall");
    localStorage.removeItem("joditlarge");
    // navigate('/dashboard/mobileDetail/createNew')
    navigate("/dashboard");
  };
  const { id } = useParams();

  const uploadMultipleFiles = (e) => {
    console.log("1");
    setFile(e.target.files);
    fileObj.splice(0, fileObj.length);
    fileArray.splice(0, fileArray.length);
    fileObj.splice(0, fileObj.length);
    fileArray.splice(0, fileArray.length);
    fileObj.push(e.target.files);
    for (let i = 0; i < fileObj[0].length; i++) {
      fileArray.push(URL.createObjectURL(fileObj[0][i]));
    }
  };
  const location = useLocation();
  var getData;
  useEffect(() => {
    if (id !== "createNew") {
      mobileList.map((item) => {
        if (item?._id?.toString() === id?.toString()) {
          setItem(item);
          mobileList[0].camera.main.value.map((item, index) =>
            console.log("item: ", index, item)
          );

          setFileArray(item.mobilePhotos.map((item) => `${baseURL}${item}`));
        }
        setEdit(location.state.editMobile);
        console.log("location.state.editMobile", location.state.editMobile);
      });
    }
  }, [id, addMobile]);
  console.log(value, "sharjeell112435");
  console.log(inputVal, "inputVal");
  const initialValues = {
    brandName: `${item
      ? item.brandName
      : value
        ? value[0].brandName
        : inputVal
          ? inputVal.brandName
          : ""
      }`,
    modelNo: `${item
      ? item.modelNumber
      : value
        ? value[0].modelNumber
        : inputVal
          ? inputVal.modelNo
          : ""
      }`,
    alt: `${item
      ? item.alt || ""
      : value
        ? value[0].alt || ""
        : inputVal
          ? inputVal.alt
          : ""
      }`,

    // alt: item?.alt || (value && value[0]?.alt) || (inputVal?.alt) || "",

    releaseDate: `${item
      ? item.releaseDate
      : value
        ? value[0].releaseDate
        : inputVal
          ? inputVal.releaseDate
          : ""
      }`,
    OS: {
      type: `${item
        ? item.build.OS.type
        : value
          ? value[0].OSType
          : inputVal
            ? inputVal.OS.type
            : ""
        }`,
      description: `${item
        ? item.build.OS.description
        : value
          ? value[0].OSdescription
          : inputVal
            ? inputVal.OS.description
            : ""
        }`,
    },
    UI: `${item ? item.build.UI : value ? value[0].UI : inputVal ? inputVal.UI : ""
      }`,
    dimensions: `${item
      ? item.build.dimensions
      : value
        ? value[0].dimensions
        : inputVal
          ? inputVal.dimensions
          : ""
      }`,
    weight: `${item
      ? item.build.weight
      : value
        ? value[0].weightgram
        : inputVal
          ? inputVal.weight
          : ""
      }`,
    sim: `${item
      ? item.build.sim
      : value
        ? value[0].sim
        : inputVal
          ? inputVal.sim
          : ""
      }`,
    colors: `${item
      ? item.build.colors
      : value
        ? value[0].colors
        : inputVal
          ? inputVal.colors
          : ""
      }`,

    twoGBand: {
      exists: `${item
        ? item.frequency.twoGBand.exists
          ? "yes"
          : "no"
        : value
          ? value[0].twoGBand
          : inputVal
            ? inputVal.twoGBand.exists
            : ""
        }`,
      description: `${item
        ? item.frequency.twoGBand.description
        : value
          ? value[0].twoGBanddescription
          : inputVal
            ? inputVal.twoGBand.description
            : ""
        }`,
    },
    threeGBand: {
      exists: `${item
        ? item.frequency.threeGBand.exists
          ? "yes"
          : "no"
        : value
          ? value[0].threeGBand
          : inputVal
            ? inputVal.threeGBand.exists
            : ""
        }`,
      description: `${item
        ? item.frequency.threeGBand.description
        : value
          ? value[0].threeGBanddescription
          : inputVal
            ? inputVal.threeGBand.description
            : ""
        }`,
    },
    fourGBand: {
      exists: `${item
        ? item.frequency.fourGBand.exists
          ? "yes"
          : "no"
        : value
          ? value[0].fourGBand
          : inputVal
            ? inputVal.fourGBand.exists
            : ""
        }`,
      description: `${item
        ? item.frequency.fourGBand.description
        : value
          ? value[0].fourGBanddescription
          : inputVal
            ? inputVal.fourGBand.description
            : ""
        }`,
    },
    fiveGBand: {
      exists: `${item
        ? item.frequency.fiveGBand.exists
          ? "yes"
          : "no"
        : value
          ? value[0].fiveGBand
          : inputVal
            ? inputVal.fiveGBand.exists
            : ""
        }`,
      description: `${item
        ? item.frequency.fiveGBand.description
        : value
          ? value[0].fiveGBanddescription
          : inputVal
            ? inputVal.fiveGBand.description
            : ""
        }`,
    },
    CPU: `${item
      ? item.processor.CPU
      : value
        ? value[0].CPU
        : inputVal
          ? inputVal.CPU
          : ""
      }`,
    chipSet: `${item
      ? item.processor.chipSet
      : value
        ? value[0].chipSet
        : inputVal
          ? inputVal.chipSet
          : ""
      }`,
    GPU: `${item
      ? item.processor.GPU
      : value
        ? value[0].GPU
        : inputVal
          ? inputVal.GPU
          : ""
      }`,
    technology: `${item
      ? item.display.technology
      : value
        ? value[0].technology
        : inputVal
          ? inputVal.technology
          : ""
      }`,
    size: `${item
      ? item.display.size
      : value
        ? value[0].sizeinches
        : inputVal
          ? inputVal.size
          : ""
      }`,
    resolution: `${item
      ? item.display.resolution
      : value
        ? value[0].resolution
        : inputVal
          ? inputVal.resolution
          : ""
      }`,
    protection: `${item
      ? item.display.protection
      : value
        ? value[0].protection
        : inputVal
          ? inputVal.protection
          : ""
      }`,
    ram: {
      value: item ? item.memory.ram.value : inputVal ? inputVal.ram.value : [],
      unit: `${item
        ? item.memory.ram.unit
        : value
          ? value[0].ramunit
          : inputVal
            ? inputVal.ram.unit
            : ""
        }`,
    },
    priceInPKR: `${item ? item.priceInPKR : value ? value[0].priceInPKR : ""}`,
    extraFeatures: `${item ? item.display.extraFeatures : value ? value[0].extraFeatures : ""
      }`,
    rom: {
      value: item ? item.memory.rom.value : inputVal ? inputVal.ram.value : [],
      unit: `${item
        ? item.memory.rom.unit
        : value
          ? value[0].romunit
          : inputVal
            ? inputVal.rom.unit
            : ""
        }`,
    },
    card: `${item
      ? item.memory.card
      : value
        ? value[0].card
        : inputVal
          ? inputVal.card
          : ""
      }`,
    mainCam: {
      value: item
        ? item.camera.main.value
        : inputVal
          ? inputVal.mainCam.value
          : [],
      description: `${item
        ? item.camera.main.description
        : value
          ? value[0].mainCamdescription
          : inputVal
            ? inputVal.mainCam.description
            : ""
        }`,
      flash: `${item
        ? item.camera.main.flash
          ? "yes"
          : "no"
        : value
          ? value[0].mainCamflash
          : inputVal
            ? inputVal.mainCam.flash
            : ""
        }`,
    },
    frontCam: {
      value: item
        ? item.camera.front.value
        : inputVal
          ? inputVal.frontCam.value
          : [],
      description: `${item
        ? item.camera.front.description
        : value
          ? value[0].frontCamdescription
          : inputVal
            ? inputVal.frontCam.description
            : ""
        }`,
      flash: `${item
        ? item.camera.front.flash
          ? "yes"
          : "no"
        : value
          ? value[0].frontCamflash
          : inputVal
            ? inputVal.frontCam.flash
            : ""
        }`,
    },
    features: `${item
      ? item.camera.features
      : value
        ? value[0].cameraFeatures
        : inputVal
          ? inputVal.features
          : ""
      }`,
    WLAN: {
      exists: `${item
        ? item.connectivity.WLAN.exists
          ? "yes"
          : "no"
        : value
          ? value[0].WLANexist
          : inputVal
            ? inputVal.WLAN.exists
            : ""
        }`,
      description: `${item
        ? item.connectivity.WLAN.description
        : value
          ? value[0].WLANdescription
          : inputVal
            ? inputVal.WLAN.description
            : ""
        }`,
    },
    bluetooth: {
      exists: `${item
        ? item.connectivity.bluetooth.exists
          ? "yes"
          : "no"
        : value
          ? value[0].bluetoothexist
          : inputVal
            ? inputVal.bluetooth.exists
            : ""
        }`,
      description: `${item
        ? item.connectivity.bluetooth.description
        : value
          ? value[0].bluetoothdescription
          : inputVal
            ? inputVal.bluetooth.description
            : ""
        }`,
    },
    GPS: {
      exists: `${item
        ? item.connectivity.GPS.exists
          ? "yes"
          : "no"
        : value
          ? value[0].GPSexist
          : inputVal
            ? inputVal.GPS.exists
            : ""
        }`,
      description: `${item
        ? item.connectivity.GPS.description
        : value
          ? value[0].GPSdescription
          : inputVal
            ? inputVal.GPS.description
            : ""
        }`,
    },
    radio: {
      exists: `${item
        ? item.connectivity.radio.exists
          ? "yes"
          : "no"
        : value
          ? value[0].radioexist
          : inputVal
            ? inputVal.radio.exists
            : ""
        }`,
      description: `${item
        ? item.connectivity.radio.description
        : value
          ? value[0].radiodescription
          : inputVal
            ? inputVal.radio.description
            : ""
        }`,
    },
    USB: {
      exists: `${item
        ? item.connectivity.USB.exists
          ? "yes"
          : "no"
        : value
          ? value[0].USBexist
          : inputVal
            ? inputVal.USB.exists
            : ""
        }`,
      description: `${item
        ? item.connectivity.USB.description
        : value
          ? value[0].USBdescription
          : inputVal
            ? inputVal.USB.description
            : ""
        }`,
    },
    NFC: {
      exists: `${item
        ? item.connectivity.NFC.exists
          ? "yes"
          : "no"
        : value
          ? value[0].NFCexist
          : inputVal
            ? inputVal.NFC.exists
            : ""
        }`,
      description: `${item
        ? item.connectivity.NFC.description
        : value
          ? value[0].NFCdescription
          : inputVal
            ? inputVal.NFC.description
            : ""
        }`,
    },
    infrared: `${item
      ? item.connectivity.infrared
        ? "yes"
        : "no"
      : value
        ? value[0].infrared
        : inputVal
          ? inputVal.infrared
          : ""
      }`,
    data: {
      exists: `${item
        ? item.connectivity.data.exists
          ? "yes"
          : "no"
        : value
          ? value[0].dataexists
          : inputVal
            ? inputVal.data.exists
            : ""
        }`,
      description: `${item
        ? item.connectivity.data.description
        : value
          ? value[0].datadescription
          : inputVal
            ? inputVal.data.description
            : ""
        }`,
    },
    sensor: `${item
      ? item.features.sensor
      : value
        ? value[0].sensor
        : inputVal
          ? inputVal.sensor
          : ""
      }`,
    audio: `${item
      ? item.features.audio
      : value
        ? value[0].audio
        : inputVal
          ? inputVal.audio
          : ""
      }`,
    browser: `${item
      ? item.features.browser
      : value
        ? value[0].browser
        : inputVal
          ? inputVal.browser
          : ""
      }`,
    messaging: `${item
      ? item.features.messaging
      : value
        ? value[0].messaging
        : inputVal
          ? inputVal.messaging
          : ""
      }`,
    games: `${item
      ? item.features.games
      : value
        ? value[0].games
        : inputVal
          ? inputVal.games
          : ""
      }`,
    torch: `${item
      ? item.features.torch
      : value
        ? value[0].torch
        : inputVal
          ? inputVal.torch
          : ""
      }`,
    extraFeatures: `${item
      ? item.features.extraFeatures
      : value
        ? value[0].extraFeatures
        : inputVal
          ? inputVal.extraFeatures
          : ""
      }`,
    battery: {
      capacity: `${item
        ? item.battery.capacity
        : value
          ? value[0].battery
          : inputVal
            ? inputVal.battery.capacity
            : ""
        }`,
      description: `${item
        ? item.battery.description
        : value
          ? value[0].BatteryDescription
          : inputVal
            ? inputVal.battery.description
            : ""
        }`,
    },
    priceInPKR: `${item
      ? item.priceInPKR
      : value
        ? value[0].priceInPKR
        : inputVal
          ? inputVal.priceInPKR
          : ""
      }`,
    priceInUSD: `${item
      ? item.priceInUSD
      : value
        ? value[0].priceInUSD
        : inputVal
          ? inputVal.priceInUSD
          : ""
      }`,
    shortDescription: `${item ? item.shortDescription : value ? value[0].shortDescription : ""
      }`,
    longDescription: `${item ? item.longDescription : value ? value[0].longDescription : ""
      }`,
    releaseDate: `${item
      ? item.releaseDate
      : value
        ? value[0].releaseDate
        : inputVal
          ? inputVal.releaseDate
          : ""
      }`,
    reviewUrl: `${item
      ? item.reviewUrl
      : value
        ? value[0].reviewUrl
        : inputVal
          ? inputVal.reviewUrl
          : ""
      }`,
  };

  if (item || value[0]) {
    if (item) {
      items = item.shortDescription;
      intro = item.longDescription;
    } else if (value[0]) {
      items = value[0].shortDescription;
      intro = value[0].longDescription;
    }
  } else if (Jdetails || joditLong) {
    items = Jdetails;
    intro = joditLong;
  }

  const Clicked = (e) => {
    dispatch(CreateCSVFILE(file1, navigate));
  };

  // useEffect(()=>{
  //     setBrandName(initialValues);

  // },[])
  const config = {
    readonly: false,
    enableDragAndDropFileToEditor: true,
    uploader: {
      insertImageAsBase64URI: true,
    },

    height: 400,
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validate}
        onSubmit={async (values, value) => {
          console.log(values);
          if (shortDescription == null || longDescription == null) {
            alert("Short And Long Description are Required");
          }
          if (values) {
            values.shortDescription = shortDescription;
            values.longDescription = longDescription;
          }
          // else if(value[0]){
          // value[0].shortDescription = shortDescription;
          // value[0].longDescription = longDescription;
          // }
          console.log(values.shortDescription, "values");
          console.log(values.longDescription, "valuesssss");

          //setContent(item.shortDescription);
          //setIntroduction(item.longDescription);
          const admin = await JSON.parse(localStorage.getItem("admin"));
          edit
            ? dispatch(
              editMobile(
                location.state.mobileId,
                values,
                file,
                navigate,
                admin
              )
            )
            : dispatch(addMobile(values, file, navigate, admin));
        }}
      // navigate("/dashboard/mobileDetail/")
      >
        {(formik) => (
          <div className="border border-orange flex justify-center ">
            <div className="w-1/2">
              <div className="w-full p-4 flex justify-center">
                {id !== "createNew" ? (
                  <h1>Edit Mobile </h1>
                ) : (
                  <h1>Create Mobile </h1>
                )}
              </div>
              <Form>
                <div className="flex flex-col space-y-3">
                  <div className="flex flex-row flex-wrap">
                    {(fileArray || []).map((url) => (
                      <img
                        style={{ width: "150px", height: "200px" }}
                        src={url}
                        alt="..."
                        key={url}
                        className="p-4 border-2 border-sky-500 mx-2 my-2"
                      />
                    ))}
                  </div>
                  <div className="mx-auto my-5">
                    <div className="flex">  <input
                      type="file"
                      className="form-control hidden"
                      onChange={uploadMultipleFiles}
                      multiple
                      id="input"
                      name="images"
                      accept="image/*"
                    />
                      <div className="flex flex-col mb-5">
                        <label className="outline-none px-2 mb-[-15px]">Img Alt</label>
                        <TextField
                          name="alt"
                          placeholder="Enter Image alt"
                          value={formik.values.alt}
                          type="text"
                          className="outline-none border w-full py-2 rounded px-2"
                        />
                      </div>
                    </div>
                    <label
                      className="py-2 px-5 border-2 rounded-2xl bg-sky-500 text-center text-white"
                      htmlFor="input"
                    >
                      Choose your Photo
                    </label>
                  </div>

                  <div className="bg-blue-700 text-center text-white py-2 my-4">
                    <h1>Mobile Info</h1>
                  </div>
                  <TextField
                    label="Brand Name"
                    name="brandName"
                    placeholder="Enter brand name"
                    // onChange={(e)=>{setBrandName(e.target.value) }}
                    value={formik.values.brandName}
                    type="text"
                  />
                  <TextField
                    label="Model Number"
                    name="modelNo"
                    placeholder="Enter model number here"
                    value={formik.values.modelNo}
                    type="text"
                  />
                  {/* {setInit(formik.values)} */}
                  {/* <button onClick={(e)=>setBrandName(formik.values)}>save to storage</button> */}
                  <TextField
                    label="Release Date"
                    name="releaseDate"
                    placeholder="Enter relasse date here"
                    value={formik.values.releaseDate}
                    type="Date"
                  />
                  <div className="bg-blue-700 text-center text-white py-2">
                    <h1>Build</h1>
                  </div>
                  <div className="flex flex-row space-y-3 gap-3 justify-between items-center">
                    <div className="w-full">
                      <TextField
                        label="Operatig System"
                        name="OS.description"
                        value={formik.values.OS.description}
                        type="text"
                      />
                    </div>

                    <div className="pt-7">
                      <select
                        className="h-8"
                        name="OS.type"
                        value={formik.values.OS.type}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        style={{ display: "block" }}
                      >
                        <option value="" label="Select OS" />
                        <option value="Android" label="Android" />
                        <option value="iOS" label="iOS" />
                        <option value="Bada" label="Bada" />
                        <option value="Blackberry" label="Blackberry OS" />
                        <option value="Windows" label="Windows OS" />
                        <option value="Symbian" label="Symbian OS" />
                        <option value="Tizen" lable="Tizen" />
                      </select>
                    </div>

                    <ErrorMessage
                      component="div"
                      className="text-red-700 text-sm"
                      name="OS.type"
                    />
                  </div>
                  <TextField
                    label="UI"
                    name="UI"
                    value={formik.values.UI}
                    type="text"
                  />
                  <TextField
                    label="Dimensions"
                    name="dimensions"
                    value={formik.values.dimensions}
                    type="text"
                  />
                  <TextField
                    label="Weight (In Grams)"
                    name="weight"
                    placeholder="Weight must be in grams"
                    value={formik.values.weight}
                    // onChange={form}
                    type="text"
                  />
                  <TextField
                    label="Sim"
                    name="sim"
                    value={formik.values.sim}
                    type="text"
                  />
                  {/* Frequency*/}

                  <TextField
                    label="Colors"
                    name="colors"
                    value={formik.values.colors}
                    type="text"
                  />
                  {/* Frequency*/}
                  <div className="bg-green-300 text-center text-black py-2">
                    <h1>Frequency</h1>
                  </div>
                  {/* 2G Band*/}
                  <div className="flex flex-row items-center gap-3 justify-between space-y-3">
                    <div className="w-full">
                      <TextField
                        label="2G Band"
                        name="twoGBand.description"
                        value={formik.values.twoGBand.description}
                        type="text"
                      />
                    </div>
                    <div className="pt-7">
                      <select
                        className="h-8"
                        name="twoGBand.exists"
                        value={formik.values.twoGBand.exists}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        style={{ display: "block" }}
                      >
                        <option value="" label="Select Option" />
                        <option value="yes" label="Yes" />
                        <option value="no" label="No" />
                      </select>
                    </div>

                    <ErrorMessage
                      component="div"
                      className="text-red-700 text-sm"
                      name="twoGBand.exists"
                    />
                  </div>
                  {/* 3G BAND*/}

                  <div className="flex flex-row space-y-3 gap-3 items-center justify-between">
                    <div className="w-full">
                      <TextField
                        label="3G Band"
                        name="threeGBand.description"
                        value={formik.values.threeGBand.description}
                        type="text"
                      />
                    </div>
                    <div className="pt-7">
                      <select
                        className="h-8"
                        name="threeGBand.exists"
                        value={formik.values.threeGBand.exists}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        style={{ display: "block" }}
                      >
                        <option value="" label="Select Option" />
                        <option value="yes" label="Yes" />
                        <option value="no" label="No" />
                      </select>
                    </div>
                    <ErrorMessage
                      component="div"
                      className="text-red-700 text-sm"
                      name="threeGBand.exists"
                    />
                  </div>
                  {/* 4G BAND*/}
                  <div className="flex flex-row space-y-3 gap-3 items-center justify-between">
                    <div className="w-full">
                      <TextField
                        label="4G Band"
                        name="fourGBand.description"
                        value={formik.values.fourGBand.description}
                        type="text"
                      />
                    </div>
                    <div className="pt-7">
                      <select
                        className="h-8"
                        name="fourGBand.exists"
                        value={formik.values.fourGBand.exists}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        style={{ display: "block" }}
                      >
                        <option value="" label="Select Option" />
                        <option value="yes" label="Yes" />
                        <option value="no" label="No" />
                      </select>
                    </div>

                    <ErrorMessage
                      component="div"
                      className="text-red-700 text-sm"
                      name="fourGBand.exists"
                    />
                  </div>
                  {/* 5G BAND*/}
                  <div className="flex flex-row space-y-3 gap-3 pb-1 justify-between items-center">
                    <div className="w-full">
                      <TextField
                        label="5G Band"
                        name="fiveGBand.description"
                        value={formik.values.fiveGBand.description}
                        type="text"
                      />
                    </div>
                    <div className="pt-7">
                      <select
                        className="h-8"
                        name="fiveGBand.exists"
                        value={formik.values.fiveGBand.exists}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        style={{ display: "block" }}
                      >
                        <option value="" label="Select Option" />
                        <option value="yes" label="Yes" />
                        <option value="no" label="No" />
                      </select>
                    </div>

                    <ErrorMessage
                      component="div"
                      className="text-red-700 text-sm"
                      name="fiveGBand.exists"
                    />
                  </div>
                  {/* Processor*/}
                  <div className="bg-blue-600 text-center text-black py-2">
                    <h1>Processor</h1>
                  </div>
                  <TextField
                    label="CPU"
                    name="CPU"
                    value={formik.values.CPU}
                    type="text"
                  />
                  <TextField
                    label="Chipset"
                    name="chipSet"
                    value={formik.values.chipSet}
                    type="text"
                  />
                  <TextField
                    label="GPU"
                    name="GPU"
                    value={formik.values.GPU}
                    type="text"
                  />
                  {/* Display*/}
                  <div className="bg-yellow-600 text-center text-white font-bold py-2">
                    <h1>Display</h1>
                  </div>
                  <TextField
                    label="Technology"
                    name="technology"
                    value={formik.values.technology}
                    type="text"
                  />
                  <TextField
                    label="Size (In inches)"
                    name="size"
                    value={formik.values.size}
                    type="text"
                  />
                  <TextField
                    label="Resolution"
                    name="resolution"
                    value={formik.values.resolution}
                    type="text"
                  />
                  <TextField
                    label="Protection"
                    name="protection"
                    value={formik.values.protection}
                    type="text"
                  />
                  {/* Memory*/}
                  <div className="bg-blue-600 text-center text-black py-2">
                    <h1>Memory</h1>
                  </div>
                  <div className="flex flex-row space-y-3  gap-3 items-center justify-between">
                    <div className="w-full">
                      {/* <TextField
                        label="RAM"
                        name="ram.value"
                        value={formik.values.ram.value}
                        type="text"
                      /> */}
                      <label className="block text-gray-700 text-xs mb-2">
                        RAM
                      </label>

                      <FieldArray
                        name="ram.value"
                        render={(arrayHelpers) => (
                          <div className="rounded text-center w-full mt-2">
                            {formik.values.ram.value &&
                              formik.values.ram.value.length > 0 ? (
                              formik.values.ram.value.map((friend, index) => (
                                <div
                                  key={index}
                                  className="flex items-center w-full justify-center"
                                >
                                  <Field
                                    className="h-8 w-52 bg-slate-100 px-4 border-1 py-4 flex items-center justify-center "
                                    name={`ram.value.${index}`}
                                  />
                                  <button
                                    className="h-8 w-8 bg-red-500 text-slate-50 px-4 py-4 flex items-center justify-center "
                                    type="button"
                                    onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                  >
                                    -
                                  </button>
                                  <button
                                    type="button"
                                    className="h-8 w-8 bg-green-500 text-slate-50 px-4 py-4 flex items-center justify-center "
                                    onClick={() =>
                                      arrayHelpers.insert(index, "")
                                    } // insert an empty string at a position
                                  >
                                    +
                                  </button>
                                </div>
                              ))
                            ) : (
                              <button
                                type="button"
                                className="text-white w-full text-center bg-green-500 px-2 py-2 rounded"
                                onClick={() => arrayHelpers.push("")}
                              >
                                {/* show this when user has removed all friends from the list */}
                                Add RAM
                              </button>
                            )}
                          </div>
                        )}
                      />

                      <ErrorMessage
                        component="div"
                        className="text-red-700 text-sm"
                        name="ram.value"
                      />
                    </div>
                    <div className="pt-7">
                      <select
                        className="h-8"
                        name="ram.unit"
                        value={formik.values.ram.unit}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        style={{ display: "block" }}
                      >
                        <option value="" label="Select Unit" />
                        <option value="MB" label="MB" />
                        <option value="GB" label="GB" />
                      </select>
                    </div>

                    <ErrorMessage
                      component="div"
                      className="text-red-700 text-sm"
                      name="ram.unit"
                    />
                  </div>
                  <div className="flex flex-row space-y-3 gap-3 items-center justify-between">
                    <div className="rounded w-full mt-10">
                      {/* <TextField
                        label="RAM"
                        name="ram.value"
                        value={formik.values.ram.value}
                        type="text"
                      /> */}
                      <label className="block text-gray-700 text-xs mb-2">
                        ROM
                      </label>
                      <FieldArray
                        name="rom.value"
                        render={(arrayHelpers) => (
                          <div className="w-full ">
                            {formik.values.rom.value &&
                              formik.values.rom.value.length > 0 ? (
                              formik.values.rom.value.map((friend, index) => (
                                <div
                                  key={index}
                                  className="flex items-center w-full justify-center"
                                >
                                  <Field
                                    className="h-8 w-52 bg-slate-100 px-4 border-1 py-4 flex items-center justify-center "
                                    name={`rom.value.${index}`}
                                  />
                                  <button
                                    className="h-8 w-8 bg-red-500 text-slate-50 px-4 py-4 flex items-center justify-center "
                                    type="button"
                                    onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                  >
                                    -
                                  </button>
                                  <button
                                    type="button"
                                    className="h-8 w-8 bg-green-500 text-slate-50 px-4 py-4 flex items-center justify-center "
                                    onClick={() =>
                                      arrayHelpers.insert(index, "")
                                    } // insert an empty string at a position
                                  >
                                    +
                                  </button>
                                </div>
                              ))
                            ) : (
                              <button
                                type="button"
                                className="text-white w-full text-center bg-green-500 px-2 py-2 rounded"
                                onClick={() => arrayHelpers.push("")}
                              >
                                {/* show this when user has removed all friends from the list */}
                                Add ROM
                              </button>
                            )}
                          </div>
                        )}
                      />

                      <ErrorMessage
                        component="div"
                        className="text-red-700 text-sm"
                        name="rom.value"
                      />
                    </div>
                    <div className="pt-7">
                      <select
                        className="h-8"
                        name="rom.unit"
                        value={formik.values.rom.unit}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        style={{ display: "block" }}
                      >
                        <option value="" label="Select Unit" />
                        <option value="MB" label="MB" />
                        <option value="GB" label="GB" />
                      </select>
                    </div>
                    <ErrorMessage
                      component="div"
                      className="text-red-700 text-sm"
                      name="rom.unit"
                    />
                  </div>
                  <TextField
                    label="Card (GB)"
                    name="card"
                    value={formik.values.card}
                    type="text"
                  />
                  {/* Camera*/}
                  <div className="bg-slate-500 text-center text-black py-2">
                    <h1>Camera</h1>
                  </div>
                  <div className="flex flex-row w-full space-y-3 items-center justify-between">
                    <div className="w-full">
                      <label className="block text-gray-700 text-xs mb-2">
                        Main Camera Value
                      </label>
                      {/* <TextField
                        label="Main Camera"
                        name="mainCam.value"
                        placeholder="main camera value"
                        value={formik.values.mainCam.value}
                        type="text"
                      /> */}
                      {/* <TextField
                        label="RAM"
                        name="ram.value"
                        value={formik.values.ram.value}
                        type="text"
                      /> */}
                      <FieldArray
                        name="mainCam.value"
                        render={(arrayHelpers) => (
                          <div className="w-full ">
                            {formik.values.mainCam.value &&
                              formik.values.mainCam.value.length > 0 ? (
                              formik.values.mainCam.value.map(
                                (friend, index) => (
                                  <div
                                    key={index}
                                    className="flex items-center w-full justify-center"
                                  >
                                    <Field
                                      className="h-8 w-52 bg-slate-100 px-4 border-1 py-4 flex items-center justify-center "
                                      name={`mainCam.value.${index}`}
                                    />
                                    <button
                                      className="h-8 w-8 bg-red-500 text-slate-50 px-4 py-4 flex items-center justify-center "
                                      type="button"
                                      onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                    >
                                      -
                                    </button>
                                    <button
                                      type="button"
                                      className="h-8 w-8 bg-green-500 text-slate-50 px-4 py-4 flex items-center justify-center "
                                      onClick={() =>
                                        arrayHelpers.insert(index, "")
                                      } // insert an empty string at a position
                                    >
                                      +
                                    </button>
                                  </div>
                                )
                              )
                            ) : (
                              <button
                                type="button"
                                onClick={() => arrayHelpers.push("")}
                                className="text-white w-full text-center bg-green-500 px-2 py-2 rounded"
                              >
                                {/* show this when user has removed all friends from the list */}
                                ADD MAIN CAMERA VALUES
                              </button>
                            )}
                          </div>
                        )}
                      />

                      <ErrorMessage
                        component="div"
                        className="text-red-700 text-sm"
                        name="mainCam.value"
                      />

                      <TextField
                        label="Main Camera Description"
                        name="mainCam.description"
                        placeholder="main camera description"
                        value={formik.values.mainCam.description}
                        type="text"
                      />
                      <select
                        className="h-8 w-full my-4"
                        name="mainCam.flash"
                        value={formik.values.mainCam.flash}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        style={{ display: "block" }}
                      >
                        <option value="" label="Flash Available" />
                        <option value="yes" label="yes" />
                        <option value="no" label="no" />
                      </select>
                      <ErrorMessage
                        component="div"
                        className="text-red-700 text-sm"
                        name="mainCam.flash"
                      />
                    </div>
                  </div>
                  <div className="flex flex-row space-y-3">
                    <div className="w-full">
                      {/* <TextField
                        label="Front Camera"
                        name="frontCam.value"
                        placeholder="front camera value"
                        value={formik.values.frontCam.value}
                        type="text"
                      /> */}
                      <label className="block text-gray-700 text-xs mb-2">
                        Front Camera Value
                      </label>
                      <FieldArray
                        name="frontCam.value"
                        render={(arrayHelpers) => (
                          <div className="w-full">
                            {formik.values.frontCam.value &&
                              formik.values.frontCam.value.length > 0 ? (
                              formik.values.frontCam.value.map(
                                (friend, index) => (
                                  <div
                                    key={index}
                                    className="flex items-center w-full justify-center"
                                  >
                                    <Field
                                      className="h-8 w-52 bg-slate-100 px-4 border-1 py-4 flex items-center justify-center "
                                      name={`frontCam.value.${index}`}
                                    />
                                    <button
                                      className="h-8 w-8 bg-red-500 text-slate-50 px-4 py-4 flex items-center justify-center "
                                      type="button"
                                      onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                    >
                                      -
                                    </button>
                                    <button
                                      type="button"
                                      className="h-8 w-8 bg-green-500 text-slate-50 px-4 py-4 flex items-center justify-center "
                                      onClick={() =>
                                        arrayHelpers.insert(index, "")
                                      } // insert an empty string at a position
                                    >
                                      +
                                    </button>
                                  </div>
                                )
                              )
                            ) : (
                              <button
                                type="button"
                                className="text-white w-full text-center bg-green-500 px-2 py-2 rounded"
                                onClick={() => arrayHelpers.push("")}
                              >
                                {/* show this when user has removed all friends from the list */}
                                ADD FRONT CAMERA VALUES
                              </button>
                            )}
                          </div>
                        )}
                      />

                      <ErrorMessage
                        component="div"
                        className="text-red-700 text-sm"
                        name="frontCam.value"
                      />

                      <TextField
                        label="Front Camera Description"
                        name="frontCam.description"
                        placeholder="Front camera description"
                        value={formik.values.frontCam.description}
                        type="text"
                      />
                      <select
                        className="h-8 w-full my-4"
                        name="frontCam.flash"
                        value={formik.values.frontCam.flash}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        style={{ display: "block" }}
                      >
                        <option value="" label="Flash Available" />
                        <option value="yes" label="yes" />
                        <option value="no" label="no" />
                      </select>
                      <ErrorMessage
                        component="div"
                        className="text-red-700 text-sm"
                        name="frontCam.flash"
                      />
                    </div>
                  </div>
                  <TextField
                    label="Features"
                    name="features"
                    placeholder="enter features"
                    value={formik.values.features}
                    type="text"
                  />
                  {/* Connectivity*/}
                  <div className="bg-red-400 text-center text-black py-2">
                    <h1>Connectivity</h1>
                  </div>
                  {/* WLAN*/}
                  <div className="flex flex-row items-center justify-between gap-3 space-y-3">
                    <div className="w-full">
                      <TextField
                        label="WLAN"
                        name="WLAN.description"
                        placeholder="Enter WLAN description"
                        value={formik.values.WLAN.description}
                        type="text"
                      />
                    </div>
                    <div className="pt-7">
                      <select
                        className="h-8"
                        name="WLAN.exists"
                        value={formik.values.WLAN.exists}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        style={{ display: "block" }}
                      >
                        <option value="" label="WLAN Available" />
                        <option value="yes" label="yes" />
                        <option value="no" label="no" />
                      </select>
                    </div>
                    <ErrorMessage
                      component="div"
                      className="text-red-700 text-sm"
                      name="WLAN.exists"
                    />
                  </div>
                  {/*Bluetooth */}

                  <div className="flex flex-row items-center justify-between gap-3 space-y-3">
                    <div className="w-full">
                      <TextField
                        label="Bluetooth"
                        name="bluetooth.description"
                        placeholder="Enter bluetooth description"
                        value={formik.values.bluetooth.description}
                        type="text"
                      />
                    </div>
                    <div className="pt-7">
                      <select
                        className="h-8"
                        name="bluetooth.exists"
                        value={formik.values.bluetooth.exists}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        style={{ display: "block" }}
                      >
                        <option value="" label="Bluetooth Available" />
                        <option value="yes" label="yes" />
                        <option value="no" label="no" />
                      </select>
                    </div>

                    <ErrorMessage
                      component="div"
                      className="text-red-700 text-sm"
                      name="bluetooth.exists"
                    />
                  </div>
                  {/* GPS*/}

                  <div className="flex flex-row items-center justify-between gap-3 space-y-3">
                    <div className="w-full">
                      <TextField
                        label="GPS"
                        name="GPS.description"
                        placeholder="Enter GPS description"
                        value={formik.values.GPS.description}
                        type="text"
                      />
                    </div>
                    <div className="pt-7">
                      <select
                        className="h-8"
                        name="GPS.exists"
                        value={formik.values.GPS.exists}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        style={{ display: "block" }}
                      >
                        <option value="" label="GPS Available" />
                        <option value="yes" label="yes" />
                        <option value="no" label="no" />
                      </select>
                    </div>

                    <ErrorMessage
                      component="div"
                      className="text-red-700 text-sm"
                      name="GPS.exists"
                    />
                  </div>
                  {/* RADIO*/}

                  <div className="flex flex-row items-center justify-between gap-3 space-y-3">
                    <div className="w-full">
                      <TextField
                        label="Radio"
                        name="radio.description"
                        placeholder="Enter radio description"
                        value={formik.values.radio.description}
                        type="text"
                      />
                    </div>
                    <div className="pt-7">
                      <select
                        className="h-8"
                        name="radio.exists"
                        value={formik.values.radio.exists}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        style={{ display: "block" }}
                      >
                        <option value="" label="Radio Available" />
                        <option value="yes" label="yes" />
                        <option value="no" label="no" />
                      </select>
                    </div>
                    <ErrorMessage
                      component="div"
                      className="text-red-700 text-sm"
                      name="radio.exists"
                    />
                  </div>
                  {/* USB*/}

                  <div className="flex flex-row items-center justify-between gap-3 space-y-3">
                    <div className="w-full">
                      <TextField
                        label="USB"
                        name="USB.description"
                        placeholder="Enter usb description"
                        value={formik.values.USB.description}
                        type="text"
                      />
                    </div>
                    <div className="pt-7">
                      <select
                        className="h-8"
                        name="USB.exists"
                        value={formik.values.USB.exists}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        style={{ display: "block" }}
                      >
                        <option value="" label="USB Available" />
                        <option value="yes" label="yes" />
                        <option value="no" label="no" />
                      </select>
                    </div>
                    <ErrorMessage
                      component="div"
                      className="text-red-700 text-sm"
                      name="USB.exists"
                    />
                  </div>

                  {/* NFC*/}
                  <div className="flex flex-row items-center justify-between gap-3 space-y-3">
                    <div className="w-full">
                      <TextField
                        label="NFC"
                        name="NFC.description"
                        placeholder="Enter NFC description"
                        value={formik.values.NFC.description}
                        type="text"
                      />
                    </div>
                    <div className="pt-7">
                      <select
                        className="h-8"
                        name="NFC.exists"
                        value={formik.values.NFC.exists}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        style={{ display: "block" }}
                      >
                        <option value="" label="NFC Available" />
                        <option value="yes" label="yes" />
                        <option value="no" label="no" />
                      </select>
                      <ErrorMessage
                        component="div"
                        className="text-red-700 text-sm"
                        name="NFC.exists"
                      />
                    </div>
                  </div>
                  <label className="block text-gray-700 text-sm mb-2">
                    Infrared
                  </label>
                  <select
                    className="h-8 w-full my-4"
                    name="infrared"
                    value={formik.values.infrared}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    style={{ display: "block" }}
                  >
                    <option value="" label="Infrared Available" />
                    <option value="yes" label="yes" />
                    <option value="no" label="no" />
                  </select>
                  <ErrorMessage
                    component="div"
                    className="text-red-700 text-sm"
                    name="infrared"
                  />
                  {/* DATA*/}
                  <div className="flex flex-row items-center justify-between gap-3 space-y-3">
                    <div className="w-full">
                      <TextField
                        label="Data"
                        name="data.description"
                        placeholder="Enter data description"
                        value={formik.values.data.description}
                        type="text"
                      />
                    </div>
                    <div className="pt-7">
                      <select
                        className="h-8"
                        name="data.exists"
                        value={formik.values.data.exists}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        style={{ display: "block" }}
                      >
                        <option value="" label="Data Available" />
                        <option value="yes" label="yes" />
                        <option value="no" label="no" />
                      </select>
                    </div>
                    <ErrorMessage
                      component="div"
                      className="text-red-700 text-sm"
                      name="data.exists"
                    />
                  </div>

                  {/* Features*/}
                  <div className="bg-orange-400 text-center text-black py-2">
                    <h1>Features</h1>
                  </div>
                  <TextField
                    label="Sensors"
                    name="sensor"
                    placeholder="Enter sensor"
                    value={formik.values.sensor}
                    type="text"
                  />
                  <TextField
                    label="Audio"
                    name="audio"
                    placeholder="Enter audio detail"
                    value={formik.values.audio}
                    type="text"
                  />
                  <TextField
                    label="Browser"
                    name="browser"
                    placeholder="Enter browser detail"
                    value={formik.values.browser}
                    type="text"
                  />
                  <TextField
                    label="Messaging"
                    name="messaging"
                    placeholder="Enter messaging detail"
                    value={formik.values.messaging}
                    type="text"
                  />
                  <TextField
                    label="Games"
                    name="games"
                    placeholder="Enter games detail"
                    value={formik.values.games}
                    type="text"
                  />
                  <TextField
                    label="Torch"
                    name="torch"
                    placeholder="Enter torch detail"
                    value={formik.values.torch}
                    type="text"
                  />
                  <TextField
                    label="Extra Features"
                    name="extraFeatures"
                    placeholder="Enter extra features detail"
                    value={formik.values.extraFeatures}
                    type="text"
                  />
                  {/* Battery*/}
                  <div className="bg-purple-400 text-center text-black py-2">
                    <h1>Battery</h1>
                  </div>
                  <TextField
                    label="Battery in mAh"
                    name="battery.capacity"
                    placeholder="Please enter value in number"
                    value={formik.values.battery.capacity}
                    type="text"
                  />
                  <TextField
                    label="Battery description"
                    name="battery.description"
                    placeholder="Please enter battery description"
                    value={formik.values.battery.description}
                    type="text"
                  />
                  {/* Price */}
                  <div className="bg-green-400 text-center text-black py-2">
                    <h1>Price</h1>
                  </div>
                  <TextField
                    label="Price in PKR"
                    name="priceInPKR"
                    placeholder="Please enter price in PKR"
                    value={formik.values.priceInPKR}
                    type="text"
                  />
                  <TextField
                    label="Price in usd"
                    name="priceInUSD"
                    placeholder="Please enter price in USD"
                    value={formik.values.priceInUSD}
                    type="text"
                  />

                  {/*Descriptions*/}
                  <div className="bg-pink text-center text-black py-2">
                    <h1>Descriptions</h1>
                  </div>
                  <div className="flex flex-col">
                    <label className="text-sm pb-3 font-light">
                      Enter Short Description
                    </label>
                    <JoditEditor
                      name="shortDescription"
                      ref={editor}
                      value={items}
                      config={config}
                      tabIndex={0} // tabIndex of textarea
                      onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                      onInit={(e, editor) => {
                        setContent(editor);
                      }}
                    />
                  </div>
                  <div className="flex flex-col">
                    <label className="text-sm pb-3 font-light">
                      Enter Long Description
                    </label>

                    <JoditEditor
                      name="longDescription"
                      ref={editor}
                      value={intro}
                      config={config}
                      tabIndex={0} // tabIndex of textarea
                      onBlur={(newContent) => setIntroduction(newContent)} // preferred to use only this option to update the content for performance reasons
                      onInit={(e, editor) => {
                        setIntroduction(editor);
                      }}
                    />
                  </div>

                  <div className="bg-green-400 text-center text-black py-2">
                    <h1>Review</h1>
                  </div>
                  <TextField
                    label="Review Url"
                    name="reviewUrl"
                    placeholder="Please enter review url"
                    value={formik.values.reviewUrl}
                    type="text"
                  />

                  <div className="flex pt-4 space-x-6 pb-4 w-full justify-evenly">
                    {edit ? (
                      <>
                        <button
                          className="btn text-sm font-light rounded-sm p-1 pl-6 pr-6 bg-green-500 btn-dark mt-3 text-white"
                          type="submit"
                        >
                          UPDATE
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          className="btn text-sm font-light rounded-sm p-1 pl-6 pr-6 bg-orange-400 btn-dark mt-3"
                          type="submit"
                        >
                          Create
                        </button>
                        <button
                          className="btn text-sm font-light rounded-sm p-2 pl-6 pr-6 bg-red-200 btn-dark mt-3"
                          type="button"
                          onClick={handleReset}
                        //  onClick={()=>{
                        //   localStorage.removeItem('formsValz');

                        //  }}
                        >
                          Reset
                        </button>
                        <button
                          className="btn text-sm font-light rounded-sm p-2 pl-6 pr-6 bg-zinc-200 btn-dark mt-3"
                          type="button"
                          //  onClick={localStorages(formik.values)}
                          onClick={() => {
                            localStorage.removeItem("formik");
                            localStorage.removeItem("joditsmall");
                            localStorage.removeItem("joditlarge");
                            localStorage.setItem(
                              "formik",
                              JSON.stringify(formik.values)
                            );
                            localStorage.setItem(
                              "joditsmall",
                              shortDescription
                            );
                            localStorage.setItem("joditlarge", longDescription);
                            alert("data saved");
                          }}
                        >
                          Save{" "}
                        </button>

                        <input
                          onChange={handleFileChange}
                          className="btn  min-w-fit text-white text-xs font-light rounded-sm p-2 pl-3 pr-3 bg-blue-500 btn-dark mt-3"
                          type="file"
                          id="myfile"
                          accept=".csv , application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          name="myfile"
                        ></input>
                        <button
                          className="btn text-sm  font-light rounded-sm p-2 pl-6 pr-6 bg-green-200 btn-dark mt-3"
                          type="submit"
                          onClick={handleParse}
                        >
                          Upload Single
                        </button>
                        <div></div>
                      </>
                    )}
                  </div>
                </div>
              </Form>
            </div>
          </div>
        )}
      </Formik>
    </>
  );
};

export default Create;
