import {
  CONTACT_GET_FAIL,
  CONTACT_GET_REQUEST,
  CONTACT_GET_SUCCESS,
  CONTACT_LIST_FAIL,
  CONTACT_LIST_REQUEST,
  CONTACT_LIST_SUCCESS,
} from "../Constants/Gadget";

export const contactReducer = (state = { contactItems: [] }, action) => {
  switch (action.type) {
    case CONTACT_LIST_REQUEST:
      return { loading: true, contactItems: [] };
    case CONTACT_LIST_SUCCESS:
      return { loading: false, contactItems: action.payload };
    case CONTACT_LIST_FAIL:
      return { loading: false, error: true, error: action.payload };
    default:
      return state;
  }
};

export const contacgettReducer = (state = { contactById: [] }, action) => {
  switch (action.type) {
    case CONTACT_GET_REQUEST:
      return { loading: true };
    case CONTACT_GET_SUCCESS:
      return { loading: false, contactById: action.payload };
    case CONTACT_GET_FAIL:
      return { loading: false, error: true, error: action.payload };
    default:
      return state;
  }
};
