import axios from "axios";
import { baseURL } from "../../api/baseURL";
import { BlogsAction } from "../actionsTypes";

export const getBlogs = () => {
  return async (dispatch) => {
    const res = await axios.get(`${baseURL}/api/v1/blogs/getAllBlogs`);
    console.log("Get news===>", res.data);

    if (res.data.success) {
      dispatch({
        type: BlogsAction.GET_BLOGS,
        payload: res.data.data,
      });
    } else {
      console.log("Sever error");
    }
  };
};

export const editBlogs = (id, values, images, navigate) => {
  return async (dispatch) => {
    const formData = new FormData();
    if (images) {
      for (var i = 0; i < images.length; i++) {
        formData.append("images", images[i]);
      }
    }

    try {
      const res = await axios.put(
        `${baseURL}/api/v1/blogs/updateBlogs`,
        images === undefined ? images : formData,
        {
          params: {
            values,
            id,
          },
        },
        {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (res.data.success) {
        alert(res.data.data);
        dispatch({
          type: BlogsAction.EDIT_BLOGS,
          payload: true,
        });
        navigate("/blogs");
      } else {
        console.log("Unable to fetch");
        navigate("/blogs");
      }
    } catch (error) {}
  };
};
