import { BlogsAction } from "./actionsTypes";
import axios from "axios";
import { baseURL } from "../api/baseURL";

export const addNewBlog = (values) => {
  return (dispatch) => {
    dispatch({
      type: BlogsAction.ADD_BLOGS,
      payload: values,
    });
    localStorage.removeItem("title");
    localStorage.removeItem("details");
    localStorage.removeItem("description");
  };
};

//delete reviews
export const deleteBlog = (value, admin) => {
  return async (dispatch) => {
    try {
      const res = await axios.delete(
        `${baseURL}/api/v1/blogs/deleteBlogs`,
        {
          data: {
            value,
          },
        },
        {
          headers: {
            authorization: `${admin}`,
          },
        }
      );

      if (res.data) {
        dispatch({
          type: BlogsAction.DELETE_BLOGS,
          payload: value,
        });
      } else {
        console.log("Unable to fetch");
      }
    } catch (error) {}
  };
};

// add review
export const addBlog = (values, images, navigate, admin) => {
  return async (dispatch) => {
    const formData = new FormData();
    if (images) {
      for (var i = 0; i < images.length; i++) {
        formData.append("images", images[i]);
      }
    }
    try {
      const res = await axios.post(
        `${baseURL}/api/v1/blogs/addBlogs`,

        images === undefined ? null : formData,
        {
          params: {
            values,
          },
        },
        {
          headers: {
            mode: "no-cors",
            Authorization: `${admin}`,
            accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res.data.success) {
        dispatch({
          type: BlogsAction.ADD_BLOGS,
          payload: values,
        });
        alert(res.data.data);
        navigate("/blogs");
        localStorage.removeItem("title");
        localStorage.removeItem("details");
        localStorage.removeItem("description");
      } else {
        alert(res.data.data);
        navigate("/blogs");
      }
    } catch (error) {}
  };
};
