import { UsedMobileAction } from "../actionsTypes";
import axios from "axios";
import { baseURL } from "../../api/baseURL";

export const getusedMobiles = () => {
  return async (dispatch) => {
    const res = await axios.get(`${baseURL}/api/v1/usedmobile/getusedmobiles`);
    console.log("Get used mobile===>", res.data);
    if (res.data.success) {
      dispatch({
        type: UsedMobileAction.GET_USED_MOBILES,
        payload: res.data.data,
      });
    } else {
      console.log("Sever error");
    }
  };
};

export const editusedMobile = (id, values, images, navigate) => {
  return async (dispatch) => {
    const formData = new FormData();
    if (images) {
      for (var i = 0; i < images.length; i++) {
        formData.append("images", images[i]);
      }
    }

    try {
      const res = await axios.put(
        `${baseURL}/api/v1/usedmobile/updateoldsinglemobile`,
        images === undefined ? images : formData,
        {
          params: {
            values,
            id,
          },
        },
        {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (res.data.success) {
        alert(res.data.data);
        dispatch({
          type: UsedMobileAction.EDIT_USED_MOBILE,
          payload: true,
        });
        navigate("/usedmobile");
      } else {
        console.log("Unable to fetch");
        navigate("/dashboard");
      }
    } catch (error) {}
  };
};
