import axios from "axios";
import { baseURL } from "../../api/baseURL";
import {
  CONTACT_GET_FAIL,
  CONTACT_GET_REQUEST,
  CONTACT_GET_SUCCESS,
  CONTACT_LIST_FAIL,
  CONTACT_LIST_REQUEST,
  CONTACT_LIST_SUCCESS,
} from "../Constants/Gadget";

export const GetContact = () => async (dispatch) => {
  try {
    dispatch({ type: CONTACT_LIST_REQUEST });
    const { data } = await axios.get(`${baseURL}/api/v1/contactus`);
    console.log(data.success, "actionss");
    dispatch({ type: CONTACT_LIST_SUCCESS, payload: data.success });
  } catch (error) {
    dispatch({ type: CONTACT_LIST_FAIL, payload: error.response });
  }
};

export const getContactById = (id) => async (dispatch) => {
  try {
    //   dispatch({type:CONTACT_GET_REQUEST})

    const { data } = await axios.get(`${baseURL}/api/v1/contactus/${id}`);
    dispatch({ type: CONTACT_GET_SUCCESS, payload: data.success });
  } catch (error) {
    dispatch({ type: CONTACT_GET_FAIL, payload: error });
  }
};
