import { ReviewAction } from "../actionsTypes";
import axios from "axios";
import { baseURL } from "../../api/baseURL";

export const getReviews = () => {
  return async (dispatch) => {
    const res = await axios.get(`${baseURL}/api/v1/review/getallreviews`);
    console.log("Get review===>", res.data);
    if (res.data.success) {
      dispatch({
        type: ReviewAction.GET_REVIEW,
        payload: res.data.data,
      });
    } else {
      console.log("Sever error");
    }
  };
};

export const editReview = (id, values, images, navigate) => {
  return async (dispatch) => {
    const formData = new FormData();
    if (images) {
      for (var i = 0; i < images.length; i++) {
        formData.append("images", images[i]);
      }
    }

    try {
      const res = await axios.put(
        `${baseURL}/api/v1/review/updatereview`,
        images === undefined ? images : formData,
        {
          params: {
            values,
            id,
          },
        },
        {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (res.data.success) {
        alert(res.data.data);
        dispatch({
          type: ReviewAction.EDIT_REVIEW,
          payload: true,
        });
        navigate("/reviews");
      } else {
        console.log("Unable to fetch");
        navigate("/reviews");
      }
    } catch (error) {}
  };
};
