import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button, Icon, SvgIcon } from "@mui/material";
// import { baseURL } from "../../api/baseURL";
import { useNavigate } from "react-router";
import Papa from "papaparse";
// import { ImageAsset } from "./ImageAsset";
import { useSelector, useDispatch } from "react-redux";
import { deleteMobile } from "../store/mobilesActions";
import { CreateCSVFILE } from '../store/NewAction/CSVAction';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";

import TextField from "@mui/material/TextField";
import { getMobiles } from "../store/actions/getMobileActions";
import ImageAsset from "./ImageAsset";
const columns = [
  {
    field: "_id",
    headerName: "ID",
    width: 70,
    hide: true,
  },
  { field: "brandName", headerName: "Brand Name", width: 160 },
  { field: "modelNumber", headerName: "Model No", width: 150 },

  {
    field: "memory",
    headerName: "RAM",
    valueFormatter: ({ value }) => `${value.ram.value.join()}${value.ram.unit}`,
    width: 130,
  },
  {
    field: "memory",
    valueFormatter: ({ value }) => `${value.rom.value.join()}${value.rom.unit}`,
    headerName: "STORAGE",
    width: 130,
  },
  {
    field: "display",
    headerName: "DISPLAY",
    width: 130,
    valueFormatter: ({ value }) => `${value.resolution}`,
  },
  {
    field: "priceInPKR",
    headerName: "Price (PKR)",
    description: "This column has a value getter and is not sortable.",
    width: 130,
  },

  {
    field: "action",
    headerName: "Action",
    sortable: false,
    renderCell: (params) => {
      const onClick = (e) => {
        e.stopPropagation(); // don't select this row after clicking
        const api = params.api;
        const thisRow = {};
        api
          .getAllColumns()
          .filter((c) => c.field !== "__check__" && !!c)
          .forEach(
            (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
          );

        return alert(JSON.stringify(thisRow._id, null, 4));
      };
      return (
        <Button variant="contained" color="primary" onClick={onClick}>
          VIEW
        </Button>
      );
    },
  },
];

const Content = () => {
  const [searchQuery, setSearchQuery] = useState("")
  const [filteredMobileList, setFilteredMobileList] = useState()
  const dispatch = useDispatch();
  const { mobileList } = useSelector((state) => state.mobiles);
  const navigate = useNavigate();
  const [selectedIds, setSelectedIds] = useState([]);
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState();
  const [isLoading, setLoading] = useState();

  const [error, setError] = useState("");
  const [spinner, setSpinner] = useState(true);
  // const [file, setFile] = useState();
  const allowedExtensions = ["csv"];
  // This state will store the parsed data
  const [value, setvalue] = useState(false);
  const Spinner = () => {
    // setLoading(true);
    // fetch("https://backend.mymobilesguru.com/api/v1/mobiles/getallmobiles").
    // then(response =>response.json())
    // .then(setLoading(false));
    return <h1>Mobile Uploading</h1>
  }

  const Clicked = (e) => {
    dispatch(CreateCSVFILE(file, navigate));
    if (file) {

      alert("File Uploaded");
      // navigate('/dashboard/mobileDetail/createNew');
    }

  }
  //redux
  // const handleFileChange = (e) => {
  // 	setError("");

  // 	// Check if user has entered the file
  // 	if (e.target.files.length) {
  // 		const inputFile = e.target.files[0];

  // 		// Check the file extensions, if it not
  // 		// included in the allowed extensions
  // 		// we show the error
  // 		const fileExtension = inputFile?.type.split("/")[1];
  // 		if (!allowedExtensions.includes(fileExtension)) {
  // 			setError("Please input a csv file");
  // 			return;
  // 		}

  // 		// If input type is correct set the state
  // 		setFile(inputFile);
  // 	}
  // };
  // const handleParse = () => {

  // 	// If user clicks the parse button without
  // 	// a file we show a error
  // 	if (!file) return setError("Enter a valid file");

  // 	// Initialize a reader which allows user
  // 	// to read any file or blob.
  // 	const reader = new FileReader();

  // 	// Event listener on reader when the file
  // 	// loads, we parse it and set the data.
  // 	reader.onload = async ({ target }) => {
  // 		const csv = Papa.parse(target.result, { header: true });
  // 		const parsedData = csv?.data;
  // 		// const columns = Object.keys(parsedData);
  // 		// setData(columns);
  //     // console.log(columns , "Data1");
  //     console.log(parsedData[0].brandName , "Data2")
  //     console.log(parsedData , "Data4")
  //     setvalue(parsedData);
  //     dispatch(CreateCSVFILE(file,navigate));
  // 	};
  // 	reader.readAsText(file , "Data2");
  // };
  const handleGetMobiles = () => dispatch(getMobiles());
  //column
  const columns = [
    {
      field: "_id",
      headerName: "ID",
      width: 70,
      hide: true,
    },
    { field: "brandName", headerName: "Brand Name", width: 200 },
    { field: "modelNumber", headerName: "Model No", width: 200 },

    {
      field: "memory",
      headerName: "RAM",
      valueFormatter: ({ value }) =>
        `${value.ram.value.join()}${value.ram.unit}`,
      width: 200,
    },
    {
      field: "memory",
      valueFormatter: ({ value }) =>
        `${value.rom.value.join()}${value.rom.unit}`,
      headerName: "STORAGE",
      width: 200,
    },
    {
      field: "display",
      headerName: "DISPLAY",
      width: 250,
      valueFormatter: ({ value }) => `${value.resolution}`,
    },
    {
      field: "priceInPKR",
      headerName: "Price (PKR)",
      description: "This column has a value getter and is not sortable.",
      width: 200,
    },

    {
      field: "action",
      headerName: "Action",
      sortable: false,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          const api = params.api;
          const thisRow = {};
          api
            .getAllColumns()
            .filter((c) => c.field !== "__check__" && !!c)
            .forEach(
              (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
            );
          return navigate("/dashboard/mobileDetail/" + params.id, {
            state: { editMobile: true, mobileId: params.id },
          });
        };
        return (
          <Button variant="contained" color="primary" onClick={onClick}>
            VIEW
          </Button>
        );
      },
    },
  ];

  const handleOnCellClick = (params) => {
    return navigate("/dashboard/mobileDetail/" + params.id, {
      state: { editMobile: true, mobileId: params.id },
    });
  };

  const createnew = () => {
    navigate("/dashboard/mobileDetail/createNew");
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const ConformDelete = async () => {
    const admin = await JSON.parse(localStorage.getItem("admin"));
    console.log(admin, "for deleting purposes");
    dispatch(deleteMobile(selectedIds, admin));
    setOpen(false);
  };
  React.useEffect(() => {
    handleGetMobiles();
  }, []);
  React.useEffect(() => {
    setFilteredMobileList(mobileList);
  }, [mobileList]);

  const filterData = () => {
   const tempFiltered =  mobileList.filter(item=>{
      let filteredProducts
      if (searchQuery === "") {
        return
      }
      else{
        filteredProducts =  item.brandName.toLowerCase().includes(searchQuery.toLowerCase()) || item.modelNumber.toLowerCase().includes(searchQuery.toLowerCase())
       
        return filteredProducts
      }
    })
    setFilteredMobileList(tempFiltered)
  }


  return (
    <>
      {isLoading ? (<button >Loading.....</button>
      ) : (
        <div className="w-full h-96">
          <div
            className={`flex mb-5 rounded  +  ${selectedIds.length > 0 ? "bg-pink" : ""
              }`}
          >
            <div className="w-1/2 align-middle pt-3 text-sm font-lighter text-[#f50057] pb-3 pl-2">
              {selectedIds.length > 0 && (
                <h3>Selected Items : {selectedIds.length}</h3>
              )}
            </div>
            <div className="w-1/2 pr-2 flex justify-end">
              <Button
                disabled={!selectedIds.length > 0}
                // onClick={handleDeleteRecords}
                onClick={handleClickOpen}
              >
                {selectedIds.length > 0 && (
                  <ImageAsset className=" w-[1.5rem] h-[1.5rem] " src="Delete" />
                )}
              </Button>

              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  <div className="flex flex-col">
                    <div className="flex justify-center">
                      <ImageAsset
                        className="justify-center text-center w-[1.5rem] h-[1.5rem]"
                        src="Delete"
                      />
                    </div>
                    <div className="flex justify-center">
                      <h1>Are you sure ?</h1>
                    </div>
                  </div>
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <p className="px-10">
                      Do you want to delete this data ? <br />
                      this data canot be restore.
                    </p>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button onClick={ConformDelete} autoFocus>
                    ok
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </div>
          {/* <div className="w-full flex pb-3 justify-end">
          <button
            className="btn text-white text-xs font-light rounded-sm p-2 pl-3 pr-3 bg-orange-400 btn-dark mt-3"
            type="submit"
            onClick={createnew}
          >
            Create new +
          </button>
        </div> */}

          <div className="w-full flex space-x-5  justify-center justify-items-center content-center">
            {/* {file && <span className='mt-5 ' src={URL.createObjectURL(file)}
           alt='Upload File' >{alert("File Uploaded")}</span>} */}


            <button
              className="btn justify-items-start  text-white h-10 min-w-fit text-xs font-light rounded-sm p-2 pl-3 pr-3 bg-orange-400 btn-dark "
              type="submit"
              onClick={() => {
                navigate("/featurePhones");
              }}
            >
              Create FeaturePhones +
            </button>
            <div className="flex w-3/5">
              <div className="w-1/2">

              <TextField
                id="search-bar"
                className="text"
                onInput={(e) => {
                  setSearchQuery(e.target.value);
                }}
                label="Search by Name or Model"
                variant="outlined"
                placeholder="Search..."
                size="small"
                />
                </div>
              <IconButton type="button" aria-label="search" title = "Search" onClick={filterData}>
                <SearchIcon style={{ fill: "blue"}} />
              </IconButton>
              <IconButton type="button" aria-label="search" title = "clear" onClick={()=>setFilteredMobileList(mobileList)}>
              <ClearIcon style={{ fill: "blue"}} />
              </IconButton>
            </div>


            <div className="w-full flex space-x-5  flex pb-3 m-w-fit m-h-fit  justify-center justify-items-center content-center ">


              <input onChange={(e) => { setFile(e.target.files[0]) }} className="flex justify-center  min-w-content text-white h-10  text-xs font-light rounded-sm p-2 pl-3 pr-3 bg-blue-500 btn-dark " type="file" id="myfile" accept=".csv , application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" name="myfile"></input>
              <button
                className=" btn justify-items-end min-w-fit h-10 text-white hover:bg-blue-600 text-xs font-light rounded-sm p-2 pl-3 pr-3 bg-orange-400 btn-dark "
                type="submit"
                onClick={Clicked}

              >
                UPLOAD ALL
              </button>
            </div>
            <button
              className=" flex justify-item-end text-white text-xs min-w-fit h-10 font-light rounded-sm p-2 pl-3 pr-3 bg-orange-400 btn-dark "
              type="submit"
              onClick={createnew}
            >
              Create new +
            </button>
          </div>

          <DataGrid
            onSelectionModelChange={(newSelectionModel) => {
              setSelectedIds(newSelectionModel);
            }}
            rows={filteredMobileList}
            columns={columns}
            pageSize={50}
            rowsPerPageOptions={[50]}
            checkboxSelection
            disableSelectionOnClick
            getRowId={(r) => r._id}
            autoHeight={true}
          onRowDoubleClick={handleOnCellClick}
          />
        </div>
      )};
    </>
  )

}

export default Content;
