import {
  SLIDE_CREATE_FAIL,
  SLIDE_CREATE_REQUEST,
  SLIDE_CREATE_SUCCESS,
  SLIDE_GET_FAIL,
  SLIDE_GET_REQUEST,
  SLIDE_GET_SUCCESS,
  SLIDE_LIST_FAIL,
  SLIDE_LIST_REQUEST,
  SLIDE_LIST_SUCCESS,
  SLIDE_UPDATE_FAIL,
  SLIDE_UPDATE_REQUEST,
  SLIDE_UPDATE_SUCCESS,
} from "../Constants/Gadget";

export const slideReducer = (state = { slideItems: [] }, action) => {
  switch (action.type) {
    case SLIDE_LIST_REQUEST:
      return { loading: true, slideItems: [] };
    case SLIDE_LIST_SUCCESS:
      return { loading: false, slideItems: action.payload };
    case SLIDE_LIST_FAIL:
      return { loading: false, error: true, error: action.payload };
    default:
      return state;
  }
};

export const slideCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case SLIDE_CREATE_REQUEST:
      return { loading: true };
    case SLIDE_CREATE_SUCCESS:
      return { loading: false, slide: action.payload };
    case SLIDE_CREATE_FAIL:
      return { loading: false, error: true, error: action.payload };
    default:
      return state;
  }
};

export const slideUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case SLIDE_UPDATE_REQUEST:
      return { loading: true };
    case SLIDE_UPDATE_SUCCESS:
      return { loading: false, slide: action.payload };
    case SLIDE_UPDATE_FAIL:
      return { loading: false, error: true, error: action.payload };
    default:
      return state;
  }
};

export const slideGetReducer = (state = { slideById: [] }, action) => {
  switch (action.type) {
    case SLIDE_GET_REQUEST:
      return { loading: true };
    case SLIDE_GET_SUCCESS:
      return { loading: false, slideById: action.payload };
    case SLIDE_GET_FAIL:
      return { loading: false, error: true, error: action.payload };
    default:
      return state;
  }
};
