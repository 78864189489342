import { Button, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React from "react";
import { useState } from "react";
import axios from "axios";
import { baseURL } from "../api/baseURL";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSlideById, UpdateSlide } from "../store/NewAction/SlideActions";

const UpdateSlideScreen = () => {
  const dispatch = useDispatch();
  const slideSingle = useSelector((store) => store.slideSingle);
  const { loading, error, slideById } = slideSingle;
  // const PF = `${baseURL}/sliderImages/`;
  const PF = `${baseURL}`;

  const [brandName, setName] = useState();
  const [model, setModel] = useState();
  const [Id, setId] = useState();
  const [alt,setAlt]=useState();
  const [pathname, setPathname] = useState();
  const param = useParams();
  const id = param.id;
  useEffect(() => {
    dispatch(getSlideById(id));
  }, [id, dispatch]);
  const navigate = useNavigate();
  const [file, setFile] = useState(null);

  useEffect(() => {
    // const fun = () => {
    setName(slideById.brandName);
    setModel(slideById.model);
    setPathname(slideById.pathname);

    setId(slideById.Id);
    // }
    // fun();
  }, [slideById]);
  const submitHandler = async (e) => {
    e.preventDefault();
    const values = { brandName, model, Id,alt, pathname };
    dispatch(UpdateSlide(file, id, values, navigate));
  };

  return (
    <div className="w-full h-screen ">
      {loading ? (
        <h1 className="text-3xl animate-bounce text-black font-semibold font-serif text-center bg-zinc-200 p-6 ">
          Loading...
        </h1>
      ) : error ? (
        <h1> {error} </h1>
      ) : (
        <div className="w-full flex flex-col justify-center items-center ">
          <h1 className="font-medium text-xl ">Update Slide</h1>
          <form className="flex flex-col w-[50%]">
            <div className="flex space-x-4 mt-8 mb-5 ">
              <img
                className="h-[15rem] rounded-xl"
                src={file ? URL.createObjectURL(file) : PF + slideById.photo}
                alt="img"
              />
              <div className="flex justify-center items-center">
                <label
                  htmlFor="file"
                  className="py-2 px-6 border-2 rounded-2xl bg-sky-500 text-center text-xl text-white"
                >
                  Choose File
                </label>
                <div className="">  <input
                  type="file"
                  name="file"
                  id="file"
                  style={{ display: "none" }}
                  onChange={(e) => {
                    setFile(e.target.files[0]);
                  }}
                />
                    <TextField 
                    label="Img Alt"
                    name="alt"
                    placeholder="Enter Image alt"
                    onChange={(e)=>{setAlt(e.target.value) }}
                    value={alt}
                    type="text"
                    className="my-5 outline-none border w-full py-2 rounded px-2" 

                  />
                    </div>
                
              </div>
            </div>
            <div className="flex flex-col w-full space-y-6 ">
              <TextField
                className="w-full"
                label=""
                name="brandName"
                placeholder=""
                type="text"
                onChange={(e) => setName(e.target.value)}
                value={brandName}
              />

              <TextField
                className="w-full"
                label=""
                name="model"
                placeholder=""
                type="text"
                onChange={(e) => setModel(e.target.value)}
                value={model}
              />
              <TextField
                className="w-full"
                label="Id"
                type="text"
                name="Id"
                value={Id}
                onChange={(e) => setId(e.target.value)}
              />
              <TextField
                className="w-full"
                label="Path"
                type="text"
                name="path"
                value={pathname}
                onChange={(e) => setPathname(e.target.value)}
              />

              <Button
                type="submit"
                onClick={submitHandler}
                sx={{ paddingTop: "12px" }}
                variant="outlined"
              >
                Update
              </Button>
              <div></div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default UpdateSlideScreen;
