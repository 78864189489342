import { AdminAction } from "./actionsTypes";
import axios from "axios";
import { toast } from "react-toastify";
import { baseURL } from "../api/baseURL";

export const adminLogin = (values, navigate) => {
    return async (dispatch) => {

        try {
            const res = await axios.post(
                `${baseURL}/api/v1/admin/login`,

                {
                    email: values.email,
                    password: values.password
                });
            if (res.data.success) {
                localStorage.setItem('admin', JSON.stringify(res.data.token))
                navigate('/dashboard')
                dispatch({
                    type: AdminAction.ADMIN_LOGIN,
                    payload: res.data.token,
                });

            } else {
                alert('Invalid Credentials')
            }
        } catch (error) {
            toast('invalid credentials')
        }
    };
};

