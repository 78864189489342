import { UsedMobileAction } from "./actionsTypes";

const initialState = {
  usedMobileList: [],
  addusedSingleMobile: {},
  edit: false,
};

const usedMobileReducer = (state = initialState, action) => {
  switch (action.type) {

    case UsedMobileAction.GET_USED_MOBILES: {
      return {
        ...state,
        usedMobileList: action.payload,
      };
    }
    case UsedMobileAction.ADD_USED_MOBILE: {
      return {
        ...state,
        addusedSingleMobile: action.payload,
      };
    }
    case UsedMobileAction.DELETE_USED_MOBILE: {
      return {
        ...state,
        usedMobileList: state.usedMobileList.filter(
          (mobile) => !action.payload.includes(mobile._id)
        ),
      };
    }
    case UsedMobileAction.EDIT_USED_MOBILE: {
      return {
        ...state,
        edit: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
export default usedMobileReducer;
