import { createStore, combineReducers, applyMiddleware } from "redux";
import mobileReducer from "./mobileReducer";
import usedMobileReducer from "./usedMobileReducer";

import thunk from "redux-thunk";

import adminReducer from "./adminReducer";
import reviewReducer from "./reviewReducer";
import newsReducer from "./newsReducer";
import blogsReducer from "./blogsReducer";
import {
  GadgetCreateReducer,
  GadgetGetByIdReducer,
  GadgetReducer,
  GadgetUpdateReducer,
} from "./Reducer/GadgetReducer";
import {
  slideCreateReducer,
  slideGetReducer,
  slideReducer,
  slideUpdateReducer,
} from "./Reducer/SliderReducer";
import { contactReducer, contacgettReducer } from "./Reducer/ContactUsReducer";

const rootReducer = combineReducers({
  mobiles: mobileReducer,
  usedmobile: usedMobileReducer,

  reviews: reviewReducer,
  news: newsReducer,
  blogs: blogsReducer,
  admin: adminReducer,

  gadgetList: GadgetReducer,
  slide: slideReducer,
  createSlide: slideCreateReducer,
  updateSlide: slideUpdateReducer,
  slideSingle: slideGetReducer,
  createGadget: GadgetCreateReducer,
  updateGadget: GadgetUpdateReducer,
  GadgetSingle: GadgetGetByIdReducer,
  contactList: contacgettReducer,
  contact: contactReducer,
});

const store = createStore(rootReducer, applyMiddleware(thunk));
export default store;
