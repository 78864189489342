import { Button, TextField } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import axios from 'axios';
import { baseURL } from '../api/baseURL';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSlideById, UpdateSlide } from '../store/NewAction/SlideActions';

const QuestionUpdate = () => {
     const dispatch=useDispatch();
        const updateSlide=useSelector(store=>store.updateSlide);
        const slideSingle=useSelector(store=>store.slideSingle);
        const {loading,error,slideByIds}=slideSingle;
        const [slideById,setSlideByid]=useState([]);
        console.log(slideById,'slidesss')
    const PF = `${baseURL}/sliderImages/`
    const [gadget, setGadget] = useState([]);
    const [refresh, setRefresh] = useState(false);
 
    const [question, setQuestion] = useState();
    const [answer, setAnswer] = useState();
    const param = useParams();
    const id = param.id;
    console.log(id, 'id')
    useEffect(() => {
        // dispatch(getSlideById(id))
        const fatchApi=async()=>{
            const {data}=await axios.get(`${baseURL}/api/v1/question/`+id);
            setSlideByid(data)
            console.log(data)

        }
        fatchApi()
        
    }, [id])
    const navigate = useNavigate();

    useEffect(() => {
        const fun = () => {
            setQuestion(slideById.question)
            
            setAnswer(slideById.answer)
        }
        fun();
    }, [slideById])
 console.log(gadget,'gadget')
    const submitHandler = async (e) => {
        e.preventDefault();
        const values = { question,  answer }
        // dispatch(UpdateSlide(id,values,navigate))
        const {data}=await axios.put(`${baseURL}/api/v1/question/${id}`,values);
          if(data){
             alert('Questions Answers Updated')
             navigate('/question')
          }

    }

    return (


        <div className='w-full h-screen '>
            {loading ? 
                      <h1 className="text-3xl animate-bounce text-black font-semibold font-serif text-center bg-zinc-200 p-6 " >Loading...</h1> 
            : error ?   <h1> {error} </h1>:
                          
        
            <div className='w-full flex flex-col justify-center items-center '>
                <h1 className='font-medium text-xl '>Update Question Answer</h1>
                <form className='flex flex-col w-[50%]'>

                    
                     
                    <div className='flex flex-col w-full space-y-6 '>
                        <TextField
                            className='w-full'
                            label=""
                            name="qestion"
                            placeholder=""
                            type="text"
                            onChange={(e) => setQuestion(e.target.value)}
                            value={question}
                        />

                        
                        <TextField
                            className='w-full'
                            label=""
                            name="answer"
                            placeholder=""
                            type="text"
                            onChange={(e) => setAnswer(e.target.value)}
                            value={answer}

                        />

                       


                        <Button type='submit' onClick={submitHandler} sx={{ paddingTop: '12px' }} variant='outlined'>Update</Button>
                        <div ></div>
                    </div>
                </form>
            </div>
}
        </div>

    )
}

export default QuestionUpdate