import React from "react";

import BlogsContent from "../components/BlogsContent";

const BlogsScreen = () => {
  return (
    <div className="w-full">
      <BlogsContent />
    </div>
  );
};

export default BlogsScreen;
