import axios from "axios";
import { baseURL } from "../../api/baseURL";
import {CSVFILE_LIST_REQUEST,CSVFILE_LIST_SUCCESS,CSVFILE_LIST_FAIL,CSVFILE_CREATE_REQUEST,CSVFILE_CREATE_SUCCESS,CSVFILE_CREATE_FAIL } from "../Constants/Gadget";
import Papa from "papaparse";


//  export const GetCSVFILE=()=>async(dispatch)=>{
//     try{
//         dispatch({type:CSVFILE_LIST_REQUEST})
//         const {data}=await axios.get(`${baseURL}/api/v1/CSVMobile`);
//         console.log(data.success,'CSVFILE')
//         dispatch({type:CSVFILE_LIST_SUCCESS, payload:data.success})
        
//     }catch(error){
//         dispatch({type:CSVFILE_LIST_FAIL, payload:error.response})

//     }
//   }

 export const CreateCSVFILE=(file,navigate)=>async(dispatch)=>{
    try{
        dispatch({type:CSVFILE_CREATE_REQUEST})
        if (file) {

            console.log(file)
            const datas = new FormData();
            datas.append('file', file);
            // console.log(file);

            // console.log(values)
            // const send={datas,values};
            try {
               
                const { data } = await axios.post(`${baseURL}/api/v1/CSVMobile/addMobile`,

                    file === undefined ? null : datas,
                   
                    {
                        headers: {
                            method:'POST' ,
                            body:'formData',
                            // 'Authorization': `${admin}`,
                            accept: ".csv , application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,application/json",
                            "Content-Type": "multipart/form-data",

                        },
                    }

                )
                dispatch({type:CSVFILE_CREATE_SUCCESS, payload:data.success})

                if (data) {

                    alert('FILE ADDED SUCCESSFULLY')
                    navigate('/dashboard')
                }

            } catch (err) {
                console.log(err)
            }
        } else {
            alert('file is required')
        }
        
    }catch(error){
        dispatch({type:CSVFILE_CREATE_FAIL, payload:error.response})

    }
  }

