import { Button, TextField } from "@mui/material";
import React from "react";
import { useState, useRef } from "react";
import { Formik, formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { baseURL } from "../api/baseURL";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { CreateSlides } from "../store/NewAction/SlideActions";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const CreateSlide = () => {
  const createSlide = useSelector((store) => store.createSlide);
  const { loading, slide, error } = createSlide;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const editor = useRef(null);
  const initialValues = {
    brandName: "",
    model: "",
    Id: "",
    alt: "",
  };
  const validate = Yup.object({
    brandName: Yup.string().required("This Field is Required"),
    model: Yup.string().required("This Field is Required"),
    alt: Yup.string(),
    Id: Yup.string().required("This Field is Required"),
  });

  useEffect(() => {
    document.title = "changing";
  }, [loading]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validate}
      onSubmit={(values) => {
        // console.log(values);

        dispatch(CreateSlides(file, values, navigate));
      }}
    >
      {(formik) => (
        <div className="w-full h-screen ">
          {loading ? (
            <h1 className="text-3xl animate-bounce text-black font-semibold font-serif text-center bg-zinc-200 p-6 ">
              Loading...
            </h1>
          ) : error ? (
            <h1> {error} </h1>
          ) : (
            <div className="w-full flex flex-col justify-center items-center ">
              <h1 className="font-medium text-xl ">Create Slide</h1>
              <Form className="flex flex-col w-[50%]">
                <div className="flex space-x-4 mt-8 mb-5 ">
                  {file && (
                    <img
                      className="h-[15rem] rounded-xl"
                      src={URL.createObjectURL(file)}
                      alt="img"
                    />
                  )}
                  <div className="flex justify-center items-center">
                    <label
                      htmlFor="file"
                      className="py-2 px-6 border-2 rounded-2xl bg-sky-500 text-center text-xl text-white"
                    >
                      Choose File
                    </label>

                    <div className="flex">  <input
                      type="file"
                      name="file"
                      id="file"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        setFile(e.target.files[0]);
                      }}
                    />
                    <TextField 
                    label="Img Alt"
                    name="alt"
                    placeholder="Enter Image alt"
                    // onChange={(e)=>{setBrandName(e.target.value) }}
                    // value={formik.values.brandName}
                    onChange={formik.handleChange}
                    type="text"
                    className="my-5 outline-none border w-full py-2 rounded px-2" 

                  />
                    </div>

                    
                  </div>
                </div>
                <div className="flex flex-col w-full space-y-6 ">
                  <TextField
                    className="w-full"
                    label="Brand Name"
                    name="brandName"
                    placeholder=""
                    type="text"
                    onChange={formik.handleChange}
                  />
                  <ErrorMessage
                    component="div"
                    className="text-red-700 text-sm"
                    name="brandName"
                  />
                  <TextField
                    className="w-full"
                    label="Model"
                    name="model"
                    type="text"
                    onChange={formik.handleChange}
                  />
                  <ErrorMessage
                    component="div"
                    className="text-red-700 text-sm"
                    name="model"
                  />

                  <TextField
                    className="w-full"
                    label="Id"
                    type="text"
                    name="Id"
                    onChange={formik.handleChange}
                  />

                  <ErrorMessage
                    component="div"
                    className="text-red-700 text-sm"
                    name="Id"
                  />
                  <TextField
                    className="w-full"
                    label="pathname"
                    type="text"
                    name="pathname"
                    onChange={formik.handleChange}
                  />

                  <ErrorMessage
                    component="div"
                    className="text-red-700 text-sm"
                    name="pathname"
                  />

                  <Button
                    type="submit"
                    sx={{ paddingTop: "12px" }}
                    variant="outlined"
                  >
                    Submit
                  </Button>
                  <div></div>
                </div>
              </Form>
            </div>
          )}
        </div>
      )}
    </Formik>
  );
};

export default CreateSlide;
