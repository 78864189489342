export const MobileAction = {
  ADD_MOBILE: "ADD_MOBILE",
  DELETE_MOBILE: "DELETE_MOBILE",
  GET_MOBILES: "GET_MOBILES",
  EDIT_MOBILE: "EDIT_MONILE",
};
export const UsedMobileAction = {
  ADD_USED_MOBILE: "ADD_USED_MOBILE",
  DELETE_USED_MOBILE: "DELETE_USED_MOBILE",
  GET_USED_MOBILES: "GET_USED_MOBILES",
  EDIT_USED_MOBILE: "EDIT_USED_MONILE",
};

export const AdminAction = {
  ADMIN_LOGIN: "ADMIN_LOGIN",
  ADMIN_LOGOUT: "ADMIN_LOGOUT",
};

export const ReviewAction = {
  ADD_REVIEW: "ADD_REVIEW",
  DELETE_REVIEW: "DELETE_REVIEW",
  GET_REVIEW: "GET_REVIEW",
  EDIT_REVIEW: "EDIT_REVIEW",
};

export const NewsAction = {
  ADD_NEWS: "ADD_NEWS",
  DELETE_NEWS: "DELETE_NEWS",
  GET_NEWS: "GET_NEWS",
  EDIT_NEWS: "EDIT_NEWS",
};
export const BlogsAction = {
  ADD_BLOGS: "ADD_BLOG",
  DELETE_BLOGS: "DELETE_BLOG",
  GET_BLOGS: "GET_BLOG",
  EDIT_BLOGS: "EDIT_BLOG",
};
