import axios from "axios";
import { baseURL } from "../../api/baseURL";
import {
  SLIDE_CREATE_FAIL,
  SLIDE_CREATE_REQUEST,
  SLIDE_CREATE_SUCCESS,
  SLIDE_GET_FAIL,
  SLIDE_GET_REQUEST,
  SLIDE_GET_SUCCESS,
  SLIDE_LIST_FAIL,
  SLIDE_LIST_REQUEST,
  SLIDE_LIST_SUCCESS,
  SLIDE_UPDATE_FAIL,
  SLIDE_UPDATE_REQUEST,
  SLIDE_UPDATE_SUCCESS,
} from "../Constants/Gadget";

export const GetSlide = () => async (dispatch) => {
  try {
    dispatch({ type: SLIDE_LIST_REQUEST });
    const { data } = await axios.get(`${baseURL}/api/v1/slide`);
    dispatch({ type: SLIDE_LIST_SUCCESS, payload: data.success });
  } catch (error) {
    dispatch({ type: SLIDE_LIST_FAIL, payload: error.response });
  }
};

export const CreateSlides = (file, values, navigate) => async (dispatch) => {
  try {
    dispatch({ type: SLIDE_CREATE_REQUEST });
    if (file) {
      const datas = new FormData();
      datas.append("file", file);

      try {
        const { data } = await axios.post(
          `${baseURL}/api/v1/slide`,

          file === undefined ? null : datas,
          {
            params: {
              values,
            },
          },
          {
            headers: {
              mode: "no-cors",
              // 'Authorization': `${admin}`,
              accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        );
        dispatch({ type: SLIDE_CREATE_SUCCESS, payload: data.success });

        if (data) {
          alert("Slide Created");
          navigate("/slide");
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      alert("file is required");
      dispatch({ type: SLIDE_CREATE_FAIL });
    }
  } catch (error) {
    dispatch({ type: SLIDE_CREATE_FAIL, payload: error.response });
  }
};

export const UpdateSlide = (file, id, values, navigate) => async (dispatch) => {
  dispatch({ type: SLIDE_UPDATE_REQUEST });
  const datas = new FormData();

  if (file) {
    datas.append("file", file);
  }
  try {
    const { data } = await axios.put(
      `${baseURL}/api/v1/slide/${id}`,
      file && datas,
      {
        params: {
          values,
        },
      },
      {
        headers: {
          mode: "no-cors",
          // 'Authorization': `${admin}`,
          accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }
    );
    dispatch({ type: SLIDE_UPDATE_SUCCESS, payload: data.success });
    console.log(data);
    if (data) {
      alert("Updated Successfully");
      navigate("/slide");
    }
  } catch (err) {
    dispatch({ type: SLIDE_UPDATE_FAIL, payload: err });

    console.log(err);
  }
};

export const getSlideById = (id) => async (dispatch) => {
  try {
    //   dispatch({type:SLIDE_GET_REQUEST})

    const { data } = await axios.get(`${baseURL}/api/v1/slide/${id}`);
    dispatch({ type: SLIDE_GET_SUCCESS, payload: data.success });
  } catch (error) {
    dispatch({ type: SLIDE_GET_FAIL, payload: error });
  }
};
