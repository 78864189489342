import { MobileAction } from "./actionsTypes";
import axios from "axios";
import { baseURL } from "../api/baseURL";

export const addNewMobile = (values) => {
  return (dispatch) => {
    dispatch({
      type: MobileAction.ADD_MOBILE,
      payload: values,
    });
  };
};

//delete mobiles
export const deleteMobile = (value, admin) => {
  console.log(admin, "deleting now now now")
  return async (dispatch) => {
    try {
      const res = await axios.delete(`${baseURL}/api/v1/mobiles/deleteMobile`, {
        data: {
          value,
        },
      }, {
        headers: {
          'authorization': `${admin}`
        },
      });

      if (res.data) {
        dispatch({
          type: MobileAction.DELETE_MOBILE,
          payload: value,
        });
      } else {
        console.log("Unable to fetch");
      }
    } catch (error) { }
  };
};

// add mobile
export const addMobile = (values, images, navigate, admin) => {
  console.log("values==>", values);
  console.log('admin during phone add req', admin)
  return async (dispatch) => {
    const formData = new FormData();
    if (images) {
      for (var i = 0; i < images.length; i++) {
        formData.append("images", images[i]);
      }
    }
    try {
      const res = await axios.post(

        `${baseURL}/api/v1/mobiles/addMobile`,

        images === undefined ? null : formData,
        {
          params: {
            values,
          },
        },
        {
          headers: {
            'Authorization': `${admin}`,
            accept: "application/json",
            "Content-Type": "multipart/form-data",

          },
        }
      );
      console.log("res===>", res);
      if (res.data.success) {
        dispatch({
          type: MobileAction.ADD_MOBILE,
          payload: values,
        });
        alert(res.data.data);
        // localStorage.removeItem('formik');
        // localStorage.removeItem('joditsmall');
        // localStorage.removeItem('joditlarge');

      } else {
        alert(res.data.data);
        navigate("/dashboard");
      }
    } catch (error) {
       console.log(error , "Mobile Added error")
     }
  };
};
