import { BlogsAction } from "./actionsTypes";

const initialState = {
  blogsList: [],
  addSingleBlog: {},
  edit: false,
};

const blogsReducer = (state = initialState, action) => {
  switch (action.type) {
    case BlogsAction.GET_BLOGS: {
      return {
        ...state,
        blogsList: action.payload,
      };
    }
    case BlogsAction.ADD_BLOGS: {
      return {
        ...state,
        addSingleBlog: action.payload,
      };
    }
    case BlogsAction.DELETE_BLOGS: {
      return {
        ...state,
        blogsList: state.blogsList.filter(
          (blogs) => !action.payload.includes(blogs._id)
        ),
      };
    }
    case BlogsAction.EDIT_BLOGS: {
      return {
        ...state,
        edit: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
export default blogsReducer;
