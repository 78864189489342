import { ReviewAction } from "./actionsTypes";

const initialState = {
  reviewList: [],
  addSingleMobile: {},
  edit: false,
};

const mobileReducer = (state = initialState, action) => {
  switch (action.type) {

    case ReviewAction.GET_REVIEW: {
      return {
        ...state,
        reviewList: action.payload,
      };
    }
    case ReviewAction.ADD_REVIEW: {
      return {
        ...state,
        addSingleMobile: action.payload,
      };
    }
    case ReviewAction.DELETE_REVIEW: {
      return {
        ...state,
        reviewList: state.reviewList.filter(
          (review) => !action.payload.includes(review._id)
        ),
      };
    }
    case ReviewAction.EDIT_REVIEW: {
      return {
        ...state,
        edit: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
export default mobileReducer;
