import { NewsAction } from "./actionsTypes";

const initialState = {
  newsList: [],
  addSingleNews: {},
  edit: false,
};

const newsReducer = (state = initialState, action) => {
  switch (action.type) {

    case NewsAction.GET_NEWS: {
      return {
        ...state,
        newsList: action.payload,
      };
    }
    case NewsAction.ADD_NEWS: {
      return {
        ...state,
        addSingleNews: action.payload,
      };
    }
    case NewsAction.DELETE_NEWS: {
      return {
        ...state,
        newsList: state.newsList.filter(
          (news) => !action.payload.includes(news._id)
        ),
      };
    }
    case NewsAction.EDIT_NEWS: {
      return {
        ...state,
        edit: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
export default newsReducer;
