import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import { useNavigate } from "react-router";
import { ImageAsset } from ".";
import { useSelector, useDispatch } from "react-redux";
import { deleteReview } from "../store/reviewsActions";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { getReviews } from "../store/actions/getReviewActions";
// const columns = [
//   {
//     field: "_id",
//     headerName: "ID",
//     width: 70,
//     hide: true,
//   },
//   { field: "brandName", headerName: "Brand Name", width: 160 },
//   { field: "modelNumber", headerName: "Model No", width: 150 },

//   {
//     field: "memory",
//     headerName: "RAM",
//     valueFormatter: ({ value }) => `${value.ram.value}${value.ram.unit}`,
//     width: 130,
//   },
//   {
//     field: "memory",
//     valueFormatter: ({ value }) => `${value.rom.value}${value.rom.unit}`,
//     headerName: "STORAGE",
//     width: 130,
//   },
//   {
//     field: "display",
//     headerName: "DISPLAY",
//     width: 130,
//     valueFormatter: ({ value }) => `${value.resolution}`,
//   },
//   {
//     field: "priceInPKR",
//     headerName: "Price (PKR)",
//     description: "This column has a value getter and is not sortable.",
//     width: 130,
//   },

//   {
//     field: "action",
//     headerName: "Action",
//     sortable: false,
//     renderCell: (params) => {
//       const onClick = (e) => {
//         e.stopPropagation(); // don't select this row after clicking
//         const api = params.api;
//         const thisRow = {};
//         api
//           .getAllColumns()
//           .filter((c) => c.field !== "__check__" && !!c)
//           .forEach(
//             (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
//           );

//         return alert(JSON.stringify(thisRow._id, null, 4));
//       };
//       return (
//         <Button variant="contained" color="primary" onClick={onClick}>
//           VIEW
//         </Button>
//       );
//     },
//   },
// ];

const ReviewContent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { reviewList } = useSelector((state) => state.reviews);
  const [selectedIds, setSelectedIds] = useState([]);
  const [open, setOpen] = useState(false);
  //redux
  const handleGetReviews = () => dispatch(getReviews());
  //column
  const columns = [
    {
      field: "_id",
      headerName: "ID",
      width: 70,
      hide: true,
    },
    { field: "brandName", headerName: "Brand Name", width: 200 },
    {
      field: "rating",
    //   valueFormatter: ({ value }) => `${value.rating}`,
      headerName: "Rating",
      width: 200,
    },
    { field: "introduction", headerName: "Introduction", width: 200 },
    {
      field: "mobileName",
      headerName: "Mobile Name",
      width: 250,
    },
    {
      field: "youtubeUrl",
      headerName: "Youtube Url",
      width: 200,
    },
    {
        field: "details",
        headerName: "Details",
        width: 200,
      },

    {
      field: "action",
      headerName: "Action",
      sortable: false,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          const api = params.api;
          const thisRow = {};
          api
            .getAllColumns()
            .filter((c) => c.field !== "__check__" && !!c)
            .forEach(
              (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
            );
          return navigate("/reviews/reviewDetail/" + params.id, {
            state: { editReview: true, reviewId: params.id },
          });
        };
        return (
          <Button variant="contained" color="primary" onClick={onClick}>
            VIEW
          </Button>
        );
      },
    },
  ];

  const handleOnCellClick = (params) => {
    navigate("/reviews/mobileDetail/" + params.id);
  };

  const createnew = () => {
    navigate("/reviews/reviewDetail/createNew");
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const ConformDelete = async () => {
    const admin = await JSON.parse(localStorage.getItem('admin'))
    console.log(admin, "for deleting purposes")
    dispatch(deleteReview(selectedIds, admin));
    setOpen(false);
  };
  React.useEffect(() => {
    handleGetReviews();
  }, []);
  return (
    <>
      <div className="w-full h-96">
        <div
          className={`flex mb-5 rounded  +  ${selectedIds.length > 0 ? "bg-pink" : ""
            }`}
        >
          <div className="w-1/2 align-middle pt-3 text-sm font-lighter text-[#f50057] pb-3 pl-2">
            {selectedIds.length > 0 && (
              <h3>Selected Items : {selectedIds.length}</h3>
            )}
          </div>
          <div className="w-1/2 pr-2 flex justify-end">
            <Button
              disabled={!selectedIds.length > 0}
              // onClick={handleDeleteRecords}
              onClick={handleClickOpen}
            >
              {selectedIds.length > 0 && (
                <ImageAsset className=" w-[1.5rem] h-[1.5rem] " src="Delete" />
              )}
            </Button>

            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                <div className="flex flex-col">
                  <div className="flex justify-center">
                    <ImageAsset
                      className="justify-center text-center w-[1.5rem] h-[1.5rem]"
                      src="Delete"
                    />
                  </div>
                  <div className="flex justify-center">
                    <h1>Are you sure ?</h1>
                  </div>
                </div>
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <p className="px-10">
                    Do you want to delete this data ? <br />
                    this data canot be restore.
                  </p>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={ConformDelete} autoFocus>
                  ok
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
        <div className="w-full flex pb-3 justify-end">
          <button
            className="btn text-white text-xs font-light rounded-sm p-2 pl-3 pr-3 bg-orange-400 btn-dark mt-3"
            type="submit"
            onClick={createnew}
          >
            Create new +
          </button>
        </div>

        <DataGrid
          onSelectionModelChange={(newSelectionModel) => {
            setSelectedIds(newSelectionModel);
          }}
          rows={reviewList}
          columns={columns}
          pageSize={50}
          rowsPerPageOptions={[50]}
          checkboxSelection
          disableSelectionOnClick
          getRowId={(r) => r._id}
          autoHeight={true}
        // onRowDoubleClick={handleOnCellClick}
        />
      </div>
    </>
  );
};

export default ReviewContent;
