import React, { useState, useEffect,useRef } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import { TextField } from "../components";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { addMobile } from "../store/usedMobileAction";
import { editusedMobile } from "../store/actions/getUsedMobileActions";
import { baseURL } from "../api/baseURL";
import { useLocation } from "react-router-dom";
import JoditEditor from "jodit-react";
const validate = Yup.object({
  brandName: Yup.string().required("Required"),
  alt:Yup.string(),
  ram: Yup.string().required("Required"),
  rom: Yup.string().required("Required"),
  condition: Yup.string().required("Required"),
  price: Yup.string().required("Required"),
  location: Yup.string().required("Required"),
  contactNo: Yup.number().required("Required"),
  description: Yup.string().required("Required"),
  
});
const CreateUsedMobiles = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { usedMobileList } = useSelector((state) => state.usedmobile);

  const [file, setFile] = useState();
  const [item, setItem] = useState();
  const [fileObj, setFileObject] = useState([]);
  const [fileArray, setFileArray] = useState([]);
  const [edit, setEdit] = useState(false);



  // const [file, setFile] = useState();
  // var [fileObj, setFileObject] = useState([]);
  // var [fileArray, setFileArray] = useState([]);

  const { id } = useParams();
  const uploadMultipleFiles = (e) => {
    console.log('1')
    setFile(e.target.files);
    fileObj.splice(0, fileObj.length);
    fileArray.splice(0, fileArray.length);
    fileObj.splice(0, fileObj.length)
    fileArray.splice(0, fileArray.length)
    fileObj.push(e.target.files);
    for (let i = 0; i < fileObj[0].length; i++) {
      fileArray.push(URL.createObjectURL(fileObj[0][i]));
    }
  };
  const location = useLocation();
  useEffect(() => {
    if (id !== "createNew") {
        usedMobileList.map((item) => {
        if (item?._id?.toString() === id?.toString()) {
          setItem(item);

          setFileArray(item.usedMobilePhotos.map((item) => `${baseURL}${item}`));
        }
        setEdit(location.state.editusedMobile);
      });
    }
  }, [id, addMobile]);

  const initialValues = {
    brandName: `${item ? item.brandName : ""}`,
    alt: `${item ? item.alt : ""}`,
    ram: `${item ? item.ram : ""}`,
    rom: `${item ? item.rom : ""}`,
    condition: `${item ? item.condition : ""}`,
    price: `${item ? item.price : ""}`,
    location: `${item ? item.location : ""}`,
    contactNo: `${item ? item.contactNo : ""}`,
    description: `${item ? item.description : ""}`,
   
  };
 const editor = useRef(null);
  let items;
  let intro;
  if(item){
 items=item.shortdescription;
 intro=item.longdescription;
}
  const [shortdescription, setContent] = useState(items);
  const [longdescription, setIntroduction] = useState(intro);
 console.log(shortdescription);
 console.log(longdescription);
 useEffect(() => {
  setContent(items);
  setIntroduction(intro);
}, [items,intro]);

  const config = {
    readonly: false,
    enableDragAndDropFileToEditor: true,
    uploader:{
      insertImageAsBase64URI:true
    },

    height: 400
  };

  return (
    <>

      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validate}
        onSubmit={async (values) => {
          const admin = await JSON.parse(localStorage.getItem(('admin')))
          edit
            ? dispatch(
              editusedMobile(location.state.mobileId, values, file, navigate, admin)
            )
            : dispatch(addMobile(values, file, navigate, admin));
        }}
      // navigate("/dashboard/mobileDetail/")
      >
        {(formik) => (
          <div className="border border-orange flex justify-center ">
            <div className="w-1/2">
              <div className="w-full p-4 flex justify-center">
                {id !== "createNew" ? (
                  <h1>Edit Mobile </h1>
                ) : (
                  <h1>Create Mobile </h1>
                )}
              </div>
              <Form>
                <div className="flex flex-col space-y-3">
                  <div className="flex flex-row flex-wrap">
                    {(fileArray || []).map((url) => (
                      <img
                        style={{ width: "150px", height: "200px" }}
                        src={url}
                        alt="..."
                        key={url}
                        className="p-4 border-2 border-sky-500 mx-2 my-2"
                      />
                    ))
                    }
                  </div>
                  <div className="mx-auto my-5">
                  <div className="flex">  <input
                      type="file"
                      className="form-control hidden"
                      onChange={uploadMultipleFiles}
                      multiple
                      id="input"
                      name="images"
                      accept="image/*"
                    />
                    <TextField 
                    label="Img Alt"
                    name="alt"
                    placeholder="Enter Image alt"
                    // onChange={(e)=>{setBrandName(e.target.value) }}
                    value={formik.values.alt}
                    type="text"
                    className="my-5 outline-none border w-full py-2 rounded px-2" 

                  />
                    </div>
                    <label
                      className="py-2 px-5 border-2 rounded-2xl bg-sky-500 text-center text-white"
                      htmlFor="input"
                    >
                      Choose your Photo
                    </label>
                  </div>

                  <div className="bg-blue-700 text-center text-white py-2 my-4">
                    <h1>Mobile Info</h1>
                  </div>
                  <TextField
                    label="Brand Name"
                    name="brandName"
                    placeholder="Enter brand name"
                    value={formik.values.brandName}
                    type="text"
                  />
                  <TextField
                    label="Ram"
                    name="ram"
                    placeholder="Enter model number here"
                    value={formik.values.ram}
                    type="text"
                  />
                  <TextField
                    label="Rom"
                    name="rom"
                    placeholder="Enter rom here"
                    value={formik.values.rom}
                    type="text"
                  /> 
                  
                  <TextField
                  label="Condition"
                  name="condition"
                  placeholder="Enter condition here"
                  value={formik.values.condition}
                  type="text"
                />
                <TextField
                  label="Price"
                  name="price"
                  placeholder="Enter Price here"
                  value={formik.values.price}
                  type="text"
                />
                 <TextField
                  label="description"
                  name="description"
                  placeholder="Enter description here"
                  value={formik.values.description}
                  type="text"
                />
                 <TextField
                  label="location"
                  name="location"
                  placeholder="Enter location here"
                  value={formik.values.location}
                  type="text"
                />
                 <TextField
                  label="conatact No"
                  name="contactNo"
                  placeholder="Enter Contact No here"
                  value={formik.values.contactNo}
                  type="text"
                />
                  {/* 
                  
                  
                  this is 
                  
                  
                  
                  */}
                 

                  <div className="flex pt-4 space-x-6 pb-4 w-40">
                    {edit ? (
                      <>
                        <button
                          className="btn text-sm font-light rounded-sm p-1 pl-6 pr-6 bg-green-500 btn-dark mt-3 text-white"
                          type="submit"
                        >
                          UPDATE
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          className="btn text-sm font-light rounded-sm p-1 pl-6 pr-6 bg-orange-400 btn-dark mt-3"
                          type="submit"
                        >
                          Create
                        </button>
                        <button
                          className="btn text-sm font-light rounded-sm p-2 pl-6 pr-6 bg-red-200 btn-dark mt-3"
                          type="reset"
                        >
                          Reset
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </Form>
            </div>
          </div>
        )}
      </Formik>
    </>
  );
};

export default CreateUsedMobiles;
