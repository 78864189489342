import { AdminAction } from "./actionsTypes";

const initialState = {
    admin: {}
};

const adminReducer = (state = initialState, action) => {

    switch (action.type) {

        case AdminAction.ADMIN_LOGIN: {
            return {
                ...state,
                admin: action.payload,
            };
        }
        case AdminAction.ADMIN_LOGOUT: {
            return {};
        }

        default: {
            return state;
        }
    }
};
export default adminReducer;
