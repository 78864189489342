import React from "react";
import {
  Login,
  AdmiMainScreen,
  Create,
  CreateReview,
  CreateNews,
  ReviewScreen,
  NewsScreen,
} from "./screens/index";

import { Outlet } from "react-router";
import { Provider } from "react-redux";
import store from "./store/store";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import App from "./App";
import { SideNavbar } from "./components";
import { FeaturePhone } from "./screens/FeaturePhone";
import { useEffect } from "react";
import UsedMobile from "./components/UsedMobile";
import UsedMobileScreen from "./screens/UsedMobileScreen";
import CreateUsedMobiles from "./screens/CreateUsedMobiles";
import GadgetScreen from "./screens/GadgetScreen";
import CreateGadget from "./screens/CreateGadget";
import GadgetUpdateScreen from "./screens/GadgetUpdateScreen";
import SliderScreen from "./screens/SliderScreen";
import CreateSlide from "./screens/CreateSlide";
import UpdateSlideScreen from "./screens/UpdateSlideScreen";
import QuestionsScreen from "./screens/QuestionsScreen";
import QuestionUpdate from "./screens/QuestionUpdate";
import CreateQuestion from "./screens/CreateQuestion";
import ContactScreen from "./screens/ContactScreen";
import ContactUsDetails from "./components/ContactUsDetails";
import BlogsScreen from "./screens/BlogsScreen";
import CreateBlog from "./screens/CreateBlog";

// const admin = () => { return localStorage.getItem(JSON.parse('admin')) }
const MyRoutes = () => (
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/" element={<App />}>
            <Route index element={<Login />} />
            <Route path="login" element={<Login />} />
            <Route path="dashboard" element={<SideNavbar />}>
              <Route index element={<AdmiMainScreen />} />
              <Route path="mobileDetail/:id" element={<Create />} />
            </Route>
            {/* <Routes> */}
            <Route path="usedmobile" element={<SideNavbar />}>
              <Route index element={<UsedMobileScreen />} />
              <Route
                path="usedmobileDetail/:id"
                element={<CreateUsedMobiles />}
              />
            </Route>
            <Route path="reviews" element={<SideNavbar />}>
              <Route index element={<ReviewScreen />} />
              <Route path="reviewDetail/:id" element={<CreateReview />} />
            </Route>

            <Route path="news" element={<SideNavbar />}>
              <Route index element={<NewsScreen />} />
              <Route path="newsDetail/:id" element={<CreateNews />} />
            </Route>

            <Route path="blogs" element={<SideNavbar />}>
              <Route index element={<BlogsScreen />} />
              <Route path="blogsDetail/:id" element={<CreateBlog />} />
            </Route>

            <Route path="featurePhones" element={<SideNavbar />}>
              <Route index element={<FeaturePhone />} />
            </Route>
            <Route path="gadget" element={<SideNavbar />}>
              <Route index element={<GadgetScreen />} />
              <Route path="creategadget" element={<CreateGadget />} />
              <Route path="update/:id" element={<GadgetUpdateScreen />} />
            </Route>
            <Route path="slide" element={<SideNavbar />}>
              <Route index element={<SliderScreen />} />
              <Route path="createslide" element={<CreateSlide />} />
              <Route path="updateslide/:id" element={<UpdateSlideScreen />} />
            </Route>
            <Route path="question" element={<SideNavbar />}>
              <Route index element={<QuestionsScreen />} />
              <Route path="createquestion" element={<CreateQuestion />} />

              <Route path="updatequestion/:id" element={<QuestionUpdate />} />
            </Route>
            <Route path="contactus" element={<SideNavbar />}>
              <Route index element={<ContactScreen />} />
              {/* <Route path="createquestion" element={<CreateQuestion />} /> */}

              <Route
                path="contactUsDetails/:id"
                element={<ContactUsDetails />}
              />
            </Route>
          </Route>
        </Routes>
      </Router>
    </Provider>
  </React.StrictMode>
);

export default MyRoutes;
