import React from "react";
import { Content, SideNavbar } from "../components";

const AdminMainScreen = () => {
  return (
    <div className="w-full">
      <Content />
    </div>
  );
};

export default AdminMainScreen;
