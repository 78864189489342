import { NewsAction } from "./actionsTypes";
import axios from "axios";
import { baseURL } from "../api/baseURL";

export const addNewNews = (values) => {
  return (dispatch) => {
    dispatch({
      type: NewsAction.ADD_NEWS,
      payload: values,
    });
    localStorage.removeItem("title");
    localStorage.removeItem("details");
    localStorage.removeItem("description");
  };
};

//delete reviews
export const deleteNews = (value, admin) => {
  console.log(admin, "deleting now now now");
  return async (dispatch) => {
    try {
      const res = await axios.delete(
        `${baseURL}/api/v1/news/deleteNews`,
        {
          data: {
            value,
          },
        },
        {
          headers: {
            authorization: `${admin}`,
          },
        }
      );

      if (res.data) {
        dispatch({
          type: NewsAction.DELETE_NEWS,
          payload: value,
        });
      } else {
        console.log("Unable to fetch");
      }
    } catch (error) {}
  };
};

// add review
export const addNews = (values, images, navigate, admin) => {
  console.log("values==>", values);
  console.log("admin during news add req", admin);
  return async (dispatch) => {
    const formData = new FormData();
    if (images) {
      for (var i = 0; i < images.length; i++) {
        formData.append("images", images[i]);
      }
    }
    try {
      const res = await axios.post(
        `${baseURL}/api/v1/news/addNews`,

        images === undefined ? null : formData,
        {
          params: {
            values,
          },
        },
        {
          headers: {
            mode: "no-cors",
            Authorization: `${admin}`,
            accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("res===>", res);
      if (res.data.success) {
        dispatch({
          type: NewsAction.ADD_NEWS,
          payload: values,
        });
        alert(res.data.data);
        navigate("/news");
        localStorage.removeItem("title");
        localStorage.removeItem("details");
        localStorage.removeItem("description");
      } else {
        alert(res.data.data);
        navigate("/news");
      }
    } catch (error) {}
  };
};
